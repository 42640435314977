import { Store } from "../store/store";

export function DrawRectSelect(ctx: CanvasRenderingContext2D, store: Store) {

    const canvasPointFrom = store.ClientToCanvas(store.ui.GetMouseRectSelectPos());
    const canvasPointTo = store.ClientToCanvas(store.ui.GetMousePos());
    
    if (canvasPointTo[0] < canvasPointFrom[0])
        ctx.setLineDash([5, 3]);    

    ctx.strokeStyle = "white";
    ctx.strokeRect(canvasPointFrom[0], canvasPointFrom[1], canvasPointTo[0] - canvasPointFrom[0], canvasPointTo[1] - canvasPointFrom[1]);

    ctx.setLineDash([]);
}