import { Store } from "../store/store";
import { Command } from "./command";

export class ZoomExtentsCmd extends Command{
    constructor() {
        super("ZoomExtentsCmd");
    }

    Process(store: Store) {
        let minX = Number.MAX_SAFE_INTEGER, maxX = Number.MIN_SAFE_INTEGER, minY = Number.MAX_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER;
        store.GetActiveDoc().EntitiesForEach(entity => {
            const bounds = entity.GetVisibleBoundingRect();
            minX = Math.min(minX, bounds[0]); minY = Math.min(minY, bounds[1]); maxX = Math.max(maxX, bounds[0] + bounds[2]); maxY = Math.max(maxY, bounds[1] + bounds[3]); 
        })
        const margin = 0.01 * Math.max(maxX - minX, maxY - minY);
        minX -= margin;
        minY -= margin;
        maxX += margin;
        maxY += margin;
        const canvasRect = store.ui.GetCanvasRect();
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionWidth(Math.max(maxX - minX, (maxY - minY) * canvasRect[2] / canvasRect[3]));
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter([minX + 0.5 * (maxX - minX), minY + 0.5 * (maxY - minY)]);
    }
}