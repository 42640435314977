import { PointInRect } from "../math/rect";
import { TEXTUAL_CHAR_SEPARATION, TEXTUAL_LINE_SEPARATION, TEXTUAL_PADDING_BOTTOM, TEXTUAL_PADDING_LEFT, TEXTUAL_PADDING_RIGHT, TEXTUAL_PADDING_TOP, Textual } from "../doc/extended/entities/textual";
import { Store } from "../store/store";
import { UpsertTextCmd } from "../commands/upsertText";
import { Command } from "../commands/command";
import { PromptButtonCmd } from "../commands/promptButton";
import { KeyboardGesture } from "./gestures/keyboard";
import { PanGesture } from "./gestures/pan";
import { ZoomWheelGesture } from "./gestures/zoomWheel";
import { EditorState } from "./editor";
import { State } from "./state";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack } from "@mui/material";
import AppBar from "../components/frame/top/appBar";
import ToolBar from "../components/frame/top/toolBar";
import LeftPannel from "../components/frame/left/leftPannel";
import TopBar from "../components/frame/right/topBar";
import { Canvas } from "../components/lib/canvas";
import PromptBar from "../components/frame/right/promptBar";
import BottomBar from "../components/frame/right/bottomBar";
import Menus from "../components/menus/menus";
import Dialogs from "../components/dialogs/dialogs";

interface StateProps {
    state: TextEditorState;
    store: Store;
}

const StateRender: React.FC<StateProps> = ({state, store}) => {
    const { user, isAuthenticated, isLoading } = useAuth0();    

    const block = (
    <>
        <Box id="MainFrame" sx={{outline:'none'}}
            onKeyDown={e => state.KeyDown(e, store)}
            onKeyUp={e => state.KeyUp(e, store)}
            tabIndex={-1}>
            <Box sx={{ width: "100vw", height: "144px" }}>
                <AppBar store={store}/>
                <ToolBar store={store}/>
            </Box>
            <Stack direction={"row"}>
                <Box sx={{ width: "400px", height: "calc(100vh - 144px)" }}>
                    <LeftPannel/>
                </Box>
                <Stack>
                    <Box sx={{ width: "calc(100vw - 400px)", height: "40px" }}>
                        <TopBar store={store}/>
                    </Box>
                    <Canvas draw={ctx => state.Draw(ctx, store)} 
                            mouseDown={e => state.MouseDown(e, store)}
                            mouseUp={e => state.MouseUp(e, store)}
                            mouseMove={e => state.MouseMove(e, store)}
                            mouseWheel={e => state.MouseWheel(e, store)}
                            mouseEnter={e => state.MouseEnter(e, store)}
                            mouseLeave={e => state.MouseLeave(e, store)}
                            mouseDblClick={e => state.MouseDblClick(e, store)}
                            touchesPan={e => state.TouchesPan(e, store)}
                            touchesPinch={e => state.TouchesPinch(e, store)}
                            resizeAndOrMove={(x: number, y: number, w: number, h: number) => store.ui.SetCanvasRect(x,y,w,h)}
                            width= "calc(100vw - 400px)" height= "calc(100vh - 144px - 40px - 112px)"/>
                    <Box sx={{ width: "calc(100vw - 400px)", height: "112px" }}>
                        <PromptBar store={store}/>
                        <BottomBar store={store}/>
                    </Box>
                </Stack>
            </Stack>
            <canvas id="snapshot" width="200" height="150" style={{display:'none', position:'absolute',top:'0',left:'0',width:'200px',height:'150px'}}></canvas>
        </Box>
        <Menus store={store}/>
        <Dialogs store={store}/>
    </>);

    return block;
}

export class TextEditorState extends State {
    text: Textual;
    textOriginal: string;
    isNew: boolean;
    cursorVisible: boolean = true;
    cursorLineIdx: number = 0;
    cursorCharIdx: number = -1;
    cursorWorldPos: number[] = [0,0];
    blinkTimerId: any = undefined;
    ctx: CanvasRenderingContext2D | undefined = undefined;
    focusTmr: any;
    
    constructor(text: Textual, isNew: boolean) {
        super([
            new PanGesture(),
            new ZoomWheelGesture(),
            new KeyboardGesture(),
        ],
        [
            "ZoomExtentsCmd",

            "UpsertTextCmd",
        ]);
        this.text = text;
        this.textOriginal = text.text;
        this.isNew = isNew;
        this.blinkTimerId = setInterval(() => { this.cursorVisible = !this.cursorVisible; }, 400);
    }

    Activate(store: Store) {
        if (!store.ui.IsInputFocus()) store.ui.FocusFrame();
        this.focusTmr = setInterval(() => {if (!store.ui.IsInputFocus()) store.ui.FocusFrame()}, 500);
    }

    Deactivate(store: Store) {
        clearInterval(this.focusTmr);
    }

    Command(cmd: Command, store: Store) {
        super.Command(cmd, store);


        switch (cmd.name) {
            case "CancelCmd": {
                this.text.text = this.textOriginal;
                clearInterval(this.blinkTimerId);
                store.SetActiveState(new EditorState());
            } break;
            case "PromptButtonCmd": {
                switch ((cmd as PromptButtonCmd).bName) {
                    case "Done": {
                        this.Command(new UpsertTextCmd(this.text), store);

                        store.SetActiveState(new EditorState());
                    } break;
                    case "Cancel": {
                        this.text.text = this.textOriginal;
                        clearInterval(this.blinkTimerId);
                        store.SetActiveState(new EditorState());
                    } break;
                }
            } break;
        }        
    }

    KeyDown(event: any, store: Store) { 
        super.KeyDown(event, store);

        //console.log('text editor', event.key)
        if (event.key === "ArrowRight")
        {
            const lines = this.text.text.split("\n");
            if (this.cursorCharIdx + 1 < lines[this.cursorLineIdx].length)
                this.cursorCharIdx++;
            else if (this.cursorLineIdx + 1 < lines.length)
            {
                this.cursorLineIdx++;
                this.cursorCharIdx = -1;
            }
            console.log(this.cursorLineIdx, this.cursorCharIdx)
        }
        if (event.key === "ArrowLeft")
        {
            const lines = this.text.text.split("\n");
            if (this.cursorCharIdx > -1)
                this.cursorCharIdx--;
            else if (this.cursorLineIdx - 1 >= 0)
            {
                this.cursorLineIdx--;
                this.cursorCharIdx = lines[this.cursorLineIdx].length - 1;
            }
            console.log(this.cursorLineIdx, this.cursorCharIdx)
        }
        if (event.key === "ArrowUp" && this.ctx !== undefined)
        {
            const newCursorPos = [this.cursorWorldPos[0], this.cursorWorldPos[1] + this.text.h + TEXTUAL_LINE_SEPARATION];
            Textual.WalkTextWordWrap(
                    this.text.GetVisibleBoundingRect(), 
                    this.text.text, 
                    this.text.h, 
                    this.text.f, 
                    TEXTUAL_CHAR_SEPARATION,
                    TEXTUAL_LINE_SEPARATION,
                    TEXTUAL_PADDING_LEFT,
                    TEXTUAL_PADDING_TOP,
                    TEXTUAL_PADDING_RIGHT,
                    TEXTUAL_PADDING_BOTTOM,
                    this.ctx, 
                    (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[]) => {
                        if (PointInRect(charExtendedArea[0], charExtendedArea[1], charExtendedArea[2], charExtendedArea[3], newCursorPos[0], newCursorPos[1])) {
                            const midX = charArea[0] + 0.5 * charArea[2];
                            this.cursorLineIdx = lineIdx;
                            this.cursorCharIdx = charIdx - (newCursorPos[0] <= midX ? 1 : 0);
                        }
                    },
                    (lineIdx: number, lineArea: number[]) => {
                        if (PointInRect(lineArea[0], lineArea[1], lineArea[2], lineArea[3], newCursorPos[0], newCursorPos[1])) {
                            this.cursorLineIdx = lineIdx;
                            this.cursorCharIdx = -1;
                        }
                    });
        }
        if (event.key === "ArrowDown" && this.ctx !== undefined)
        {
            const newCursorPos = [this.cursorWorldPos[0], this.cursorWorldPos[1] - this.text.h - TEXTUAL_LINE_SEPARATION];
            Textual.WalkTextWordWrap(
                    this.text.GetVisibleBoundingRect(), 
                    this.text.text, 
                    this.text.h, 
                    this.text.f, 
                    TEXTUAL_CHAR_SEPARATION,
                    TEXTUAL_LINE_SEPARATION,
                    TEXTUAL_PADDING_LEFT,
                    TEXTUAL_PADDING_TOP,
                    TEXTUAL_PADDING_RIGHT,
                    TEXTUAL_PADDING_BOTTOM,
                    this.ctx, 
                    (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[]) => {
                        if (PointInRect(charExtendedArea[0], charExtendedArea[1], charExtendedArea[2], charExtendedArea[3], newCursorPos[0], newCursorPos[1])) {
                            const midX = charArea[0] + 0.5 * charArea[2];
                            this.cursorLineIdx = lineIdx;
                            this.cursorCharIdx = charIdx - (newCursorPos[0] <= midX ? 1 : 0);
                        }
                    },
                    (lineIdx: number, lineArea: number[]) => {
                        if (PointInRect(lineArea[0], lineArea[1], lineArea[2], lineArea[3], newCursorPos[0], newCursorPos[1])) {
                            this.cursorLineIdx = lineIdx;
                            this.cursorCharIdx = -1;
                        }
                    });
        }
        if (event.key.length === 1) // char
        {
            const lines = this.text.text.split("\n");
            lines[this.cursorLineIdx] = lines[this.cursorLineIdx].substring(0, this.cursorCharIdx + 1) + event.key + lines[this.cursorLineIdx].substring(this.cursorCharIdx + 1);
            this.text.text = lines.join("\n");
            this.cursorCharIdx++;
        }
        if (event.key === "Enter")
        {
            const lines = this.text.text.split("\n");
            lines.splice(this.cursorLineIdx + 1, 0, lines[this.cursorLineIdx].substring(this.cursorCharIdx + 1));
            lines[this.cursorLineIdx] = lines[this.cursorLineIdx].substring(0, this.cursorCharIdx + 1);
            this.text.text = lines.join("\n");
            this.cursorLineIdx++;
            this.cursorCharIdx = -1;
        }
        if (event.key === "Backspace")
        {
            let lines = this.text.text.split("\n");
            if (this.cursorCharIdx > -1)
            {
                lines[this.cursorLineIdx] = lines[this.cursorLineIdx].substring(0, this.cursorCharIdx) + lines[this.cursorLineIdx].substring(this.cursorCharIdx + 1);
                this.cursorCharIdx--;
            }
            else if (this.cursorLineIdx - 1 >= 0) {
                lines[this.cursorLineIdx - 1] += lines[this.cursorLineIdx];
                lines.splice(this.cursorLineIdx, 1);
                this.cursorLineIdx--;
                this.cursorCharIdx = lines[this.cursorLineIdx].length - 1;
            }
            this.text.text = lines.join("\n");
        }
        if (event.key === "Delete")
        {
            let lines = this.text.text.split("\n");
            if (this.cursorCharIdx < lines[this.cursorLineIdx].length - 1)
            {
                lines[this.cursorLineIdx] = lines[this.cursorLineIdx].substring(0, this.cursorCharIdx + 1) + lines[this.cursorLineIdx].substring(this.cursorCharIdx + 2);
            }
            else if (this.cursorLineIdx + 1 < lines.length) {
                lines[this.cursorLineIdx] += lines[this.cursorLineIdx + 1];
                lines.splice(this.cursorLineIdx + 1, 1);
            }
            this.text.text = lines.join("\n");
        }
        if (event.key === "Home")
        {
            this.cursorCharIdx = -1;
        }
        if (event.key === "End")
        {
            let lines = this.text.text.split("\n");
            this.cursorCharIdx = lines[this.cursorLineIdx].length - 1;
        }
    }

    KeyUp(event: any, store: Store) {
        super.KeyUp(event, store);
    }

    MouseDown(event: any, store: Store) {
        super.MouseDown(event, store);

        if (event.button === 0 && this.ctx !== undefined)
        {
            const wMouse = store.ClientToWorld(store.ui.GetMousePos());
            Textual.WalkTextWordWrap(
                this.text.GetVisibleBoundingRect(), 
                this.text.text, 
                this.text.h, 
                this.text.f, 
                TEXTUAL_CHAR_SEPARATION,
                TEXTUAL_LINE_SEPARATION,
                TEXTUAL_PADDING_LEFT,
                TEXTUAL_PADDING_TOP,
                TEXTUAL_PADDING_RIGHT,
                TEXTUAL_PADDING_BOTTOM,
                this.ctx, 
                (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[]) => {
                    if (PointInRect(charExtendedArea[0], charExtendedArea[1], charExtendedArea[2], charExtendedArea[3], wMouse[0], wMouse[1])) {
                        const midX = charArea[0] + 0.5 * charArea[2];
                        this.cursorLineIdx = lineIdx;
                        this.cursorCharIdx = charIdx - (wMouse[0] <= midX ? 1 : 0);
                    }
                },
                (lineIdx: number, lineArea: number[]) => {
                    if (PointInRect(lineArea[0], lineArea[1], lineArea[2], lineArea[3], wMouse[0], wMouse[1])) {
                        this.cursorLineIdx = lineIdx;
                        this.cursorCharIdx = -1;
                    }
                });
        }
    }

    MouseUp(event: any, store: Store) {
        super.MouseUp(event, store);

        if (event.button === 0)
        {
            // Snap mouse
            let worldMouse = store.ClientToWorld(store.ui.GetMousePos());
            const wSelectRadius = store.CanvasToWorldDelta([10, 0])[0];
            const nearestAvoidance = 0.4 * wSelectRadius;
            let nearestP: number[] = [0,0], nearestDist = Number.MAX_SAFE_INTEGER;
            store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaExact(
                store.GetActiveDoc().GetActiveSpace(),
                layer => layer.isVisible,
                () => true,
                [worldMouse[0] - wSelectRadius, worldMouse[1] - wSelectRadius, 2 * wSelectRadius, 2 * wSelectRadius]
            ).forEach(entity => {
                entity.GetDestSnaps(worldMouse).forEach(snap => {
                    let dist = Math.sqrt((snap.p[0] - worldMouse[0]) * (snap.p[0] - worldMouse[0]) + (snap.p[1] - worldMouse[1]) * (snap.p[1] - worldMouse[1]));
                    if (snap.type === "NEAREST_POINT")
                        dist += nearestAvoidance;
                    if (nearestDist > dist)
                    {
                        nearestP = snap.p;
                        nearestDist = dist;
                    }
                })
            })

            if (nearestDist !== Number.MAX_SAFE_INTEGER)
            {
                let canvasMouse = store.ClientToCanvas(store.ui.GetMousePos());
                const canvasNearestP = store.WorldToCanvas(nearestP);
                if (100 >= (canvasNearestP[0] - canvasMouse[0]) * (canvasNearestP[0] - canvasMouse[0]) + (canvasNearestP[1] - canvasMouse[1]) * (canvasNearestP[1] - canvasMouse[1]))
                    worldMouse = nearestP;
            }

        }
    }

    MouseMove(event: any, store: Store) {
        super.MouseMove(event, store);
    }

    MouseWheel(event: any, store: Store) {
        super.MouseWheel(event, store);
    }

    MouseEnter(event: any, store: Store) {
        super.MouseEnter(event, store);
    }

    MouseLeave(event: any, store: Store) {
        super.MouseLeave(event, store);
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        super.Draw(ctx, store);

        this.ctx = ctx;;

        ctx.fillStyle = 'black'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = 'white'
        ctx.beginPath()
        ctx.arc(50, 100, 20*Math.sin(Math.random()*1000*0.05)**2, 0, 2*Math.PI)
        ctx.fill();

        store.GetActiveDoc().GetSpacialPartiionAccelerator()._draw(ctx, store);

        // Get visible entities for layer
        const boundsW = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth();
        const boundsH = Math.abs(store.CanvasToWorldDelta([0,store.ui.GetCanvasRect()[3]])[1]);
        const boundsX0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0] - 0.5 * boundsW;
        const boundsY0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1] - 0.5 * boundsH;
        const entities = store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaApproximate(
            store.GetActiveDoc().GetActiveSpace(),
            layer => layer.isVisible,
            () => true,
            [boundsX0, boundsY0, boundsW, boundsH]
        )

        // Draw entities
        entities.forEach(entity => {
            if (!entity.IsSelected())
                entity.Draw(ctx, store);
            else
                entity.DrawSelected(ctx, store);
        })
        this.text.DrawSelected(ctx, store);

        // Draw input border
        ctx.strokeStyle = 'gray';
        const textualRect = this.text.GetVisibleBoundingRect();
        const [x, y] = store.WorldToCanvas([textualRect[0], textualRect[1]]);
        const [w, h] = store.WorldToCanvasDelta([textualRect[2], textualRect[3]]);
        ctx.strokeRect(x, y, w, h);

        // Draw cursor
        let cursorX = 0, cursorY: number | undefined = undefined;
        if (this.cursorCharIdx >= 0)
        {
            Textual.WalkTextWordWrap(
                textualRect, 
                this.text.text, 
                this.text.h, 
                this.text.f, 
                TEXTUAL_CHAR_SEPARATION,
                TEXTUAL_LINE_SEPARATION,
                TEXTUAL_PADDING_LEFT,
                TEXTUAL_PADDING_TOP,
                TEXTUAL_PADDING_RIGHT,
                TEXTUAL_PADDING_BOTTOM,
                ctx, 
                (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[]) => {
                    if (this.cursorLineIdx === lineIdx && this.cursorCharIdx === charIdx)
                    {
                        cursorX = charArea[0] + charArea[2];
                        cursorY = charArea[1];
                    }
                },
                (lineIdx: number, lineArea: number[]) => {});
        }
        else
        {
            cursorX = textualRect[0] + TEXTUAL_PADDING_LEFT;
            cursorY = undefined;
            Textual.WalkTextWordWrap(
                textualRect, 
                this.text.text, 
                this.text.h, 
                this.text.f, 
                TEXTUAL_CHAR_SEPARATION,
                TEXTUAL_LINE_SEPARATION,
                TEXTUAL_PADDING_LEFT,
                TEXTUAL_PADDING_TOP,
                TEXTUAL_PADDING_RIGHT,
                TEXTUAL_PADDING_BOTTOM,
                ctx, 
                (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[]) => {
                    if (cursorY === undefined && this.cursorLineIdx === lineIdx)
                        cursorY = charArea[1];
                },
                (lineIdx: number, lineArea: number[]) => {
                    if (cursorY === undefined && this.cursorLineIdx === lineIdx)
                        cursorY = lineArea[1] + lineArea[3] - (lineIdx === 0 ? TEXTUAL_PADDING_TOP : 0) - this.text.h;
                });
        }
        this.cursorWorldPos = [cursorX, cursorY!];
        if (this.cursorVisible) {
            const canvasP0 = store.WorldToCanvas([cursorX, cursorY! + this.text.h * 1]);
            const canvasP1 = store.WorldToCanvas([cursorX, cursorY! + this.text.h * 0.2]);
            ctx.fillStyle = "white";
            ctx.fillRect(canvasP0[0], canvasP0[1], 3, canvasP1[1] - canvasP0[1]);
        }

        let canvasMouse = store.ClientToCanvas(store.ui.GetMousePos());
        ctx.strokeStyle = 'white';
        ctx.beginPath();
        ctx.moveTo(canvasMouse[0] - 5, canvasMouse[1] - 15);
        ctx.lineTo(canvasMouse[0] + 5, canvasMouse[1] - 15);
        ctx.moveTo(canvasMouse[0] - 5, canvasMouse[1] + 15);
        ctx.lineTo(canvasMouse[0] + 5, canvasMouse[1] + 15);
        ctx.moveTo(canvasMouse[0], canvasMouse[1] - 15);
        ctx.lineTo(canvasMouse[0], canvasMouse[1] + 15);
        ctx.stroke();
    }

    Render(store: Store) {
        return <StateRender state={this} store={store}/>
    }
}