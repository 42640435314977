import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Divider, Stack, Typography, popoverClasses } from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';
import { Layer } from "../../doc/extended/layers/layer";

export interface LayerSelectProps {
    store: Store;
}

export default function LayerSelectMenu(props: LayerSelectProps) {
    if (props.store.HasActiveDoc())
    {
        const menuItemsBlock: any[] = [];
        props.store.GetActiveDoc().LayersForEach(layer => menuItemsBlock.push(
            <MenuItem key={layer.name} onClick={() => layerClick(layer)}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <CheckBoxOutlineBlankSharpIcon fontSize="small" sx={{ bgcolor: layer.color, color: layer.color, mr:1 }}/>
                        <Typography>{layer.name}</Typography>
                    </Stack>
                </Stack>
            </MenuItem>));
    
        const handleClose = () => {
            props.store.ui.Set().layerSelectMenu.anchorEl = null;
        }
        const layerClick = (layer: Layer) => {
            props.store.GetActiveDoc().SetActiveLayer(layer);
            props.store.ui.Set().layerSelectMenu.anchorEl = null;
        }
        return (
            <Menu anchorEl={props.store.ui.Get().layerSelectMenu.anchorEl} 
                  open={Boolean(props.store.ui.Get().layerSelectMenu.anchorEl)} 
                  sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} 
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: -1,
                    horizontal: 'center',
                  }}>
                {menuItemsBlock}
            </Menu>
        );
    }
    else return (<></>)
}