import { NormalizeAngle } from "./angle";
import { Dist } from "./point";

// Assumes: Point is already in the circle
export function ArcPointToAngle(x: number, y: number, cx: number, cy: number, r: number) {
    const dx = x - cx;
    const dy = y - cy; 
    if (dx > 0)
        if (dy > 0)
            return Math.asin(dy < r ? dy / r : 1);
        else 
            return 2 * Math.PI - Math.asin(-dy < r ? -dy / r : 1);
    else
        if (dy > 0)
            return Math.PI - Math.asin(dy < r ? dy / r : 1);
        else 
            return Math.PI + Math.asin(-dy < r ? -dy / r : 1);
}

// Assumes: Point is already in the circle
export function PointInArc(x: number, y: number, cx: number, cy: number, r: number, a0: number, a1: number) {
    a0 = NormalizeAngle(a0); 
    a1 = NormalizeAngle(a1);
    let beta = ArcPointToAngle(x, y, cx, cy, r);
    if (a0 <= a1)
        return a0 <= beta && beta <= a1;
    return beta >= a0 || beta <= a1;
}

export function ArcPointFromAngle(cx: number, cy: number, r: number, a: number) {
    return [cx + Math.cos(a) * r, cy + Math.sin(a) * r];
}

export function ArcBoundingRect(cx: number, cy: number, r: number, a0: number, a1: number) {
    let minX = Number.MAX_SAFE_INTEGER, maxX = Number.MIN_SAFE_INTEGER, minY = Number.MAX_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER;
    let p = ArcPointFromAngle(cx, cy, r, a0);
    minX = Math.min(minX, p[0]); minY = Math.min(minY, p[1]); maxX = Math.max(maxX, p[0]); maxY = Math.max(maxY, p[1]); 
    p = ArcPointFromAngle(cx, cy, r, a1);
    minX = Math.min(minX, p[0]); minY = Math.min(minY, p[1]); maxX = Math.max(maxX, p[0]); maxY = Math.max(maxY, p[1]); 
    p = [cx - r, cy];
    if (PointInArc(p[0], p[1], cx, cy, r, a0, a1)) {
        minX = Math.min(minX, p[0]); minY = Math.min(minY, p[1]); maxX = Math.max(maxX, p[0]); maxY = Math.max(maxY, p[1]); 
    }
    p = [cx + r, cy];
    if (PointInArc(p[0], p[1], cx, cy, r, a0, a1)) {
        minX = Math.min(minX, p[0]); minY = Math.min(minY, p[1]); maxX = Math.max(maxX, p[0]); maxY = Math.max(maxY, p[1]); 
    }
    p = [cx, cy - r];
    if (PointInArc(p[0], p[1], cx, cy, r, a0, a1)) {
        minX = Math.min(minX, p[0]); minY = Math.min(minY, p[1]); maxX = Math.max(maxX, p[0]); maxY = Math.max(maxY, p[1]); 
    }
    p = [cx, cy + r];
    if (PointInArc(p[0], p[1], cx, cy, r, a0, a1)) {
        minX = Math.min(minX, p[0]); minY = Math.min(minY, p[1]); maxX = Math.max(maxX, p[0]); maxY = Math.max(maxY, p[1]); 
    }
    return [minX, minY, maxX - minX, maxY - minY];
}

export function PointsToArc(x0: number, y0: number, x1: number, y1: number, x2: number, y2: number) {
    const a0 = 2 * (x0 - x1);
    const b0 = x1 * x1 + y1 * y1 - x0 * x0 - y0 * y0;
    const c0 = 2 * (y1 - y0);
    const a1 = 2 * (x0 - x2);
    const b1 = x2 * x2 + y2 * y2 - x0 * x0 - y0 * y0;
    const c1 = 2 * (y2 - y0);
    const den = a1 * c0 - a0 * c1; if (Math.abs(den) < 0.0000001) return undefined;
    const cx = (b0 * c1 - b1 * c0) / den;
    let cy: number;
    if (Math.abs(c0) >= 0.0000001)
        cy = (a0 * cx + b0) / c0;
    else
        cy = (a1 * cx + b1) / c1;
    const r = Dist([cx, cy], [x0, y0]);
    const angle0 = ArcPointToAngle(x0, y0, cx, cy, r) ?? 0;
    const angle1 = ArcPointToAngle(x2, y2, cx, cy, r) ?? 0;
    if (PointInArc(x1, y1, cx, cy, r, angle0, angle1))
        return [[cx, cy], [r], [angle0, angle1]];
    return [[cx, cy], [r], [angle1, angle0]];
}

export function ArcMidPoint(cx: number, cy: number, r: number, a0: number, a1: number) {
    if (a1 >= a0)
        return ArcPointFromAngle(cx, cy, r, 0.5 * (a1 + a0));
    return ArcPointFromAngle(cx, cy, r, Math.PI + 0.5 * (a1 + a0));
}