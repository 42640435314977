import { SpacialPartiionAccelerator } from "../accelerators/spacialPartitionAccelerator";
import { CanvasToClient, CanvasToWorld, CanvasToWorldDelta, ClientToCanvas, WorldToCanvas, WorldToCanvasDelta } from "../math/projection";
import { IEntity } from "../doc/extended/entities/entity";
import { Layer } from "../doc/extended/layers/layer";
import { Space } from "../doc/extended/spaces/space";
import { Account } from "./account";
import PromptBar from "./promptBar";
import SelectedObjects from "./selectedObjects";
import { UndoableAction, UndoableHistory } from "../doc/extended/undoableHistory";
import { State } from "../states/state";
import { BootState } from "../states/boot";
import { Doc } from "../doc/extended/doc";
import { GenId } from "../tsutils/id";
import { GenerateSnapshot } from "../doc/extended/snapshot";

export class ReactiveStore {
    fileMenu = { anchorEl: null };
    fileMenuClosed = { anchorEl: null };
    editMenu = { anchorEl: null };
    selectionMenu = { anchorEl: null };
    layerSelectMenu = { anchorEl: null };
    profileLogintMenu = { anchorEl: null };
    profileLogouttMenu = { anchorEl: null };
    mobileAppMenu = { anchorEl: null };
    mobileAppMenuClosed = { anchorEl: null };

    selection = new SelectedObjects();

    promptBar = new PromptBar();
}

export class FileStore {
    hasFile = false;
    isOwned = false;
    ownerContainerName = "";
    filePath = "";
    fileName = "";
}

export class UIStore {
    mousePos: number[] = [0,0];
    mousePanningPrevPos: number[] = [];
    mouseRectSelectPos: number[] = [];
    mouseClickSelectPos: number[] = [];
    canvasRect: number[] = [0,0,100,100];
    reactiveStore: ReactiveStore = new ReactiveStore();
    forceReactRefresh: ()=>void = ()=>{};
    account = new Account();
    file = new FileStore();

    Set() { this.forceReactRefresh(); return this.reactiveStore; }
    Get() { return this.reactiveStore; }
    SetReactRefresh(forceReactRefresh: ()=>void) { this.forceReactRefresh = forceReactRefresh; }

    IsInputFocus() { return document.activeElement?.id.startsWith("input"); }
    FocusFrame() { document.getElementById("MainFrame")?.focus(); }

    GetMousePos() { return this.mousePos; }
    SetMousePos(newPos: number[]) { this.mousePos = newPos; }

    GetMousePanningPrevPos() { return this.mousePanningPrevPos; }
    SetMousePanningPrevPos(newPos: number[]) { this.mousePanningPrevPos = newPos; }

    GetMouseRectSelectPos() { return this.mouseRectSelectPos; }
    SetMouseRectSelectPos(newPos: number[]) { this.mouseRectSelectPos = newPos; }

    GetMouseClickSelectPos() { return this.mouseClickSelectPos; }
    SetMouseClickSelectPos(newPos: number[]) { this.mouseClickSelectPos = newPos; }

    GetCanvasRect() { return this.canvasRect; }
    SetCanvasRect(x: number, y:number, w: number, h: number) { this.canvasRect = [x,y,w,h]; }
}

export class Store {
    ui: UIStore;
    docs: Doc[];
    activeDoc: Doc | undefined;
    activeState: State;

    constructor() {
        this.ui = new UIStore();
        this.docs = [];
        this.activeDoc = undefined;
        this.activeState = new BootState();
    }

    Tick() {
        this.docs.filter(doc => doc.snapshotIsDirty).forEach(doc => GenerateSnapshot("snapshot", this, doc, ()=>{}));
    }

    GetActiveState() { return this.activeState; }
    SetActiveState(newState: State) { this.activeState = newState; newState.Activate(this); console.log('new state', newState.constructor.name); this.ui.Set(); }

    HasActiveDoc() { return this.activeDoc !== undefined; }
    GetActiveDoc() { return this.activeDoc!; }
    SetActiveDoc(newDoc: Doc | undefined) { this.activeDoc = newDoc; }

    Do(undoableAction: UndoableAction) { this.activeDoc!.undoableHistory.Do(undoableAction); }
    HasUndo() { return this.activeDoc!.undoableHistory.HasUndo(); }
    Undo() { this.activeDoc!.undoableHistory.Undo(); }
    HasRedo() { return this.activeDoc!.undoableHistory.HasRedo(); }
    Redo() { this.activeDoc!.undoableHistory.Redo(); }

    GenEntityId() {
        let id = GenId();
        while (this.activeDoc!.entities.has(id))
            id = GenId();
        return id;
    }

    GenSpaceId() {
        let id = GenId();
        while (this.activeDoc!.spaces.has(id))
            id = GenId();
        return id;
    }

    GenLayerId() {
        let id = GenId();
        while (this.activeDoc!.layers.has(id))
            id = GenId();
        return id;
    }

    WorldToCanvas(wP: number[]) {
        return WorldToCanvas(
            wP[0], wP[1],
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth(), 
            this.ui.GetCanvasRect()[2], this.ui.GetCanvasRect()[3]);
    }

    WorldToCanvasDelta(cD: number[]) {
        return WorldToCanvasDelta(
            cD[0], cD[1],
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth(), 
            this.ui.GetCanvasRect()[2], this.ui.GetCanvasRect()[3]);
    }

    CanvasToWorld(cP: number[]) {
        return CanvasToWorld(
            cP[0], cP[1],
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth(), 
            this.ui.GetCanvasRect()[2], this.ui.GetCanvasRect()[3]);
    }

    CanvasToWorldDelta(cD: number[]) {
        return CanvasToWorldDelta(
            cD[0], cD[1],
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1], 
            this.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth(), 
            this.ui.GetCanvasRect()[2], this.ui.GetCanvasRect()[3]);
    }

    CanvasToClient(cP: number[]) {
        return CanvasToClient(
            cP[0], cP[1],
            this.ui.GetCanvasRect()[0], this.ui.GetCanvasRect()[1]);
    }

    ClientToCanvas(sP: number[]) {
        return ClientToCanvas(
            sP[0], sP[1],
            this.ui.GetCanvasRect()[0], this.ui.GetCanvasRect()[1]);
    }

    WorldToClient(wP: number[]) {
        return this.CanvasToClient(this.WorldToCanvas(wP));
    }

    ClientToWorld(sP: number[]) {
        return this.CanvasToWorld(this.ClientToCanvas(sP));
    }
}