import { Box, Stack, SxProps, Theme, Tooltip } from '@mui/material';
import React, { PropsWithChildren } from 'react';

export interface ReadyButtonProps {
  w?: string | number;
  h?: string | number;
  c?: string;
  bc?: string;
  hc?: string;
  hbc?: string;
  dc?: string;
  dbc?: string;
  ai?: string;
  jc?: string;
  toolTip?: string;
  disabled?: boolean;
  pressed?: boolean;
  sx?: SxProps<Theme> | undefined
}

const defaultProps = {
  w: undefined,
  h: 24,
  c: 'lightgray',
  bc: 'gray',
  hc: 'white',
  hbc: 'lightgray',
  dc: 'black',
  dbc: 'gray',
  ai: 'center',
  jc: 'center',
  toolTip: '',
  disabled: false,
  pressed: false,
};

export const ReadyButton = (propsIn: PropsWithChildren<ReadyButtonProps>) => {
  const props = {...defaultProps, ...propsIn};
  const disabledBlock = (
    <Tooltip title={props.toolTip}>
      <Stack direction={'row'} alignItems={props.ai} justifyContent={props.jc} sx={{...{
          bgcolor: props.dbc, 
          color: props.dc, 
          p: 1, 
          width: props.w,
          height: props.h,
          borderRadius: '4px'
        }, 
        ...props.sx}}>
        {props.children}
      </Stack>
    </Tooltip>
  );
  const enabledBlock = (
    <Tooltip title={props.toolTip}>
      <Stack direction={'row'} alignItems={props.ai} justifyContent={props.jc} sx={{...{
        bgcolor: props.bc, 
        color: props.c, 
        p: 1, 
        width: props.w,
        height: props.h,
        borderRadius: '4px',
        '&:hover': {
          backgroundColor: `${props.hbc} !important`,
          color: `${props.hc} !important`
        }}, 
        ...props.sx}}>
        {props.children}
      </Stack>
    </Tooltip>
  );
  const pressedBlock = (
    <Tooltip title={props.toolTip}>
      <Stack direction={'row'} alignItems={props.ai} justifyContent={props.jc} sx={{...{
        bgcolor: props.hbc, 
        color: props.hc, 
        p: 1, 
        width: props.w,
        height: props.h,
        borderRadius: '4px',
        }, 
        ...props.sx}}>
        {props.children}
      </Stack>
    </Tooltip>
  );
  return props.disabled ? disabledBlock : props.pressed ? pressedBlock : enabledBlock;
};