import { Arc } from "../doc/extended/entities/arc";
import { IEntity, dat } from "../doc/extended/entities/entity";
import { Line } from "../doc/extended/entities/line";
import { Poly } from "../doc/extended/entities/poly";
import { GenId } from "../tsutils/id";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { AcceleratorKeyCmd } from "./acceleratorKey";
import { Command } from "./command";
import { PromptButtonCmd } from "./promptButton";
import { ApiNewChange } from "../api/doc";

export class CreatePolyCmd extends Command{
    constructor() {
        super("CreatePolyCmd");
    }

    Process(store: Store) {
        let poly = new Poly(store.GenEntityId(), [], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
        let line = new Line("", [0,0], [1,1], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
        let arcP0 = [0,0];
        let arcP1 = [0,0];

        store.ui.Set().promptBar.Set("PLINE", "Select first point or", ["Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateLineWithPoint(poly, line, arcP0, arcP1, wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            }
        ));
    }

    CreateLineWithPoint(poly: Poly, line: Line, arcP0: number[], arcP1: number[], wP: number[], store: Store) {
        line.p0 = [...wP];

        store.ui.Set().promptBar.Set("PLINE", "Select next point or", ["Arc", "Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                line.p1 = [...wP];
                
                if (!store.GetActiveDoc().HasEntity(poly))
                    this.DoAddEntityToNewPolyAction(poly, line.Clone(""), store);
                else
                    this.DoAddEntityToExistingPolyAction(poly, line.Clone(""), store);

                this.CreateLineWithPoint(poly, line, arcP0, arcP1, wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "AcceleratorKeyCmd")
                {
                    const event = (cmd as AcceleratorKeyCmd).event;
                    if (event.key.toUpperCase() === "A") cmd = new PromptButtonCmd("Arc");
                }
                if (cmd.name === "PromptButtonCmd")
                {
                    switch ((cmd as PromptButtonCmd).bName) {
                        case "Arc": {
                            this.CreateArcWith1Point(poly, line, arcP0, arcP1, wP, store);
                        } break;
                        case "Cancel": {
                            store.SetActiveState(new EditorState());
                        } break;
                    }
                }
            },
            (ctx, wP) => { // gizmo
                line.p1 = [...wP];
                const polyTemp = poly.Clone("");
                polyTemp.es.push(line);
                polyTemp.Draw(ctx, store);
            }
        ));
    }

    CreateArcWith1Point(poly: Poly, line: Line, arcP0: number[], arcP1: number[], wP: number[], store: Store) {
        arcP0[0] = wP[0]; arcP0[1] = wP[1];

        store.ui.Set().promptBar.Set("PLINE", "Select second point of arc or", ["Line", "Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateArcWith2Points(poly, line, arcP0, arcP1, wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "AcceleratorKeyCmd")
                {
                    const event = (cmd as AcceleratorKeyCmd).event;
                    if (event.key.toUpperCase() === "L") cmd = new PromptButtonCmd("Line");
                }
                if (cmd.name === "PromptButtonCmd")
                {
                    switch ((cmd as PromptButtonCmd).bName) {
                        case "Line": {
                            this.CreateLineWithPoint(poly, line, arcP0, arcP1, wP, store);
                        } break;
                        case "Cancel": {
                            store.SetActiveState(new EditorState());
                        } break;
                    }
                }
            },
            (ctx, wP) => { // gizmo
                ctx.setLineDash([10, 3]);
                ctx.strokeStyle = store.GetActiveDoc().GetActiveLayer().color;
                const canvasP0 = store.WorldToCanvas(arcP0);
                const canvasP1 = store.WorldToCanvas(wP);
                ctx.moveTo(canvasP0[0], canvasP0[1]);
                ctx.lineTo(canvasP1[0], canvasP1[1]);
                ctx.stroke();
                ctx.setLineDash([]);
            }
        ));
    }

    CreateArcWith2Points(poly: Poly, line: Line, arcP0: number[], arcP1: number[], wP: number[], store: Store) {
        arcP1[0] = wP[0]; arcP1[1] = wP[1];

        store.ui.Set().promptBar.Set("PLINE", "Select end point of arc or", ["Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                const arc = new Arc("", [0,0], 1, [1,1], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
                arc.Set3Points(arcP0, arcP1, [...wP]);

                if (!store.GetActiveDoc().HasEntity(poly))
                    this.DoAddEntityToNewPolyAction(poly, arc, store);
                else
                    this.DoAddEntityToExistingPolyAction(poly, arc, store);

                this.CreateArcWith1Point(poly, line, arcP0, arcP1, wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "AcceleratorKeyCmd")
                {
                    const event = (cmd as AcceleratorKeyCmd).event;
                    if (event.key.toUpperCase() === "A") cmd = new PromptButtonCmd("Arc");
                }
                if (cmd.name === "PromptButtonCmd")
                {
                    switch ((cmd as PromptButtonCmd).bName) {
                        case "Cancel": {
                            store.SetActiveState(new EditorState());
                        } break;
                    }
                }
            },
            (ctx, wP) => { // gizmo
                const arc = new Arc("", [0,0], 1, [1,1], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
                arc.Set3Points(arcP0, arcP1, [...wP]);
                const polyTemp = poly.Clone("");
                polyTemp.es.push(arc);
                polyTemp.Draw(ctx, store);
            }
        ));
    }

    DoAddEntityToNewPolyAction(poly: Poly, entity: IEntity, store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                poly.es.push(dat(entity));
                store.GetActiveDoc().AddMask(poly);
                ApiNewChange(store.GetActiveDoc().AddEntity(poly), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                poly.es.pop();
                store.GetActiveDoc().RemoveMask(poly);
                ApiNewChange(store.GetActiveDoc().RemoveEntity(poly), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }

    DoAddEntityToExistingPolyAction(poly: Poly, entity: IEntity, store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                poly.es.push(dat(entity));
                store.GetActiveDoc().AddMask(poly);
                ApiNewChange(store.GetActiveDoc().UpdateEntity(poly), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                poly.es.pop();
                store.GetActiveDoc().RemoveMask(poly);
                ApiNewChange(store.GetActiveDoc().UpdateEntity(poly), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}