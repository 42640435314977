import Box from '@mui/material/Box';

import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Store } from '../../../store/store';
import SnapButton from './snapButton';
import GridButton from './gridButton';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export interface BottomBarProps {
    store: Store;
}

export default function BottomBar(props: BottomBarProps) {
    return (
        <Box sx={{ bgcolor: 'black' }}>
            <Stack direction={"row"} justifyContent="space-between" sx={{ bgcolor: 'gray', p: 1, borderRadius:'4px' }}>
                <Stack direction={"row"} spacing={1}>
                    <Stack direction={"row"} spacing={1} sx={{ bgcolor: 'lightgray', width: 140, borderRadius:'4px' }} alignItems={'center'} justifyContent={'flex-end'}>
                        <Typography sx={{ color: 'black', pr:1, pl: 1, overflow: 'clip', whiteSpace: 'nowrap' }}>Model asdfasdfsad sad</Typography>
                        <Divider orientation='vertical' sx={{ bgcolor: 'lightgray' }}/>
                        <KeyboardArrowUpIcon sx={{ color: 'black', pr:1 }}/>
                    </Stack>
                    <Stack sx={{ width: 100 }} justifyContent={'center'}>
                        <Typography noWrap align='center' sx={{ color: 'white', pr:1, pl: 1, textOverflow: 'ellipsis', overflow: 'clip', whiteSpace: 'nowrap' }}>Layout1 asdf sad sad fsad sa f fads</Typography>
                    </Stack>
                    <Stack sx={{ width: 100 }} alignItems={'center'} justifyContent={'center'}>
                        <Typography sx={{ color: 'white', pr:1, pl: 1, overflow: 'clip', whiteSpace: 'nowrap' }}>Layout2</Typography>
                    </Stack>
                    <Divider orientation='vertical' sx={{ bgcolor: 'lightgray', m:2 }}/>
                    <IconButton sx={{ color: 'white' }}><AddIcon/></IconButton>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                    <SnapButton/>
                    <GridButton/>
                </Stack>
            </Stack>
        </Box>
    )
}