import React, { useEffect, useReducer } from 'react';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { Store } from '../../store/store';
import { Frame } from '../frame/frame';
import { WorldSpace } from '../../doc/extended/spaces/worldSpace';
import { Line } from '../../doc/extended/entities/line';
import { Layer } from '../../doc/extended/layers/layer';
import FileMenu from '../menus/fileMenu';
import EditMenu from '../menus/editMenu';
import SelectionMenu from '../menus/selectionMenu';
import { Circle } from '../../doc/extended/entities/circle';
import { PointsToArc } from '../../math/arc';
import { Arc } from '../../doc/extended/entities/arc';
import { Poly } from '../../doc/extended/entities/poly';
import LayerSelectMenu from '../menus/layerSelectMenu';
import { Textual } from '../../doc/extended/entities/textual';
import { MobileFrame } from '../mobileFrame/mobileFrame';
import MobileAppMenu from '../menus/mobileAppMenu';
import { MobileViewerState } from '../../states/mobileViewer';
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSimple from '../lib/loadingSimple';
import ProfileLoginMenu from '../menus/profileLoginMenu';
import ProfileLogoutMenu from '../menus/profileLogoutMenu';
import { ViewerState } from '../../states/viewer';
import { OpenFile } from '../lib/openFile';
import { BootState } from '../../states/boot';
import { IsProduction } from '../../tsutils/production';



var store = new Store();

setInterval(()=>{
  store.Tick();
}, IsProduction() ? 5 * 60000 : 10000);

console.log("creating websocket")
//const exampleSocket = new WebSocket("ws://localhost:80/api/uns/ws");
const exampleSocket = new WebSocket("wss://cadxi.com/api/uns/ws");
exampleSocket.onopen = (event) => {
  console.log("websocket onopen")
    exampleSocket.send("Here's some text that the server is urgently awaiting!");
};
exampleSocket.onmessage = (message) => {
    console.log(message.data);
    //exampleSocket.close();
};

// var worldSpace = new WorldSpace();

// var layer = new Layer();
// layer.name = 'Inside';
// layer.color = 'blue';

// var layer2 = new Layer();
// layer2.name = 'Walls';
// layer2.color = 'orange';

// var line1 = new Line([-1, 0], [1, 0], worldSpace, layer);
// var line2 = new Line([0, -1], [0, 1], worldSpace, layer);

// //var line1 = new Line([0, 0], [10, 0], worldSpace, layer);

// //var e2 = new Line([1, 0], [2, 0], worldSpace, layer);
// //var ee = new Arc([0, 0], 1, [Math.PI/2+0.5,-Math.PI-0.5], worldSpace, layer);
// //var e1 = new Line([0, 1], [-1, 0], worldSpace, layer);
// //var ep = new Poly([e2,ee,e1], worldSpace, layer)

// var text = new Textual([0,0,3,3], "a aaaaaa", 1, "Courrier New", worldSpace, layer);

// store.doc.Load(
//   [worldSpace],
//   [layer, layer2],
//   [line1,line2, text],
// );

//store.SetActiveState(IsMobile() ? new MobileViewerState() : new ViewerState());
//store.SetActiveState(new BootState());



function App() {
  const [, forceReactUpdate] = useReducer(x => x + 1, 0);
  store.ui.SetReactRefresh(forceReactUpdate);

  // let dynamicFrame;
  // if (!IsMobile()) {
  //   dynamicFrame = (<Frame store={store}/>);
  // }
  // else {
  //   dynamicFrame = (<MobileFrame store={store}/>);
  // }

  // useEffect(() => {  
  //   console.log(encodeURI(btoa(`FALSE:vdcoder_gmail.com:/peter/pan:thisfile.txt`)))
  //   console.log(store.ui.file);
  //   if (store.ui.file.hasFile && store.ui.file.isOwned && isAuthenticated)
  //     store.SetActiveState(IsMobile() ? new MobileViewerState() : new IdleState());
  //   else if (store.ui.file.hasFile)
  //     store.SetActiveState(IsMobile() ? new MobileViewerState() : new ViewerState());

  //   store.ui.FocusFrame();
  //   setTimeout(() => {
  //     if (!store.ui.IsInputFocus())
  //       store.ui.FocusFrame();
  //   }, 500);
  // })

  return store.GetActiveState().Render(store);

  // return (
  //   <>
  //     <BrowserRouter>
  //       <Routes>
  //         <Route path="/">
  //           <Route index element={dynamicFrame} />
  //           <Route path="desktop" element={<Frame store={store}/>} />
  //           <Route path="mobile" element={<MobileFrame store={store}/>} />
  //           <Route path="openfile/:hashkey" element={<OpenFile store={store}/>} />
  //         </Route>
  //       </Routes>
  //     </BrowserRouter>
      
  //     <FileMenu store={store}/>
  //     <EditMenu store={store}/>
  //     <SelectionMenu store={store}/>
  //     <LayerSelectMenu store={store}/>
  //     <ProfileLoginMenu store={store}/>
  //     <ProfileLogoutMenu store={store}/>
      
  //     <MobileAppMenu store={store}/>

  //     <TextEditor/>

  //     {isLoading? (<LoadingSimple/>) : ""}
  //   </>
  // );
}

export default App;
