import Cookies from "js-cookie";
import { Doc } from "../doc/extended/doc";
import { ClosedState } from "../states/closed";
import { MobileClosedState } from "../states/mobileClosed";
import { Store } from "../store/store";
import { IsMobile } from "../tsutils/mobile";
import { Command } from "./command";
import { MobileViewerState } from "../states/mobileViewer";
import { EditorState } from "../states/editor";

export class CloseCmd extends Command {
    doc: Doc | undefined;
    
    constructor(doc: Doc | undefined = undefined) {
        super("CloseCmd");
        this.doc = doc;
    }

    Process(store: Store) {
        if (this.doc === undefined) this.doc = store.GetActiveDoc();
        store.docs = store.docs.filter(d => d !== this.doc);
        if (store.docs.length > 0)
        {
            Cookies.set('selectedFileOwnerEmail', store.docs[0].email); 
            Cookies.set('selectedFileName', store.docs[0].fileName);
            store.SetActiveDoc(store.docs[0]);
            if (IsMobile() || store.docs[0].cannotEdit !== false)
            {
                if (IsMobile())
                    store.SetActiveState(new MobileClosedState());
                else
                    store.SetActiveState(new ClosedState());
            }
            else
            {
                store.SetActiveState(new EditorState());
            }
        }
        else
        {
            Cookies.remove('selectedFileOwnerEmail')
            Cookies.remove('selectedFileName');
            store.SetActiveDoc(undefined);
            if (IsMobile())
                store.SetActiveState(new MobileClosedState());
            else
                store.SetActiveState(new ClosedState());
        }
    }
}