import { Space } from "../doc/extended/spaces/space";
import { Store } from "../store/store";
import { Command } from "./command";

export class SelectAllCmd extends Command{
    constructor() {
        super("SelectAllCmd");
    }

    Process(store: Store) {
        store.GetActiveDoc().EntitiesForEach(entity => {
            if (entity.GetSpace() === store.GetActiveDoc().GetActiveSpace() && entity.GetLayer().isVisible) 
            {
                entity.SetIsSelected(true);
                store.ui.Set().selection.entities.add(entity);
            }
        })
    }
}