import { Store } from "../store/store";
import { ApiPostBinToJson, ApiPostJsonToBin } from "../tsutils/apiFetch";
import { BufferBuilder, SerializeString } from "../tsutils/binary";
import { API_HOST } from "./api";

export async function ApiDownloadFile(
    email: string, 
    fileName: string,
    store: Store
) {
    const data = await ApiPostJsonToBin(API_HOST, 'sec/doc/download', { email, fileName }, store.ui.account.accessToken);
    if (data === undefined) return undefined;
    return new Uint8Array(data);
}

export async function ApiNewChange(
    changeBuf: Uint8Array,
    email: string,
    fileName: string,
    store: Store
) {
    const bb = new BufferBuilder();
    bb.Add(SerializeString(JSON.stringify({
        email,
        fileName,
    })));
    bb.Add(changeBuf);

    console.log('ApiPostBinToJson', store.ui.account.accessToken)
    const data = await ApiPostBinToJson(API_HOST, 'sec/doc/change', bb.ToBuffer(), store.ui.account.accessToken);
    if (data === undefined) return undefined;
    return data.ok;
}

export async function ApiNewSnapshot(
    snapshotBuf: Uint8Array,
    email: string,
    fileName: string,
    store: Store
) {
    const bb = new BufferBuilder();
    bb.Add(SerializeString(JSON.stringify({
        email,
        fileName,
    })));
    bb.Add(snapshotBuf);

    const data = await ApiPostBinToJson(API_HOST, 'sec/doc/snapshot', bb.ToBuffer(), store.ui.account.accessToken);
    if (data === undefined) return undefined;
    return data.ok;
}