import { Dist } from "../math/point";
import { Circle } from "../doc/extended/entities/circle";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { Command } from "./command";
import { PromptInputCmd } from "./promptInput";
import { GenId } from "../tsutils/id";
import { ApiNewChange } from "../api/doc";

export class CreateCircleCmd extends Command{
    constructor() {
        super("CreateCircleCmd");
    }

    Process(store: Store) {
        store.ui.Set().promptBar.Set("CIRCLE", "Select center point or", ["Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateCircleWithPoint(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
            }
        ));
    }

    CreateCircleWithPoint(wP: number[], store: Store) {
        store.ui.Set().promptBar.Set("CIRCLE", "Specify radius or ", ["Cancel"], "Enter radius here");

        const circle = new Circle(store.GenEntityId(), [...wP], 1, store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());

        store.SetActiveState(new PointSelectState(
            wP => { // success
                circle.r = Dist(wP, circle.c);
                this.DoAddCircleAction(circle, store);

                store.SetActiveState(new EditorState());
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
                if (cmd.name === "PromptInputCmd") {
                    const r = parseFloat((cmd as PromptInputCmd).value);
                    if (isNaN(r))
                        console.log("r is NaN");
                    else {
                        circle.r = r;
                        store.GetActiveDoc().AddEntity(circle);

                        store.SetActiveState(new EditorState());
                    }
                }
            },
            (ctx, wP) => { // gizmo
                circle.r = Dist(wP, circle.c);
                circle.Draw(ctx, store);
            }
        ));
    }

    DoAddCircleAction(circle: Circle, store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                store.GetActiveDoc().AddMask(circle);
                ApiNewChange(store.GetActiveDoc().AddEntity(circle), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                store.GetActiveDoc().RemoveMask(circle);
                ApiNewChange(store.GetActiveDoc().RemoveEntity(circle), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}