import { ApiNewChange } from "../api/doc";
import { IEntity, dat } from "../doc/extended/entities/entity";
import { Line } from "../doc/extended/entities/line";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { BufferBuilder } from "../tsutils/binary";
import { Command } from "./command";

export class MoveCmd extends Command{
    constructor() {
        super("MoveCmd");
    }

    Process(store: Store) {
        store.ui.Set().promptBar.Set("MOVE", "Select from point or", ["Cancel"], "");
        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.MoveWithPoint(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            }
        ));
    }

    MoveWithPoint(wP: number[], store: Store) {
        store.ui.Set().promptBar.Set("MOVE", "Select target point or", ["Cancel"], "");

        const line = new Line(store.GenEntityId(), [...wP], [1,1], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());

        store.SetActiveState(new PointSelectState(
            wP => { // success
                line.p1 = [...wP];
                const selectedEntities = Array.from(store.ui.Get().selection.entities);
                this.DoMoveAction(selectedEntities, line.p0, line.p1, store);

                store.SetActiveState(new EditorState());
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
                line.p1 = [...wP];
                line.Draw(ctx, store);
            }
        ));
    }

    DoMoveAction(selectedEntities: IEntity[], fromP: number[], toP: number[], store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                const bb = new BufferBuilder();
                selectedEntities.forEach(entity => {
                    entity.MoveBy([toP[0] - fromP[0], toP[1] - fromP[1]]);
                    bb.Add(store.GetActiveDoc().UpdateEntity(dat(entity)));
                    store.GetActiveDoc().UpdateMask(entity);
                })
                ApiNewChange(bb.ToBuffer(), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                const bb = new BufferBuilder();
                selectedEntities.forEach(entity => {
                    entity.MoveBy([fromP[0] - toP[0], fromP[1] - toP[1]]);
                    bb.Add(store.GetActiveDoc().UpdateEntity(dat(entity)));
                    store.GetActiveDoc().UpdateMask(entity);
                })
                ApiNewChange(bb.ToBuffer(), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}