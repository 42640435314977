import { Store } from "../../store/store";
import { RectSelectCmd } from "../../commands/rectSelect";
import { Gesture } from "./gesture";

export class RectSelectGesture extends Gesture {

    MouseDown(event: any, store: Store) {
        if (event.button === 0) // left button
            store.ui.SetMouseRectSelectPos(store.ui.GetMousePos());
    }

    MouseUp(event: any, store: Store) {
        if (event.button === 0) // left button
        {
            const clientFromP = store.ui.GetMouseRectSelectPos();
            const clientToP = store.ui.GetMousePos();

            if (25 < (clientFromP[0] - clientToP[0]) * (clientFromP[0] - clientToP[0]) + (clientFromP[1] - clientToP[1]) * (clientFromP[1] - clientToP[1]))
            {
                const fromP = store.ClientToWorld(clientFromP);
                const toP = store.ClientToWorld(clientToP);
    
                store.ui.SetMouseRectSelectPos([]);
    
                store.GetActiveState().Command(new RectSelectCmd(fromP, toP, event.shiftKey), store);
            }
            else // Rect select is too close, a click
                store.ui.SetMouseRectSelectPos([]);
        }
    }

    MouseEnter(event: any, store: Store) {
        store.ui.SetMouseRectSelectPos([]);
    }

    MouseLeave(event: any, store: Store) {
        store.ui.SetMouseRectSelectPos([]);
    }
}