import { Store } from "../../store/store";
import { AcceleratorKeyCmd } from "../../commands/acceleratorKey";
import { CancelCmd } from "../../commands/cancel";
import { Gesture } from "./gesture";

export class KeyboardGesture extends Gesture {

    KeyDown(event: any, store: Store) {
        console.log(event.key)
        if (event.keyCode === 27) // Escape key
            store.GetActiveState().Command(new CancelCmd(), store);
        else 
            store.GetActiveState().Command(new AcceleratorKeyCmd(event), store);
    }
}