
export class LayerData {
    id: string = "";
    name: string = "Layer 0";
    color: string = "white";
    isVisible: boolean = true;
    extra: any = undefined;

    constructor(id: string, name: string, color: string, isVisible: boolean) {
        this.id = id;
        this.name = name;
        this.color = color;
        this.isVisible = isVisible;
    }
}