import { GenId } from "../../../tsutils/id";
import { CircleAreaIntersects } from "../../../math/intersects";
import { PointCircleNearestPoint } from "../../../math/nearest";
import { PointEquals } from "../../../math/point";
import { PointInRect } from "../../../math/rect";
import { CenterPointSnap } from "../../../snaps/centerPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { QuadrantPointSnap } from "../../../snaps/quadrantPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { CircleData } from "../../data/entities/circleData";
import { EntityData } from "../../data/entities/entityData";
import { LayerData } from "../../data/layers/layerData";
import { SpaceData } from "../../data/spaces/spaceData";
import { Layer } from "../layers/layer";
import { Space } from "../spaces/space";
import { IEntity } from "./entity";

export class Circle extends CircleData implements IEntity {
    
    constructor(id: string, c: number[], r: number, space: Space, layer: Layer) {
        super(id, c, r, space, layer);
        this.extra = { isSelected: false }
    }

    GetId(): string { return this.id; }

    GetLayer(): Layer { return this.layer as Layer; }

    GetSpace(): Space { return this.space as Space; }

    IsSelected(): boolean { return this.extra.isSelected; }

    SetIsSelected(value: boolean) { this.extra.isSelected = value; }

    Clone(newId: string) { return new Circle(newId, [...this.c], this.r, this.GetSpace(), this.GetLayer()) }

    GetVisibleBoundingRect(): number[] {
        return [this.c[0] - this.r, this.c[1] - this.r, 2 * this.r, 2 * this.r];
    }

    GetInteractiveBoundingRect() { return this.GetVisibleBoundingRect(); }
    
    IntersectsArea(areaRect: number[]): boolean {
        return PointInRect(areaRect[0], areaRect[1], areaRect[2], areaRect[3], this.c[0], this.c[1])
            || CircleAreaIntersects(this.c[0], this.c[1], this.r, areaRect[0], areaRect[1], areaRect[2], areaRect[3]);
    }

    GetSrcSnaps(): number[][] { 
        return [ this.c ]; 
    }

    GetDestSnaps(wPointer: number[]): Snap[] {
        const snaps = [ 
            new CenterPointSnap(this.c), 
            new QuadrantPointSnap([this.c[0] - this.r, this.c[1]]), 
            new QuadrantPointSnap([this.c[0] + this.r, this.c[1]]), 
            new QuadrantPointSnap([this.c[0], this.c[1] - this.r]), 
            new QuadrantPointSnap([this.c[0], this.c[1] + this.r])]; 
        const nearestP = PointCircleNearestPoint(this.c[0], this.c[1], this.r, wPointer[0], wPointer[1]);
        if ( nearestP !== undefined )
            snaps.push(new NearestPointSnap(nearestP));
        return snaps;
    }

    MoveBy(delta: number[]) {
        this.c[0] += delta[0]; this.c[1] += delta[1];
    }

    MoveSrcSnap(snapP: number[], newP: number[]) {
        const srcSnaps = this.GetSrcSnaps();
        if (PointEquals(snapP, srcSnaps[0]))
        {
            this.c = [...newP];
            return [];
        }
        return [];
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        ctx.strokeStyle = this.layer.color;
        ctx.beginPath();
        const sC = store.WorldToCanvas(this.c);
        const sR = Math.abs(store.WorldToCanvasDelta([this.r, 0])[0]);
        ctx.arc(sC[0], sC[1], sR, 0, 2 * Math.PI);
        ctx.stroke();
    }

    DrawSelected(ctx: CanvasRenderingContext2D, store: Store) {
        ctx.setLineDash([5, 3]);   
        ctx.strokeStyle = this.layer.color;
        ctx.beginPath();
        const sC = store.WorldToCanvas(this.c);
        const sR = Math.abs(store.WorldToCanvasDelta([this.r, 0])[0]);
        ctx.arc(sC[0], sC[1], sR, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.setLineDash([]);
    }
}