import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Divider, Stack, Typography, popoverClasses } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export interface FileMenuProps {
    store: Store;
}

export default function FileMenu(props: FileMenuProps) {
    const handleClose = () => {
        props.store.ui.Set().fileMenu.anchorEl = null;
    };
    return (
        <Menu anchorEl={props.store.ui.Get().fileMenu.anchorEl} open={Boolean(props.store.ui.Get().fileMenu.anchorEl)} sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} onClose={handleClose}>
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <SaveIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Save</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>⌘S</Typography>
                </Stack>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <SaveAsIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Save As ...</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <ExitToAppIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Close</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>⌘X</Typography>
                </Stack>
            </MenuItem>
        </Menu>
    );
}