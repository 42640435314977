import { Store } from "../../store/store";

export class Gesture {
    MouseDown(event: any, store: Store) {}      
    MouseUp(event: any, store: Store) {}
    MouseMove(event: any, store: Store) {}
    MouseWheel(event: any, store: Store) {}
    MouseEnter(event: any, store: Store) {}
    MouseLeave(event: any, store: Store) {}
    MouseDblClick(event: any, store: Store) {}
    TouchesPan(event: any, store: Store) {}
    TouchesPinch(event: any, store: Store) {}
    KeyDown(event: any, store: Store) {}
    KeyUp(event: any, store: Store) {}
}