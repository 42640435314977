import { GenId } from "../../../tsutils/id";
import { SegmentAreaIntersects } from "../../../math/intersects";
import { PointLineNearestPoint, PointSegmentNearestPoint } from "../../../math/nearest";
import { PointEquals } from "../../../math/point";
import { WorldToCanvas } from "../../../math/projection";
import { EndPointSnap } from "../../../snaps/endPoint";
import { MidPointSnap } from "../../../snaps/midPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { LineData } from "../../data/entities/lineData";
import { Layer } from "../layers/layer";
import { Space } from "../spaces/space";
import { IEntity } from "./entity";

export class Line extends LineData implements IEntity {
    
    constructor(id: string, p0: number[], p1: number[], space: Space, layer: Layer) {
        super(id, p0, p1, space, layer);
        this.extra = { isSelected: false }
    }

    GetId(): string { return this.id; }

    GetLayer(): Layer { return this.layer as Layer; }

    GetSpace(): Space { return this.space as Space; }

    IsSelected(): boolean { return this.extra.isSelected; }

    SetIsSelected(value: boolean) { this.extra.isSelected = value; }

    Clone(newId: string) { return new Line(newId, [...this.p0], [...this.p1], this.GetSpace(), this.GetLayer()) }

    GetVisibleBoundingRect(): number[] {
        return [
            Math.min(this.p0[0], this.p1[0]),
            Math.min(this.p0[1], this.p1[1]),
            Math.abs(this.p0[0] - this.p1[0]),
            Math.abs(this.p0[1] - this.p1[1])];
    }
    
    GetInteractiveBoundingRect() { return this.GetVisibleBoundingRect(); }
    
    IntersectsArea(areaRect: number[]): boolean {
        return SegmentAreaIntersects(this.p0[0], this.p0[1], this.p1[0], this.p1[1], areaRect[0], areaRect[1], areaRect[2], areaRect[3]);
    }

    GetSrcSnaps(): number[][] { 
        return [ this.p0, [0.5 * (this.p0[0] + this.p1[0]), 0.5 * (this.p0[1] + this.p1[1])], this.p1 ]; 
    }

    GetDestSnaps(wPointer: number[]): Snap[] {
        const snaps = [ 
            new EndPointSnap(this.p0), 
            new MidPointSnap([0.5 * (this.p0[0] + this.p1[0]), 0.5 * (this.p0[1] + this.p1[1])]), 
            new EndPointSnap(this.p1) ]; 
        const nearestP = PointSegmentNearestPoint(this.p0[0], this.p0[1], this.p1[0], this.p1[1], wPointer[0], wPointer[1]);
        if ( nearestP !== undefined && 
             (  nearestP[0] !== this.p0[0] || nearestP[0] !== this.p1[0]
             || nearestP[1] !== this.p0[1] || nearestP[1] !== this.p1[1] ))
                snaps.push(new NearestPointSnap(nearestP));
        return snaps;
    }

    MoveBy(delta: number[]) {
        this.p0[0] += delta[0]; this.p0[1] += delta[1];
        this.p1[0] += delta[0]; this.p1[1] += delta[1];
    }

    MoveSrcSnap(snapP: number[], newP: number[]) {
        const srcSnaps = this.GetSrcSnaps();
        if (PointEquals(snapP, srcSnaps[0])) {
            this.p0 = [...newP];
            return [];
        }
        else if (PointEquals(snapP, srcSnaps[2])) {
            this.p1 = [...newP];
            return [];
        }
        else if (PointEquals(snapP, srcSnaps[1])) {
            const oldP0 = [this.p0[0], this.p0[1]];
            const oldP1 = [this.p1[0], this.p1[1]];
            const delta = [newP[0] - snapP[0], newP[1] - snapP[1]];
            this.p0[0] += delta[0]; this.p0[1] += delta[1];
            this.p1[0] += delta[0]; this.p1[1] += delta[1];
            return [[oldP0, this.p0], [oldP1, this.p1]]; // inform of changed endpoints
        }
        return [];
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        ctx.strokeStyle = this.layer.color;
        ctx.beginPath();
        const sP0 = store.WorldToCanvas(this.p0);
        const sP1 = store.WorldToCanvas(this.p1);
        ctx.moveTo(sP0[0], sP0[1]);
        ctx.lineTo(sP1[0], sP1[1]);
        ctx.stroke();
    }

    DrawSelected(ctx: CanvasRenderingContext2D, store: Store) {
        ctx.strokeStyle = this.layer.color;
        ctx.setLineDash([5, 3]);    
        ctx.beginPath();
        const sP0 = store.WorldToCanvas(this.p0);
        const sP1 = store.WorldToCanvas(this.p1);
        ctx.moveTo(sP0[0], sP0[1]);
        ctx.lineTo(sP1[0], sP1[1]);
        ctx.stroke();
        ctx.setLineDash([]);
    }
}