import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Avatar, Box, Divider, ListItemIcon, Stack, Typography, popoverClasses } from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Check } from "@mui/icons-material";
import NotInterestedOutlinedIcon from '@mui/icons-material/NotInterestedOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { CloseCmd } from "../../commands/close";

export interface MobileAppMenuProps {
    store: Store;
}

export default function MobileAppMenu(props: MobileAppMenuProps) {
    const close = () => {
        props.store.ui.Set().mobileAppMenu.anchorEl = null;
    };
    return (
        <Menu anchorEl={props.store.ui.Get().mobileAppMenu.anchorEl} 
              open={Boolean(props.store.ui.Get().mobileAppMenu.anchorEl)} 
              sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} 
              onClose={close}
              transformOrigin={{
                vertical: -5,
                horizontal: "center",
              }}>
            <MenuItem onClick={close} disabled={true}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <Typography>Drawing</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem onClick={close}>
                <ListItemIcon>
                    <Check fontSize="large" sx={{mr:1}}/>
                </ListItemIcon>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <Typography>Model</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem onClick={close}>
                <ListItemIcon>
                    <Check fontSize="large" sx={{color:'lightgray', mr:1}}/>
                </ListItemIcon>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <Typography>Layout 1</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem onClick={close}>
                <ListItemIcon>
                    <Check fontSize="large" sx={{color:'lightgray', mr:1}}/>
                </ListItemIcon>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <Typography>Layout 2</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={close}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <FolderOutlinedIcon fontSize="large" sx={{mr:1}}/>
                        <Typography>Files</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem onClick={() => { props.store.GetActiveState().Command(new CloseCmd(), props.store); close(); }}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <NotInterestedOutlinedIcon fontSize="large" sx={{mr:1}}/>
                        <Typography>Close</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={close}>
                <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'} justifyContent={'end'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{mr:1}}>
                        <Avatar alt={props.store.ui.account.name} src={props.store.ui.account.avatarUrl} />
                    </Stack>
                    <ArrowRightIcon sx={{float: "right"}}/>
                </Stack>
            </MenuItem>
        </Menu>
    );
}