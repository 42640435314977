import { Dist, DistSqr } from "../math/point";
import { Store } from "../store/store";
import { AcceleratorKeyCmd } from "../commands/acceleratorKey";
import { Command } from "../commands/command";
import { KeyboardGesture } from "./gestures/keyboard";
import { PanGesture } from "./gestures/pan";
import { ZoomWheelGesture } from "./gestures/zoomWheel";
import { Snap } from "../snaps/snap";
import { State } from "./state";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack } from "@mui/material";
import AppBar from "../components/frame/top/appBar";
import ToolBar from "../components/frame/top/toolBar";
import LeftPannel from "../components/frame/left/leftPannel";
import TopBar from "../components/frame/right/topBar";
import { Canvas } from "../components/lib/canvas";
import BottomBar from "../components/frame/right/bottomBar";
import PromptBar from "../components/frame/right/promptBar";
import Menus from "../components/menus/menus";
import Dialogs from "../components/dialogs/dialogs";
import LayerButton from "../components/frame/right/layerButton";

interface StateProps {
    state: PointSelectState;
    store: Store;
}

const StateRender: React.FC<StateProps> = ({state, store}) => {
    const { user, isAuthenticated, isLoading } = useAuth0();    

    const block = (
    <>
        <Box id="MainFrame" sx={{outline:'none'}}
            onKeyDown={e => state.KeyDown(e, store)}
            onKeyUp={e => state.KeyUp(e, store)}
            tabIndex={-1}>
            <Box sx={{ width: "100vw", height: "144px" }}>
                <AppBar store={store}/>
                <ToolBar store={store}/>
            </Box>
            <Stack direction={"row"}>
                <Box sx={{ width: "400px", height: "calc(100vh - 144px)" }}>
                    <LeftPannel/>
                </Box>
                <Stack>
                    <Box sx={{ width: "calc(100vw - 400px)", height: "40px" }}>
                        <TopBar store={store}/>
                    </Box>
                    <Canvas draw={ctx => state.Draw(ctx, store)} 
                            mouseDown={e => state.MouseDown(e, store)}
                            mouseUp={e => state.MouseUp(e, store)}
                            mouseMove={e => state.MouseMove(e, store)}
                            mouseWheel={e => state.MouseWheel(e, store)}
                            mouseEnter={e => state.MouseEnter(e, store)}
                            mouseLeave={e => state.MouseLeave(e, store)}
                            mouseDblClick={e => state.MouseDblClick(e, store)}
                            touchesPan={e => state.TouchesPan(e, store)}
                            touchesPinch={e => state.TouchesPinch(e, store)}
                            resizeAndOrMove={(x: number, y: number, w: number, h: number) => store.ui.SetCanvasRect(x,y,w,h)}
                            width= "calc(100vw - 400px)" height= "calc(100vh - 144px - 40px - 112px)"/>
                    <Box sx={{ width: "calc(100vw - 400px)", height: "112px" }}>
                        <PromptBar store={store}/>
                        <BottomBar store={store}/>
                    </Box>
                </Stack>
            </Stack>
            <LayerButton store={store}/>
            <canvas id="snapshot" width="200" height="150" style={{display:'none', position:'absolute',top:'0',left:'0',width:'200px',height:'150px'}}></canvas>
        </Box>
        <Menus store={store}/>
        <Dialogs store={store}/>
    </>);

    return block;
}

export class PointSelectState extends State {
    onSuccess: (wPoint: number[])=>void;
    onCancel: ()=>void;
    onCommand: (cmd: Command)=>void;
    drawGizmo: (ctx: CanvasRenderingContext2D, wPoint: number[])=>void = ()=>{};
    focusTmr: any;
    
    constructor(
        onSuccess: (wPoint: number[])=>void,
        onCancel: ()=>void,
        onCommand: (cmd: Command)=>void = ()=>{},
        drawGizmo: (ctx: CanvasRenderingContext2D, wPoint: number[])=>void = ()=>{}) {
        super([
            new PanGesture(),
            new ZoomWheelGesture(),
            new KeyboardGesture(),
        ],
        [
            "ZoomExtentsCmd",
        ]);
        this.onSuccess = onSuccess;
        this.onCancel = onCancel;
        this.onCommand = onCommand;
        this.drawGizmo = drawGizmo;
    }

    Activate(store: Store) {
        if (!store.ui.IsInputFocus()) store.ui.FocusFrame();
        this.focusTmr = setInterval(() => {if (!store.ui.IsInputFocus()) store.ui.FocusFrame()}, 500);
    }

    Deactivate(store: Store) {
        clearInterval(this.focusTmr);
    }

    Command(cmd: Command, store: Store) {
        super.Command(cmd, store);

        this.onCommand(cmd);

        switch (cmd.name) {
            case "CancelCmd": {
                this.onCancel();
            } break;
            case "AcceleratorKeyCmd": {
                AcceleratorKeyCmd.UpdatePromptInput((cmd as AcceleratorKeyCmd).event, store);
                this.onCommand(cmd);
            } break;
            case "PromptButtonCmd": {
                this.onCommand(cmd);
            } break;
            case "PromptInputCmd": {
                this.onCommand(cmd);
            } break;
        }        
    }

    MouseDown(event: any, store: Store) {
        super.MouseDown(event, store);
    }

    MouseUp(event: any, store: Store) {
        super.MouseUp(event, store);

        if (event.button === 0)
        {
            // Snap mouse
            let worldMouse = store.ClientToWorld(store.ui.GetMousePos());
            const wSelectRadius = store.CanvasToWorldDelta([10, 0])[0];
            const nearestAvoidance = 0.4 * wSelectRadius;
            let nearestP: number[] = [0,0], nearestDist = Number.MAX_SAFE_INTEGER;
            store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaExact(
                store.GetActiveDoc().GetActiveSpace(),
                layer => layer.isVisible,
                () => true,
                [worldMouse[0] - wSelectRadius, worldMouse[1] - wSelectRadius, 2 * wSelectRadius, 2 * wSelectRadius]
            ).forEach(entity => {
                entity.GetDestSnaps(worldMouse).forEach(snap => {
                    let dist = Math.sqrt((snap.p[0] - worldMouse[0]) * (snap.p[0] - worldMouse[0]) + (snap.p[1] - worldMouse[1]) * (snap.p[1] - worldMouse[1]));
                    if (snap.type === "NEAREST_POINT")
                        dist += nearestAvoidance;
                    if (nearestDist > dist)
                    {
                        nearestP = snap.p;
                        nearestDist = dist;
                    }
                })
            })

            if (nearestDist !== Number.MAX_SAFE_INTEGER)
            {
                let canvasMouse = store.ClientToCanvas(store.ui.GetMousePos());
                const canvasNearestP = store.WorldToCanvas(nearestP);
                if (100 >= (canvasNearestP[0] - canvasMouse[0]) * (canvasNearestP[0] - canvasMouse[0]) + (canvasNearestP[1] - canvasMouse[1]) * (canvasNearestP[1] - canvasMouse[1]))
                    worldMouse = nearestP;
            }

            this.onSuccess(worldMouse);
        }
    }

    MouseMove(event: any, store: Store) {
        super.MouseMove(event, store);
    }

    MouseWheel(event: any, store: Store) {
        super.MouseWheel(event, store);
    }

    MouseEnter(event: any, store: Store) {
        super.MouseEnter(event, store);
    }

    MouseLeave(event: any, store: Store) {
        super.MouseLeave(event, store);
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        super.Draw(ctx, store);

        ctx.fillStyle = 'black'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = 'white'
        ctx.beginPath()
        ctx.arc(50, 100, 20*Math.sin(Math.random()*1000*0.05)**2, 0, 2*Math.PI)
        ctx.fill();

        store.GetActiveDoc().GetSpacialPartiionAccelerator()._draw(ctx, store);

        // Get visible entities for layer
        const boundsW = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth();
        const boundsH = Math.abs(store.CanvasToWorldDelta([0,store.ui.GetCanvasRect()[3]])[1]);
        const boundsX0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0] - 0.5 * boundsW;
        const boundsY0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1] - 0.5 * boundsH;
        const entities = store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaApproximate(
            store.GetActiveDoc().GetActiveSpace(),
            layer => layer.isVisible,
            () => true,
            [boundsX0, boundsY0, boundsW, boundsH]
        )

        // Draw entities
        entities.forEach(entity => {
            if (!entity.IsSelected())
                entity.Draw(ctx, store);
            else
                entity.DrawSelected(ctx, store);
        })

        // Draw src snaps
        entities.forEach(entity => {
            if (entity.IsSelected())
                entity.GetSrcSnaps().forEach(p => {
                    const canvasP = store.WorldToCanvas(p);
                    ctx.fillStyle = 'darkgray';
                    ctx.fillRect(canvasP[0] - 5, canvasP[1] - 5, 10, 10);
                })
        })
          
        // Snap mouse
        let nearestSnap: Snap | undefined = undefined;

        const worldMouse = store.ClientToWorld(store.ui.GetMousePos());
        const wSelectRadius = store.CanvasToWorldDelta([10, 0])[0];
        const nearestAvoidance = 0.4 * wSelectRadius;
        let nearestDist = Number.MAX_SAFE_INTEGER;
        store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaExact(
            store.GetActiveDoc().GetActiveSpace(),
            layer => layer.isVisible,
            () => true,
            [worldMouse[0] - wSelectRadius, worldMouse[1] - wSelectRadius, 2 * wSelectRadius, 2 * wSelectRadius]
        ).forEach(entity => {
            entity.GetDestSnaps(worldMouse).forEach(snap => {
                let dist = Dist(snap.p, worldMouse);
                if (snap.type === "NEAREST_POINT")
                    dist += nearestAvoidance;
                if (nearestDist > dist)
                {
                    nearestDist = dist;
                    nearestSnap = snap;
                }
            })
        })
        let canvasMouse = store.ClientToCanvas(store.ui.GetMousePos());
        if (nearestDist !== Number.MAX_SAFE_INTEGER)
        {
            const canvasNearestP = store.WorldToCanvas(nearestSnap!.p);
            if (100 >= DistSqr(canvasNearestP, canvasMouse))
            {
                canvasMouse = canvasNearestP;

                // Draw snap
                nearestSnap!.Draw(ctx, store);
            }
        }            

        // Draw gizmo from command
        this.drawGizmo(ctx, store.CanvasToWorld(canvasMouse));        

        ctx.strokeStyle = 'white';
        ctx.beginPath();
        ctx.setLineDash([5, 3]);
        ctx.moveTo(canvasMouse[0] - 40, canvasMouse[1]);
        ctx.lineTo(canvasMouse[0] - 5, canvasMouse[1]);
        ctx.moveTo(canvasMouse[0] + 5, canvasMouse[1]);
        ctx.lineTo(canvasMouse[0] + 40, canvasMouse[1]);
        ctx.moveTo(canvasMouse[0], canvasMouse[1] - 40);
        ctx.lineTo(canvasMouse[0], canvasMouse[1] - 5);
        ctx.moveTo(canvasMouse[0], canvasMouse[1] + 5);
        ctx.lineTo(canvasMouse[0], canvasMouse[1] + 40);
        ctx.stroke();
        ctx.beginPath();
        ctx.setLineDash([]);
        ctx.moveTo(canvasMouse[0], canvasMouse[1] - 5);
        ctx.lineTo(canvasMouse[0], canvasMouse[1] + 5);
        ctx.moveTo(canvasMouse[0] - 5, canvasMouse[1]);
        ctx.lineTo(canvasMouse[0] + 5, canvasMouse[1]);
        ctx.stroke();
    }

    Render(store: Store) {
        return <StateRender state={this} store={store}/>
    }
}