import { Store } from "../../store/store";
import FileMenu from "./fileMenu";
import EditMenu from "./editMenu";
import SelectionMenu from "./selectionMenu";
import LayerSelectMenu from "./layerSelectMenu";
import ProfileLoginMenu from "./profileLoginMenu";
import ProfileLogoutMenu from "./profileLogoutMenu";
import MobileAppMenu from "./mobileAppMenu";
import FileMenuClosed from "./fileMenuClosed";
import MobileAppMenuClosed from "./mobileAppMenuClosed";

export interface MenusProps {
    store: Store;
}

export default function Menus(props: MenusProps) {
    return (
        <>
            <FileMenu store={props.store}/>
            <FileMenuClosed store={props.store}/>
            <EditMenu store={props.store}/>
            <SelectionMenu store={props.store}/>
            <LayerSelectMenu store={props.store}/>
            <ProfileLoginMenu store={props.store}/>
            <ProfileLogoutMenu store={props.store}/>

            <MobileAppMenu store={props.store}/>
            <MobileAppMenuClosed store={props.store}/>
        </>
    );
}