import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Divider, Stack, Typography, popoverClasses } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { useAuth0 } from "@auth0/auth0-react";

export interface FileMenuClosedProps {
    store: Store;
}

export default function FileMenuClosed(props: FileMenuClosedProps) {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    
    const newClick = () => {
        if (isAuthenticated) {

        }
        else loginWithRedirect();
    }

    const openClick = () => {
        if (isAuthenticated) {

        }
        else loginWithRedirect();
    }

    const uploadClick = () => {
        if (isAuthenticated) {

        }
        else loginWithRedirect();
    }

    const filesClick = () => {
        if (isAuthenticated) {

        }
        else loginWithRedirect();
    }

    const exitClick = () => {
        window.location.href = "https://cadxi.com";
    }

    const settingsClick = () => {
        if (isAuthenticated) {

        }
        else loginWithRedirect();
    }

    const handleClose = () => {
        props.store.ui.Set().fileMenuClosed.anchorEl = null;
    };
    return (
        <Menu anchorEl={props.store.ui.Get().fileMenuClosed.anchorEl} open={Boolean(props.store.ui.Get().fileMenuClosed.anchorEl)} sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} onClose={handleClose}>
            <MenuItem onClick={newClick}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <InsertDriveFileOutlinedIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>New</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem onClick={openClick}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <FileOpenOutlinedIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Open</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={uploadClick}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <FileUploadOutlinedIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Upload</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <MenuItem onClick={filesClick}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <FolderOutlinedIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Files</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={exitClick}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <DoorFrontOutlinedIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Exit</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
        </Menu>
    );
}