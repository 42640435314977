
import { DocData } from "../data/docData";
import { LayerData } from "../data/layers/layerData";
import { SpaceData } from "../data/spaces/spaceData";
import { Line } from "./entities/line";
import { Layer } from "./layers/layer";
import { Space } from "./spaces/space";
import { EntityData } from "../data/entities/entityData";
import { IEntity, dat, ext } from "./entities/entity";
import { SpacialPartiionAccelerator } from "../../accelerators/spacialPartitionAccelerator";
import { Textual } from "./entities/textual";
import { Poly } from "./entities/poly";
import { Arc } from "./entities/arc";
import { Circle } from "./entities/circle";
import { UndoableHistory } from "./undoableHistory";

export class Doc extends DocData {
    email: string;
    fileName: string;
    cannotEdit: boolean | undefined;
    activeSpace: Space | null = null;
    activeLayer: Layer | null = null;
    spacialPartiionAccelerator: SpacialPartiionAccelerator;
    undoableHistory = new UndoableHistory();
    snapshotIsDirty: boolean = false;

    constructor(email: string, fileName: string, cannotEdit: boolean | undefined) {
        super();
        this.email = email;
        this.fileName = fileName;
        this.cannotEdit = cannotEdit;
        this.spacialPartiionAccelerator = new SpacialPartiionAccelerator();
    }

    GetEmail() { return this.email; }

    GetFileName() { return this.fileName; }
    SetFileName(fileName: string) { this.fileName = fileName; }

    CreateSpace(id: string) { return new Space(id); }
    CreateLayer(id: string, name: string, color: string, isVisible: boolean) { return new Layer(id, name, color, isVisible); }
    CreateLine(id: string, p0: number[], p1: number[], space: SpaceData, layer: LayerData) { return new Line(id, p0, p1, space as Space, layer as Layer); }
    CreateCircle(id: string, c: number[], r: number, space: SpaceData, layer: LayerData) { return new Circle(id, c, r, space as Space, layer as Layer); }
    CreateArc(id: string, c: number[], r: number, a: number[], space: SpaceData, layer: LayerData) { return new Arc(id, c, r, a, space as Space, layer as Layer); }
    CreatePoly(id: string, es: EntityData[], space: SpaceData, layer: LayerData) { return new Poly(id, es.map(e => ext(e)), space as Space, layer as Layer); }
    CreateTextual(id: string, area: number[], text: string, h: number, f: string, space: SpaceData, layer: LayerData) { return new Textual(id, area, text, h, f, space as Space, layer as Layer); }

    Deserialize(buffer: Uint8Array) {
        super.Deserialize(buffer);
        this.activeSpace = this.spaces.values().next().value as Space;
        this.activeLayer = this.layers.values().next().value as Layer;

        const spaces: Space[] = [];
        this.spaces.forEach(sp => spaces.push(sp as Space));
        const layers: Layer[] = [];
        this.layers.forEach(l => layers.push(l as Layer));
        const entities: IEntity[] = [];
        this.entities.forEach(e => entities.push(ext(e)));
        this.spacialPartiionAccelerator.Load(spaces, layers, entities);
    }

    DeserializeChange(buffer: Uint8Array, offset: { value: number }) {
        super.DeserializeChange(buffer, offset);
        this.snapshotIsDirty = true;
    }

    GetActiveSpace(): Space { return this.activeSpace!; }

    GetActiveLayer(): Layer { return this.activeLayer!; }
    SetActiveLayer(layer: Layer) { this.activeLayer = layer; }

    LayersForEach(cb: (layer: Layer, index: number)=>void) { 
        let i = 0;
        this.layers.forEach(layer => { cb(layer, i); i++ }); 
    }

    EntitiesForEach(cb: (entity: IEntity, index: number)=>void) { 
        let i = 0;
        this.entities.forEach(entity => { cb(ext(entity), i); i++ }); 
    }

    HasEntity(entity: IEntity) {
        return this.entities.has(entity.GetId());
    }

    AddMask(entity: IEntity) {
        this.GetSpacialPartiionAccelerator().AddEntity(entity);
    }
    UpdateMask(entity: IEntity) {
        this.GetSpacialPartiionAccelerator().UpdateEntity(entity);
    }
    RemoveMask(entity: IEntity) {
        this.GetSpacialPartiionAccelerator().RemoveEntity(entity);
    }

    GetSpacialPartiionAccelerator() { return this.spacialPartiionAccelerator; }

    
}