import { IEntity, dat } from "../doc/extended/entities/entity";
import { Textual } from "../doc/extended/entities/textual";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { Command } from "./command";
import { BufferBuilder } from "../tsutils/binary";
import { ApiNewChange } from "../api/doc";

export class SnapMoveCmd extends Command{
    entities: IEntity[]; 
    snap: number[];

    constructor(entities: IEntity[], snap: number[]) {
        super("SnapMoveCmd"); this.entities = entities; this.snap = snap;
    }

    Process(store: Store) {
        console.log('snap move')
        store.SetActiveState(new PointSelectState(
            wPoint => { // onSuccess
                this.DoMoveAction(this.entities, this.snap, wPoint, store);

                store.SetActiveState(new EditorState());
            }, 
            () => { // onCancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
            },
            (ctx, wPoint) => { // drawGizmo
                this.entities.forEach(entity => {
                    const tempEntity = entity.Clone("");
                    tempEntity.MoveSrcSnap(this.snap, wPoint);
                    if (tempEntity instanceof Textual)
                        tempEntity.DrawSelected(ctx, store);
                    else
                        tempEntity.Draw(ctx, store);
                })
            }
        ))
    }

    DoMoveAction(entities: IEntity[], snap: number[], wPoint: number[], store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                const bb = new BufferBuilder();
                entities.forEach(entity => {
                    entity.MoveSrcSnap(snap, wPoint);
                    bb.Add(store.GetActiveDoc().UpdateEntity(dat(entity)));
                    store.GetActiveDoc().UpdateMask(entity);
                })
                ApiNewChange(bb.ToBuffer(), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                const bb = new BufferBuilder();
                entities.forEach(entity => {
                    entity.MoveSrcSnap(wPoint, snap);
                    bb.Add(store.GetActiveDoc().UpdateEntity(dat(entity)));
                    store.GetActiveDoc().UpdateMask(entity);
                })
                ApiNewChange(bb.ToBuffer(), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}