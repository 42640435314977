import { NormalizeRect } from "../math/rect";
import { IEntity } from "../doc/extended/entities/entity";
import { Layer } from "../doc/extended/layers/layer";
import { Space } from "../doc/extended/spaces/space";
import { Store } from "../store/store";

export class SpacialPartiionAccelerator {
    tableContainers: Map<Space, Map<Layer, PartitionTable>> = new Map<Space, Map<Layer, PartitionTable>>();

    Load(spaces: Space[], layers: Layer[], entities: IEntity[]) {
        spaces.forEach(space => {
            const spaceContainer = new Map<Layer, PartitionTable>();

            layers.forEach(layer => {
                const layerTable = new PartitionTable(space, layer);
                
                const entitySet = new Set<IEntity>();
                entities.forEach(entity => {
                    entitySet.add(entity);
                })
                layerTable.Rebuild(entitySet);

                spaceContainer.set(layer, layerTable);
            })

            this.tableContainers.set(space, spaceContainer);
        })
    }

    AddSpace(space: Space) {}

    RemoveSpace(space: Space) {}

    AddLayer(layer: Layer) {}

    RemoveLayer(layer: Layer) {}

    AddEntity(entity: IEntity) {
        this.tableContainers.get(entity.GetSpace())!.get(entity.GetLayer())!.AddEntity(entity);
    }

    RemoveEntity(entity: IEntity) {
        this.tableContainers.get(entity.GetSpace())!.get(entity.GetLayer())!.RemoveEntity(entity);
    }

    UpdateEntity(entity: IEntity) { 
        this.RemoveEntity(entity); this.AddEntity(entity); 
    }

    UpdateEntityLayer(prevEntityLayer: Layer, entity: IEntity) { 
        this.tableContainers.get(entity.GetSpace())!.get(prevEntityLayer)!.RemoveEntity(entity);
        this.AddEntity(entity); 
    }

    GetEntitiesForAreaApproximate(
        space: Space, 
        layerFilter: (layer: Layer)=>boolean,
        entityFilter: (entity: IEntity)=>boolean,
        areaRect: number[]): IEntity[] {
        const entities: IEntity[] = [];
        this.tableContainers.get(space)!.forEach((layerTable, layer) => {
            if (layerFilter(layer))
            {
                const layerEntities = this.GetLayerEntitiesForAreaApproximate(space, layer, areaRect);
                layerEntities.forEach(entity => {
                    if (entityFilter(entity))
                        entities.push(entity);
                })
            }
        })
        return entities;
    }

    GetEntitiesForAreaExact(
        space: Space, 
        layerFilter: (layer: Layer)=>boolean,
        entityFilter: (entity: IEntity)=>boolean,
        areaRect: number[]): IEntity[] {
        const entities: IEntity[] = [];
        this.tableContainers.get(space)!.forEach((layerTable, layer) => {
            if (layerFilter(layer))
            {
                const layerEntities = this.GetLayerEntitiesForAreaApproximate(space, layer, areaRect);
                layerEntities.forEach(entity => {
                    if (entity.IntersectsArea(areaRect) && entityFilter(entity))
                        entities.push(entity);
                })
            }
        })
        return entities;
    }

    GetLayerEntitiesForAreaApproximate(space: Space, layer: Layer, areaRect: number[]): Set<IEntity> {
        return this.tableContainers.get(space)!.get(layer)!.GetEntitiesForAreaApproximate(areaRect)
    }

    _draw(ctx: CanvasRenderingContext2D, store: Store) {
        this.tableContainers.forEach(layers => layers.forEach(tb => {
            const cellWidth = (tb.p1[0] - tb.p0[0]) / 100;
            const cellHeight = (tb.p1[1] - tb.p0[1]) / 100;
            ctx.strokeStyle = '#101010';
            let y = tb.p0[1];
            for (var r = 0; r < 100; r++)
            {
                let l0 = store.WorldToCanvas([tb.p0[0], y]);
                ctx.moveTo(l0[0], l0[1]);
                let l1 = store.WorldToCanvas([tb.p1[0], y]);
                ctx.lineTo(l1[0], l1[1]);
                y += cellHeight;
            }
            let x = tb.p0[0];
            for (var c = 0; c < 100; c++)
            {
                let l0 = store.WorldToCanvas([x, tb.p0[1]]);
                ctx.moveTo(l0[0], l0[1]);
                let l1 = store.WorldToCanvas([x, tb.p1[1]]);
                ctx.lineTo(l1[0], l1[1]);
                x += cellWidth;
            }
            ctx.stroke();
        }))
    }
}

class PartitionTable {
    table: Map<string, IEntity>[][] = [];
    p0: number[] = [0,0];
    p1: number[] = [0,0];
    space: Space;
    layer: Layer;

    constructor(space: Space, layer: Layer) {
        this.space = space;
        this.layer = layer;
    }

    GetEntitiesForAreaApproximate(areaRect: number[]): Set<IEntity> {
        const entities = new Set<IEntity>();
        this.AreaWalker(areaRect, cellEntities => {
            cellEntities.forEach(entity =>
                entities.add(entity));
        })
        return entities;
    }

    AddEntity(entity: IEntity) {
        const bounds = entity.GetInteractiveBoundingRect();
        if (this.p0[0] < bounds[0] && bounds[0] + bounds[2] < this.p1[0] &&
            this.p0[1] < bounds[1] && bounds[1] + bounds[3] < this.p1[1])
        {
            this.AreaWalker(bounds, (cellEntities, cellBounds) => {
                if (entity.IntersectsArea(cellBounds))
                    cellEntities.set(entity.GetId(), entity);
            })
        }
        else // Does not fit, rebuild
        {
            var entities = this.ExtractEntities();
            entities.add(entity);
            this.Rebuild(entities);
        }
    }

    RemoveEntity(entity: IEntity) {
        const bounds = entity.GetInteractiveBoundingRect();
        this.AreaWalker(bounds, (cellEntities, cellBounds) => {
            if (entity.IntersectsArea(cellBounds))
                cellEntities.delete(entity.GetId());
        })
    }

    AreaWalker(areaRect: number[], cb: (cellEntities: Map<string, IEntity>, cellBounds: number[])=>void) {
        areaRect = NormalizeRect(areaRect[0], areaRect[1], areaRect[2], areaRect[3]);
        const cellWidth = (this.p1[0] - this.p0[0]) / 100;
        const cellHeight = (this.p1[1] - this.p0[1]) / 100;
        const c0 = Math.max(0, Math.floor(100 * (areaRect[0] - this.p0[0]) / (this.p1[0] - this.p0[0])));
        const c1 = Math.min(99, Math.floor(100 * (areaRect[0] + areaRect[2] - this.p0[0]) / (this.p1[0] - this.p0[0])));
        const r0 = Math.max(0, Math.floor(100 * (areaRect[1] - this.p0[1]) / (this.p1[1] - this.p0[1])));
        const r1 = Math.min(99, Math.floor(100 * (areaRect[1] + areaRect[3] - this.p0[1]) / (this.p1[1] - this.p0[1])));
        for (var r = r0; r <= r1; r++)
        {
            for (var c = c0; c <= c1; c++)
            {
                cb(this.table[r][c], [this.p0[0] + c * cellWidth, this.p0[1] + r * cellHeight, cellWidth, cellHeight]);
            }
        }
    }

    Rebuild(entities: Set<IEntity>) {

        // Entire bounds for all entities
        const entireBoundsP0: number[] = [Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER];
        const entireBoundsP1: number[] = [Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
        entities.forEach(entity => {
            if (entity.GetSpace() === this.space && entity.GetLayer() === this.layer)
            {
                const bounds = entity.GetInteractiveBoundingRect();
                entireBoundsP0[0] = Math.min(entireBoundsP0[0], bounds[0]);
                entireBoundsP0[1] = Math.min(entireBoundsP0[1], bounds[1]);
                entireBoundsP1[0] = Math.max(entireBoundsP1[0], bounds[0] + bounds[2]);
                entireBoundsP1[1] = Math.max(entireBoundsP1[1], bounds[1] + bounds[3]);
            }
        })

        // Compute bounds
        if (entireBoundsP0[0] !== Number.MAX_SAFE_INTEGER)
        {
            // Inflate 10%
            const w = entireBoundsP1[0] - entireBoundsP0[0]; 
            entireBoundsP0[0] -= 0.0000001 + 0.05 * w;
            entireBoundsP1[0] += 0.0000001 + 0.05 * w;
            const h = entireBoundsP1[1] - entireBoundsP0[1];
            entireBoundsP0[1] -= 0.0000001 + 0.05 * h;
            entireBoundsP1[1] += 0.0000001 + 0.05 * h;

            // Set
            this.p0 = entireBoundsP0;
            this.p1 = entireBoundsP1;
        }
        else // Tiny table to ensure rebuilding
        {
            this.p0 = [0,0];
            this.p1 = [0.0000001,0.0000001];
        }
        
        // Build table
        this.table = [];
        for (var r = 0; r < 100; r++)
        {
            this.table.push([]);
            for (var c = 0; c < 100; c++)
            {
                this.table[r].push(new Map<string, IEntity>());
            }
        }

        // Add entities
        entities.forEach(entity => {
            if (entity.GetSpace() === this.space && entity.GetLayer() === this.layer)
                this.AddEntity(entity);
        })
    }

    ExtractEntities(): Set<IEntity> {
        let entitySet = new Set<IEntity>();  
        for (var r = 0; r < 100; r++)
        {
            for (var c = 0; c < 100; c++)
            {
                this.table[r][c].forEach(entity => {
                    entitySet.add(entity);
                })
            }
        }
        return entitySet;
    }

    
}