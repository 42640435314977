import { Store } from "../store/store";
import { Command } from "../commands/command";
import { State } from "./state";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import Menus from "../components/menus/menus";
import Dialogs from "../components/dialogs/dialogs";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AppBarClosed from "../components/frame/top/appBarClosed";

interface StateProps {
    state: ClosedState;
    store: Store;
}

const StateRender: React.FC<StateProps> = ({state, store}) => {
    const { user, isAuthenticated, isLoading } = useAuth0();    

    const block = (
    <>
        <Box>
            <Box sx={{ width: "100vw", height: "72px" }}>
                <AppBarClosed store={store} />
            </Box>
            <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: "100vw", height: "calc(100vh - 72px)", bgcolor:'black'}}>
                <Stack alignItems={'center'} justifyContent={'center'}>
                    <AutoAwesomeOutlinedIcon fontSize="large" sx={{color:'gray', mb:1}}/>
                    <Typography variant="h6" sx={{color:'gray'}}>No file opened</Typography>
                </Stack>
            </Stack>
        </Box>
        <Menus store={store}/>
        <Dialogs store={store}/>
    </>
    );

    useEffect(() => { (async () => {
        
    })()}, [user, isAuthenticated, isLoading])

    return block;
}

export class ClosedState extends State {
    constructor() {
        super([], []);
    }

    Activate(store: Store) {}

    Command(cmd: Command, store: Store) {
        super.Command(cmd, store);
        switch (cmd.name) {
        }        
    }

    Render(store: Store) {
        return <StateRender state={this} store={store}/>
    }
}