import { ArcPointFromAngle, PointInArc } from "./arc";
import { DistSqr } from "./point";
import { PointInRect } from "./rect";

export function PointLineNearestPoint(
    x0: number, y0: number, x1: number, y1: number,
    x: number, y: number
) {
    const dx = x1 - x0;
    const dy = y1 - y0;
    const a = -dy;
    const b = dx;
    const c = dy * x0 - dx * y0;
    const den = a * a + b * b;
    if (Math.abs(den) < 0.0000001) return undefined;
    return [
        (b * (b * x - a * y) - a * c) / den,
        (a * (-b * x + a * y) - b * c) / den,
    ];
}

export function PointSegmentNearestPoint(
    x0: number, y0: number, x1: number, y1: number,
    x: number, y: number
) {
    const nearestP = PointLineNearestPoint(x0, y0, x1, y1, x, y);
    if (nearestP !== undefined && PointInRect(x0, y0, x1 - x0, y1 - y0, nearestP[0], nearestP[1]))
        return nearestP;
    else if ((x - x0) * (x - x0) + (y - y0) * (y - y0) < (x - x1) * (x - x1) + (y - y1) * (y - y1))
        return [x0, y0];
    return [x1, y1];
}

// circle

// if point is in the center we return cx + r, cy
export function PointCircleNearestPoint(
    cx: number, cy: number, r: number,
    x: number, y: number
) {
    const px = x - cx;
    const py = y - cy;
    const den = px * px + py * py; if (Math.abs(den) < 0.0000001) return undefined;
    const part = r * Math.sqrt(1 / den);
    return [
        cx + px * part,
        cy + py * part,
    ];
}

// arc

export function PointArcNearestPoint(
    cx: number, cy: number, r: number, a0: number, a1: number,
    x: number, y: number
) {
    const p0 = ArcPointFromAngle(cx, cy, r, a0);
    const p1 = ArcPointFromAngle(cx, cy, r, a1);
    const pn = PointCircleNearestPoint(cx, cy, r, x, y) ?? p0;
    if (PointInArc(pn[0], pn[1], cx, cy, r, a0, a1)) return pn;
    if (DistSqr([x, y], p0) < DistSqr([x, y], p1)) return p0;
    return p1;
}
