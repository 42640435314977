import { Textual } from "../doc/extended/entities/textual";
import { Store } from "../store/store";
import { AcceleratorKeyCmd } from "../commands/acceleratorKey";
import { Command } from "../commands/command";
import { CreateArcCmd } from "../commands/createArc";
import { CreateCircleCmd } from "../commands/createCircle";
import { CreateLineCmd } from "../commands/createLine";
import { CreatePolyCmd } from "../commands/createPoly";
import { DeselectCmd } from "../commands/deselect";
import { PromptButtonCmd } from "../commands/promptButton";
import { PromptInputCmd } from "../commands/promptInput";
import { ClickSelectGesture } from "./gestures/clickSelect";
import { KeyboardGesture } from "./gestures/keyboard";
import { PanGesture } from "./gestures/pan";
import { RectSelectGesture } from "./gestures/rectSelect";
import { ZoomWheelGesture } from "./gestures/zoomWheel";
import { DrawRectSelect } from "../gizmos/drawRectSelect";
import { State } from "./state";
import { TextEditorState } from "./textEditor";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import AppBar from "../components/frame/top/appBar";
import ToolBar from "../components/frame/top/toolBar";
import { Canvas } from "../components/lib/canvas";
import PromptBar from "../components/frame/right/promptBar";
import BottomBar from "../components/frame/right/bottomBar";
import Menus from "../components/menus/menus";
import Dialogs from "../components/dialogs/dialogs";
import TopBar from "../components/frame/right/topBar";

interface StateProps {
    state: ViewerState;
    store: Store;
}

const StateRender: React.FC<StateProps> = ({state, store}) => {
    const { user, isAuthenticated, isLoading } = useAuth0();    

    const block = (
    <>
        <Box id="MainFrame" sx={{outline:'none'}}
            onKeyDown={e => store.GetActiveState().KeyDown(e, store)}
            onKeyUp={e => store.GetActiveState().KeyUp(e, store)}
            tabIndex={-1}>
            <Box sx={{ width: "100vw", height: "144px" }}>
                <AppBar store={store}/>
                <ToolBar store={store}/>
            </Box>
            <Stack>
                <Box sx={{ width: "100vw", height: "40px" }}>
                    <TopBar store={store}/>
                </Box>
                <Canvas draw={ctx => store.GetActiveState().Draw(ctx, store)} 
                        mouseDown={e => store.GetActiveState().MouseDown(e, store)}
                        mouseUp={e => store.GetActiveState().MouseUp(e, store)}
                        mouseMove={e => store.GetActiveState().MouseMove(e, store)}
                        mouseWheel={e => store.GetActiveState().MouseWheel(e, store)}
                        mouseEnter={e => store.GetActiveState().MouseEnter(e, store)}
                        mouseLeave={e => store.GetActiveState().MouseLeave(e, store)}
                        mouseDblClick={e => store.GetActiveState().MouseDblClick(e, store)}
                        touchesPan={e => store.GetActiveState().TouchesPan(e, store)}
                        touchesPinch={e => store.GetActiveState().TouchesPinch(e, store)}
                        resizeAndOrMove={(x: number, y: number, w: number, h: number) => store.ui.SetCanvasRect(x,y,w,h)}
                        width= "100vw" height= "calc(100vh - 144px - 40px - 112px)"/>
                <Box sx={{ width: "100vw", height: "112px" }}>
                    <PromptBar store={store}/>
                    <BottomBar store={store}/>
                </Box>
            </Stack>
            <canvas id="snapshot" width="200" height="150" style={{display:'none', position:'absolute',top:'0',left:'0',width:'200px',height:'150px'}}></canvas>
        </Box>
        <Menus store={store}/>
        <Dialogs store={store}/>
    </>);

    useEffect(() => { (async () => {
        
    })()}, [user, isAuthenticated, isLoading])

    return block;
}

export class ViewerState extends State {
    constructor() {
        super([
            new PanGesture(),
            new ZoomWheelGesture(),
            new RectSelectGesture(),
            new ClickSelectGesture(),
            new KeyboardGesture(),
        ],
        [
            "RectSelectCmd",
            "ClickSelectCmd",
            "SelectAllCmd",
            "DeselectCmd",
            "SnapMoveCmd",

            "CreateLineCmd",
            "CreateCircleCmd",
            "CreateArcCmd",
            "CreatePolyCmd",
            "CreateRectangleCmd",
            "CreateTextCmd",

            "ZoomExtentsCmd",
            "ZoomRectangleCmd",

            "MoveCmd",
            "EraseCmd"
        ]);
    }

    Activate(store: Store) {}

    Command(cmd: Command, store: Store) {
        super.Command(cmd, store);
        switch (cmd.name) {
            case "CancelCmd": {
                this.Command(new DeselectCmd(), store);
            } break;
            case "AcceleratorKeyCmd": {
                AcceleratorKeyCmd.UpdatePromptInput((cmd as AcceleratorKeyCmd).event, store);
            } break;
            case "PromptButtonCmd": {
                const tcmd = cmd as PromptButtonCmd;
                switch (tcmd.bName) {
                    case "Deselect": {
                        this.Command(new DeselectCmd(), store);
                    } break;
                }
            } break;
            case "PromptInputCmd": {
                const tcmd = cmd as PromptInputCmd;
                switch (tcmd.value.toUpperCase()) {
                    case "L": case "LINE": {
                        this.Command(new CreateLineCmd(), store);
                    } break;
                    case "C": case "CIRCLE": {
                        this.Command(new CreateCircleCmd(), store);
                    } break;
                    case "A": case "ARC": {
                        this.Command(new CreateArcCmd(), store);
                    } break;
                    case "P": case "PLINE": {
                        this.Command(new CreatePolyCmd(), store);
                    } break;
                }
            } break;
        }        
    }

    MouseDblClick(event: any, store: Store) { 
        super.MouseDblClick(event, store);

        if (event.button === 0)
        {
            // Snap mouse
            let wMouse = store.ClientToWorld(store.ui.GetMousePos());
            store.ui.Get().selection.entities.forEach(entity => {
                if (entity.IntersectsArea([wMouse[0], wMouse[1], 0, 0]))
                {
                    if (entity instanceof Textual)
                    {
                        store.ui.Set().promptBar.Set("TEXT", "Make changes to text or", ["Done", "Cancel"], "");
                        store.SetActiveState(new TextEditorState(entity as Textual, false));
                    }
                }
            })
        }
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        super.Draw(ctx, store);

        ctx.fillStyle = 'black'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = 'white'
        ctx.beginPath()
        ctx.arc(50, 100, 20*Math.sin(Math.random()*1000*0.05)**2, 0, 2*Math.PI)
        ctx.fill();

        store.GetActiveDoc().GetSpacialPartiionAccelerator()._draw(ctx, store);

        // Get visible entities for layer
        const boundsW = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth();
        const boundsH = Math.abs(store.CanvasToWorldDelta([0,store.ui.GetCanvasRect()[3]])[1]);
        const boundsX0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0] - 0.5 * boundsW;
        const boundsY0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1] - 0.5 * boundsH;
        const entities = store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaApproximate(
            store.GetActiveDoc().GetActiveSpace(),
            layer => layer.isVisible,
            () => true,
            [boundsX0, boundsY0, boundsW, boundsH]
        )

        // Draw entities
        entities.forEach(entity => {
            if (!entity.IsSelected())
                entity.Draw(ctx, store);
            else
                entity.DrawSelected(ctx, store);
        })

        // Draw src snaps
        entities.forEach(entity => {
            if (entity.IsSelected())
                entity.GetSrcSnaps().forEach(p => {
                    const canvasP = store.WorldToCanvas(p);
                    ctx.fillStyle = 'cyan';
                    ctx.fillRect(canvasP[0] - 5, canvasP[1] - 5, 10, 10);
                })
        })
          
        DrawRectSelect(ctx, store);

        // Snap mouse
        const worldMouse = store.ClientToWorld(store.ui.GetMousePos());
        let nearestP: number[] = [0,0], nearestDist = Number.MAX_SAFE_INTEGER;
        entities.forEach(entity => {
            if (entity.IsSelected())
                entity.GetSrcSnaps().forEach(p => {
                    const distSqr = (p[0] - worldMouse[0]) * (p[0] - worldMouse[0]) + (p[1] - worldMouse[1]) * (p[1] - worldMouse[1]);
                    if (nearestDist > distSqr)
                    {
                        nearestP = p;
                        nearestDist = distSqr;
                    }
                })
        })
        let canvasMouse = store.ClientToCanvas(store.ui.GetMousePos());
        const canvasNearestP = store.WorldToCanvas(nearestP);
        if (25 >= (canvasNearestP[0] - canvasMouse[0]) * (canvasNearestP[0] - canvasMouse[0]) + (canvasNearestP[1] - canvasMouse[1]) * (canvasNearestP[1] - canvasMouse[1]))
            canvasMouse = canvasNearestP;

        ctx.strokeStyle = 'white';
        ctx.beginPath();
        ctx.moveTo(canvasMouse[0] - 40, canvasMouse[1]);
        ctx.lineTo(canvasMouse[0] - 5, canvasMouse[1]);
        ctx.moveTo(canvasMouse[0] + 5, canvasMouse[1]);
        ctx.lineTo(canvasMouse[0] + 40, canvasMouse[1]);
        ctx.moveTo(canvasMouse[0], canvasMouse[1] - 40);
        ctx.lineTo(canvasMouse[0], canvasMouse[1] - 5);
        ctx.moveTo(canvasMouse[0], canvasMouse[1] + 5);
        ctx.lineTo(canvasMouse[0], canvasMouse[1] + 40);
        ctx.stroke();
        ctx.strokeRect(canvasMouse[0] - 5, canvasMouse[1] - 5, 10, 10)
    }

    Render(store: Store) {
        return <StateRender state={this} store={store}/>
    }
}