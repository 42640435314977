export function NormalizeRect(
    rx: number, ry: number, rw: number, rh: number
) {
    if (rw < 0) { rx += rw; rw = -rw; }
    if (rh < 0) { ry += rh; rh = -rh; }
    return [rx, ry, rw, rh];
}

export function PointInRect(
    rx: number, ry: number, rw: number, rh: number,
    x: number, y: number
) {
    if (rw < 0) { rx += rw; rw = -rw; }
    if (rh < 0) { ry += rh; rh = -rh; }
    return rx <= x && x <= rx + rw && ry <= y && y <= ry + rh;
}
