import { Line } from "../doc/extended/entities/line";
import { Poly } from "../doc/extended/entities/poly";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { Command } from "./command";

export class ZoomRectangleCmd extends Command{
    constructor() {
        super("ZoomRectangleCmd");
    }

    Process(store: Store) {
        store.ui.Set().promptBar.Set("ZOOM", "Select first corner or", ["Cancel"], "");
        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.ZoomRectangleWithPoint(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            }
        ));
    }

    ZoomRectangleWithPoint(wP0: number[], store: Store) {
        store.ui.Set().promptBar.Set("ZOOM", "Select other corner or", ["Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                const canvasRect = store.ui.GetCanvasRect();
                store.GetActiveDoc().GetActiveSpace().SetWorldProjectionWidth(Math.max(Math.abs(wP0[0] - wP[0]), Math.abs(wP0[1] - wP[1]) * canvasRect[2] / canvasRect[3]));
                store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter([0.5 * (wP0[0] + wP[0]), 0.5 * (wP0[1] + wP[1])]);

                store.SetActiveState(new EditorState());
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
                const canvasPointFrom = store.WorldToCanvas(wP0);
                const canvasPointTo = store.WorldToCanvas(wP);
                ctx.strokeStyle = "white";
                ctx.strokeRect(canvasPointFrom[0], canvasPointFrom[1], canvasPointTo[0] - canvasPointFrom[0], canvasPointTo[1] - canvasPointFrom[1]);
            }
        ));
    }
}