import Box from '@mui/material/Box';

import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Store } from '../../../store/store';
import SnapButton from './snapButton';
import GridButton from './gridButton';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export interface TopBarProps {
    store: Store;
}

export default function TopBar(props: TopBarProps) {
    const layerClick = (event: any) => props.store.ui.Set().layerSelectMenu.anchorEl = event.currentTarget;

    return (
        <Stack direction={"row"} sx={{ bgcolor: 'black', p: 1 }}>
            <Stack direction={"row"} sx={{ borderBottom:'1px solid gray', bgcolor: 'black', width: 5}} alignItems={'center'} justifyContent={'space-between'} onClick={layerClick}>
            </Stack>
            {props.store.docs.map(doc => {
                if (doc === props.store.GetActiveDoc()) return (
                    <Stack key={doc.GetFileName()} direction={"row"} sx={{ borderRadius:'6px 6px 0px 0px', borderTop:'1px solid gray', borderLeft:'1px solid gray', borderRight:'1px solid gray', bgcolor: 'black', cursor: 'pointer'}} alignItems={'center'} justifyContent={'space-between'} onClick={layerClick}>
                        <Stack direction={"row"} alignItems={'center'}>
                            <Box sx={{width:10, height:10, bgcolor:'lightgray', borderRadius:'2px', ml:1.5}}></Box>
                            <Typography sx={{ color: 'white', ml:1, pr:0.5 }}>{doc.GetFileName()}</Typography>
                        </Stack>
                        <CloseOutlinedIcon fontSize="small" sx={{ color: 'lightgray', pr:1 }}/>
                    </Stack>)
                else return (
                    <Stack key={doc.GetFileName()} direction={"row"} sx={{ borderBottom:'1px solid gray', bgcolor: 'black', cursor: 'pointer'}} alignItems={'center'} justifyContent={'space-between'} onClick={layerClick}>
                        <Stack direction={"row"} alignItems={'center'}>
                            <Box sx={{width:10, height:10, bgcolor:'gray', borderRadius:'2px', ml:1.5}}></Box>
                            <Typography sx={{ color: 'lightgray', ml:1, pr:0.5 }}>{props.store.GetActiveDoc().GetFileName()}</Typography>
                        </Stack>
                        <CloseOutlinedIcon fontSize="small" sx={{ color: 'gray', pr:1 }}/>
                    </Stack>)
            })}
            <Stack direction={"row"} sx={{ borderBottom:'1px solid gray', bgcolor: 'black', width: 5}} alignItems={'center'} justifyContent={'space-between'} onClick={layerClick}>
            </Stack>
        </Stack>
    )
}