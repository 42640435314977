import { ArcBoundingRect, ArcMidPoint, ArcPointFromAngle, PointsToArc } from "../../../math/arc";
import { ArcAreaIntersects } from "../../../math/intersects";
import { PointArcNearestPoint } from "../../../math/nearest";
import { PointInRect } from "../../../math/rect";
import { CenterPointSnap } from "../../../snaps/centerPoint";
import { EndPointSnap } from "../../../snaps/endPoint";
import { MidPointSnap } from "../../../snaps/midPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { LayerData } from "../layers/layerData";
import { SpaceData } from "../spaces/spaceData";
import { EntityData } from "./entityData";

export class PolyData extends EntityData {
    es: EntityData[];

    constructor(id: string, es: EntityData[], space: SpaceData, layer: LayerData) {
        super(id, space, layer);
        this.es = es; 
    }
}