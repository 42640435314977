import { Store } from "../store/store";
import { ApiPostJsonToJson } from "../tsutils/apiFetch";
import { IsProduction } from "../tsutils/production";

export const API_HOST = IsProduction() ? "https://cadxi.com/api" : "http://localhost:80/api";

export async function ApiHello(
    store: Store
) {
    const data = await ApiPostJsonToJson(API_HOST, 'sec/hello', {}, store.ui.account.accessToken);
    if (data === undefined) return undefined;
    return data;
}