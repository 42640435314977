import Box from '@mui/material/Box';

import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Store } from '../../../store/store';
import SnapButton from './snapButton';
import GridButton from './gridButton';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export interface LayerButtonProps {
    store: Store;
}

export default function LayerButton(props: LayerButtonProps) {
    const layerClick = (event: any) => props.store.ui.Set().layerSelectMenu.anchorEl = event.currentTarget;

    return (
        <Stack direction={"row"} sx={{ bgcolor: 'black', border: '1px solid gray', borderRadius:'4px', position: 'absolute', top: 152, left: 'calc(100vw - 164px)', width: 150, height: 34, cursor: 'pointer'}} alignItems={'center'} justifyContent={'space-between'} onClick={layerClick}>
            <Stack direction={"row"} alignItems={'center'}>
                <Box sx={{width:14, height:14, bgcolor:props.store.GetActiveDoc().GetActiveLayer().color, ml:1}}></Box>
                <Typography sx={{ color: 'white', ml:1, pr:1 }}>{props.store.GetActiveDoc().GetActiveLayer().name}</Typography>
            </Stack>
            <KeyboardArrowDownIcon sx={{ color: 'white', pr:1 }}/>
        </Stack>
    )
}