import { ArcPointFromAngle, PointInArc } from "./arc";
import { Dist } from "./point";
import { PointInRect } from "./rect";

// rect

export function RectRectIntersects(
    x0: number, y0: number, w0: number, h0: number,
    x1: number, y1: number, w1: number, h1: number): boolean 
{
    return PointInRect(x1, y1, w1, h1, x0, y0) || 
        PointInRect(x1, y1, w1, h1, x0 + w0, y0) || 
        PointInRect(x1, y1, w1, h1, x0, y0 + h0) || 
        PointInRect(x1, y1, w1, h1, x0 + w0, y0 + h0) || 
        PointInRect(x0, y0, w0, h0, x1, y1) || 
        PointInRect(x0, y0, w0, h0, x1 + w1, y1) || 
        PointInRect(x0, y0, w0, h0, x1, y1 + h1) || 
        PointInRect(x0, y0, w0, h0, x1 + w1, y1 + h1);
}

// line

export function LineHorzLineIntersect(
    x0: number, y0: number, x1: number, y1: number,
    y: number
) {
    const deltaY = y1 - y0; if (Math.abs(deltaY) < 0.0000001) return undefined;
    const deltaX = x1 - x0;
    return [ (y - y0) * deltaX / deltaY + x0, y ];
}

export function LineVertLineIntersect(
    x0: number, y0: number, x1: number, y1: number,
    x: number
) {
    const deltaX = x1 - x0; if (Math.abs(deltaX) < 0.0000001) return undefined;
    const deltaY = y1 - y0;
    return [ x, (x - x0) * deltaY / deltaX + y0 ];
}

export function LineLineIntersect(
    ax0: number, ay0: number, ax1: number, ay1: number,
    bx0: number, by0: number, bx1: number, by1: number,
) {
    const aDeltaX = ax1 - ax0; const aDeltaY = ay1 - ay0;
    const bDeltaX = bx1 - bx0; const bDeltaY = by1 - by0;
    if (Math.abs(aDeltaX) > 0.0000001 && Math.abs(bDeltaX) > 0.0000001)
    {
        const am = aDeltaY / aDeltaX; const bm = bDeltaY / bDeltaX;
        const den = am - bm;
        if (Math.abs(den) < 0.0000001) return undefined;
        const num = by0 - bm * bx0 - ay0 + am * ax0;
        const x = num / den;
        const y = am * (x - ax0) + ay0;
        return [ x, y ];
    }
    if (Math.abs(aDeltaY) > 0.0000001 && Math.abs(bDeltaY) > 0.0000001)
    {
        const am = aDeltaX / aDeltaY; const bm = bDeltaX / bDeltaY;
        const den = am - bm;
        if (Math.abs(den) < 0.0000001) return undefined;
        const num = bx0 - bm * by0 - ax0 + am * ay0;
        const y = num / den;
        const x = am * (y - ay0) + ax0;
        return [ x, y ];
    }
    return undefined;
}

export function SegmentHorzSegmentIntersect(
    x0: number, y0: number, x1: number, y1: number,
    sx: number, sy: number, sw: number
) {
    const p = LineHorzLineIntersect(x0, y0, x1, y1, sy); if (p === undefined) return undefined;
    if (sw < 0) { sx += sw; sw = -sw; }
    if (sx <= p[0] && p[0] <= sx + sw && PointInRect(x0, y0, x1 - x0, y1 - y0, p[0], p[1]))
        return p;
    return undefined;    
}

export function SegmentVertSegmentIntersect(
    x0: number, y0: number, x1: number, y1: number,
    sx: number, sy: number, sh: number
) {
    const p = LineVertLineIntersect(x0, y0, x1, y1, sx); if (p === undefined) return undefined;
    if (sh < 0) { sy += sh; sh = -sh; }
    if (sy <= p[1] && p[1] <= sy + sh && PointInRect(x0, y0, x1 - x0, y1 - y0, p[0], p[1]))
        return p;
    return undefined;
}

export function SegmentAreaIntersects(
    x0: number, y0: number, x1: number, y1: number,
    ax: number, ay: number, aw: number, ah: number
) {
    return PointInRect(ax, ay, aw, ah, x0, y0) 
        || PointInRect(ax, ay, aw, ah, x1, y1)
        || SegmentHorzSegmentIntersect(x0, y0, x1, y1, ax, ay, aw) !== undefined
        || SegmentHorzSegmentIntersect(x0, y0, x1, y1, ax, ay + ah, aw) !== undefined
        || SegmentVertSegmentIntersect(x0, y0, x1, y1, ax, ay, ah) !== undefined
        || SegmentVertSegmentIntersect(x0, y0, x1, y1, ax + aw, ay, ah) !== undefined;
}

// circle

export function CircleHorzLineIntersect(
    cx: number, cy: number, r: number,
    y: number
) {
    const root = r * r - (y - cy) * (y - cy); 
    if (root === 0) return [[ cx, y ]];
    if (root < 0) return [];
    const sqrt = Math.sqrt(root);
    return [[ cx + sqrt, y ], [ cx - sqrt, y ]];
}

export function CircleVertLineIntersect(
    cx: number, cy: number, r: number,
    x: number
) {
    const root = r * r - (x - cx) * (x - cx); 
    if (root === 0) return [[ x, cy ]];
    if (root < 0) return [];
    const sqrt = Math.sqrt(root);
    return [[ x, cy + sqrt ], [ x, cy - sqrt ]];
}

export function CircleHorzSegmentIntersect(
    cx: number, cy: number, r: number,
    sx: number, sy: number, sw: number
) {
    const plist = CircleHorzLineIntersect(cx, cy, r, sy);
    if (sw < 0) { sx += sw; sw = -sw; }
    let points: number[][] = [];
    for (var i = 0; i < plist.length; i++)
        if (sx <= plist[i][0] && plist[i][0] <= sx + sw)
            points.push(plist[i])
    return points;    
}

export function CircleVertSegmentIntersect(
    cx: number, cy: number, r: number,
    sx: number, sy: number, sh: number
) {
    const plist = CircleVertLineIntersect(cx, cy, r, sx);
    if (sh < 0) { sy += sh; sh = -sh; }
    let points: number[][] = [];
    for (var i = 0; i < plist.length; i++)
        if (sy <= plist[i][1] && plist[i][1] <= sy + sh)
            points.push(plist[i]);
    return points;    
}

export function CircleAreaIntersects(
    cx: number, cy: number, r: number,
    ax: number, ay: number, aw: number, ah: number
): boolean {
    return (PointInRect(ax, ay, aw, ah, cx - r, cy))
        || CircleHorzSegmentIntersect(cx, cy, r, ax, ay, aw).length > 0
        || CircleHorzSegmentIntersect(cx, cy, r, ax, ay + ah, aw).length > 0
        || CircleVertSegmentIntersect(cx, cy, r, ax, ay, ah).length > 0
        || CircleVertSegmentIntersect(cx, cy, r, ax + aw, ay, ah).length > 0;
}

// arc

export function ArcHorzSegmentIntersect(
    cx: number, cy: number, r: number, a0: number, a1: number,
    sx: number, sy: number, sw: number
) {
    const plist = CircleHorzSegmentIntersect(cx, cy, r, sx, sy, sw);
    let points: number[][] = [];
    for (var i = 0; i < plist.length; i++)
        if (PointInArc(plist[i][0], plist[i][1], cx, cy, r, a0, a1))
            points.push(plist[i])
    return points;    
}

export function ArcVertSegmentIntersect(
    cx: number, cy: number, r: number, a0: number, a1: number,
    sx: number, sy: number, sh: number
) {
    const plist = CircleVertSegmentIntersect(cx, cy, r, sx, sy, sh);
    let points: number[][] = [];
    for (var i = 0; i < plist.length; i++)
        if (PointInArc(plist[i][0], plist[i][1], cx, cy, r, a0, a1))
            points.push(plist[i]);
    return points;    
}

export function ArcAreaIntersects(
    cx: number, cy: number, r: number, a0: number, a1: number,
    ax: number, ay: number, aw: number, ah: number
): boolean {
    const p0 = ArcPointFromAngle(cx, cy, r, a0);
    return PointInRect(ax, ay, aw, ah, p0[0], p0[1])
        || ArcHorzSegmentIntersect(cx, cy, r, a0, a1, ax, ay, aw).length > 0
        || ArcHorzSegmentIntersect(cx, cy, r, a0, a1, ax, ay + ah, aw).length > 0
        || ArcVertSegmentIntersect(cx, cy, r, a0, a1, ax, ay, ah).length > 0
        || ArcVertSegmentIntersect(cx, cy, r, a0, a1, ax + aw, ay, ah).length > 0;
}