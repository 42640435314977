import { ApiNewChange } from "../api/doc";
import { Textual } from "../doc/extended/entities/textual";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { Command } from "./command";

export class UpsertTextCmd extends Command{
    textual: Textual; 

    constructor(textual: Textual) {
        super("UpsertTextCmd"); this.textual = textual;
    }

    Process(store: Store) {
        this.DoUpsertTextAction(this.textual, store);
    }

    DoUpsertTextAction(textual: Textual, store: Store) {
        const isNew = store.GetActiveDoc().HasEntity(textual);
        store.Do(new UndoableAction( 
            function () { // do

                if (isNew)
                {
                    store.GetActiveDoc().AddMask(textual);
                    ApiNewChange(store.GetActiveDoc().AddEntity(textual), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);
                }
                else
                {
                    store.GetActiveDoc().UpdateMask(textual);
                    ApiNewChange(store.GetActiveDoc().UpdateEntity(textual), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);
                }

            },
            function () { // undo

                if (isNew)
                {
                    store.GetActiveDoc().RemoveMask(textual);
                    ApiNewChange(store.GetActiveDoc().RemoveEntity(textual), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);
                }
                else
                {
                    store.GetActiveDoc().UpdateMask(textual);
                    ApiNewChange(store.GetActiveDoc().UpdateEntity(textual), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);
                }

            }
        ))
    }
}