import { Store } from "../../store/store";
import { ClickSelectCmd } from "../../commands/clickSelect";
import { Gesture } from "./gesture";

export class ClickSelectGesture extends Gesture {

    MouseDown(event: any, store: Store) {
        if (event.button === 0) // left button
            store.ui.SetMouseClickSelectPos(store.ui.GetMousePos());
    }

    MouseMove(event: any, store: Store) {
        const clientFromP = store.ui.GetMouseClickSelectPos();
        if (clientFromP.length > 0)
        {
            const clientToP = store.ui.GetMousePos();

            if (25 < (clientFromP[0] - clientToP[0]) * (clientFromP[0] - clientToP[0]) + (clientFromP[1] - clientToP[1]) * (clientFromP[1] - clientToP[1]))
                store.ui.SetMouseClickSelectPos([]); // Cancel click gesture
        }
    }

    MouseUp(event: any, store: Store) {
        const clientFromP = store.ui.GetMouseClickSelectPos();
        if (event.button === 0 && clientFromP.length > 0) // left button, nearby
        {
            const clientP = store.ui.GetMousePos();

            const wP = store.ClientToWorld(clientP);

            //console.log('wP', wP)
    
            store.ui.SetMouseClickSelectPos([]);
    
            store.GetActiveState().Command(new ClickSelectCmd(wP, event.shiftKey), store);
        }
    }

    MouseEnter(event: any, store: Store) {
        store.ui.SetMouseClickSelectPos([]);
    }

    MouseLeave(event: any, store: Store) {
        store.ui.SetMouseClickSelectPos([]);
    }
}