import { GenId } from "../../../tsutils/id";
import { ArcBoundingRect, ArcMidPoint, ArcPointFromAngle, PointsToArc } from "../../../math/arc";
import { ArcAreaIntersects } from "../../../math/intersects";
import { PointArcNearestPoint } from "../../../math/nearest";
import { PointEquals } from "../../../math/point";
import { PointInRect } from "../../../math/rect";
import { CenterPointSnap } from "../../../snaps/centerPoint";
import { EndPointSnap } from "../../../snaps/endPoint";
import { MidPointSnap } from "../../../snaps/midPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { ArcData } from "../../data/entities/arcData";
import { LayerData } from "../../data/layers/layerData";
import { SpaceData } from "../../data/spaces/spaceData";
import { Layer } from "../layers/layer";
import { Space } from "../spaces/space";
import { IEntity } from "./entity";

export class Arc extends ArcData implements IEntity {

    constructor(id: string, c: number[], r: number, a: number[], space: Space, layer: Layer) {
        super(id, c, r, a, space, layer);
        this.extra = { isSelected: false }
    }

    GetId(): string { return this.id; }

    GetLayer(): Layer { return this.layer as Layer; }

    GetSpace(): Space { return this.space as Space; }

    IsSelected(): boolean { return this.extra.isSelected; }

    SetIsSelected(value: boolean) { this.extra.isSelected = value; }

    Clone(newId: string) { return new Arc(newId, [...this.c], this.r, [...this.a], this.GetSpace(), this.GetLayer()) }

    GetVisibleBoundingRect(): number[] {
        return ArcBoundingRect(this.c[0], this.c[1], this.r, this.a[0], this.a[1]);
    }

    GetInteractiveBoundingRect() { 
        const bounds = this.GetVisibleBoundingRect();
        let minX = bounds[0], maxX = bounds[0] + bounds[2], minY = bounds[1], maxY = bounds[1] + bounds[3];
        minX = Math.min(minX, this.c[0]); minY = Math.min(minY, this.c[1]); maxX = Math.max(maxX, this.c[0]); maxY = Math.max(maxY, this.c[1]); 
        return [minX, minY, maxX - minX, maxY - minY];
    }
    
    IntersectsArea(areaRect: number[]): boolean {
        return PointInRect(areaRect[0], areaRect[1], areaRect[2], areaRect[3], this.c[0], this.c[1])
            || ArcAreaIntersects(this.c[0], this.c[1], this.r, this.a[0], this.a[1], areaRect[0], areaRect[1], areaRect[2], areaRect[3]);
    }

    GetSrcSnaps(): number[][] { 
        const p0 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[0]);
        const p1 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[1]);
        return [ p0, p1, this.c, ArcMidPoint(this.c[0], this.c[1], this.r, this.a[0], this.a[1]) ]; 
    }

    GetDestSnaps(wPointer: number[]): Snap[] {
        const p0 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[0]);
        const p1 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[1]);
        const snaps = [ 
            new EndPointSnap(p0), 
            new EndPointSnap(p1),
            new CenterPointSnap(this.c),
            new MidPointSnap(ArcMidPoint(this.c[0], this.c[1], this.r, this.a[0], this.a[1])),
        ]; 
        const nearestP = PointArcNearestPoint(this.c[0], this.c[1], this.r, this.a[0], this.a[1], wPointer[0], wPointer[1]);
        if (!PointEquals(nearestP, p0) && !PointEquals(nearestP, p1))
            snaps.push(new NearestPointSnap(nearestP));
        return snaps;
    }

    MoveBy(delta: number[]) {
        this.c[0] += delta[0]; this.c[1] += delta[1];
    }

    MoveSrcSnap(snapP: number[], newP: number[]) {
        const srcSnaps = this.GetSrcSnaps();
        if (PointEquals(snapP, srcSnaps[0]))
        {
            this.Set3Points(newP, 
                ArcMidPoint(this.c[0], this.c[1], this.r, this.a[0], this.a[1]), 
                ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[1]));
            return [];
        }
        else if (PointEquals(snapP, srcSnaps[1]))
        {
            this.Set3Points(ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[0]), 
                ArcMidPoint(this.c[0], this.c[1], this.r, this.a[0], this.a[1]), 
                newP);
            return [];
        }
        else if (PointEquals(snapP, srcSnaps[3]))
        {
            this.Set3Points(ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[0]), 
                newP, 
                ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[1]))
            return [];
        }
        else if (PointEquals(snapP, srcSnaps[2])) {
            const oldP0 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[0]);
            const oldP1 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[1]);
            this.c = [...newP];
            const newP0 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[0]);
            const newP1 = ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[1]);
            return [[oldP0, newP0], [oldP1, newP1]]; // inform of endpoint changes
        }
        return [];
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        ctx.strokeStyle = this.layer.color;
        ctx.beginPath();
        const sC = store.WorldToCanvas(this.c);
        const sR = Math.abs(store.WorldToCanvasDelta([this.r, 0])[0]);
        ctx.arc(sC[0], sC[1], sR, -this.a[0], -this.a[1], true);
        ctx.stroke();

        // ctx.strokeStyle = this.layer.color;
        // ctx.beginPath();
        // const rect = this.GetBoundingRect();
        // const p0 = store.WorldToCanvas([rect[0], rect[1]]);
        // const p1 = store.WorldToCanvas([rect[0] + rect[2], rect[1] + rect[3]]);
        // ctx.strokeRect(p0[0], p0[1], p1[0]-p0[0], p1[1]-p0[1]);
    }

    DrawSelected(ctx: CanvasRenderingContext2D, store: Store) {
        ctx.setLineDash([5, 3]);   
        ctx.strokeStyle = this.layer.color;
        ctx.beginPath();
        const sC = store.WorldToCanvas(this.c);
        const sR = Math.abs(store.WorldToCanvasDelta([this.r, 0])[0]);
        ctx.arc(sC[0], sC[1], sR, -this.a[0], -this.a[1], true);
        ctx.stroke();
        ctx.setLineDash([]);
    }

    // Special

    Set3Points(p0: number[], p1: number[], p2: number[]) {
        const arc = PointsToArc(p0[0], p0[1], p1[0], p1[1], p2[0], p2[1]);
        if (arc !== undefined)
        {
            this.c = arc[0];
            this.r = arc[1][0];
            this.a = arc[2];
        }
    }

    GetPoint0() { return ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[0]); }
    GetPoint1() { return ArcMidPoint(this.c[0], this.c[1], this.r, this.a[0], this.a[1]); }
    GetPoint2() { return ArcPointFromAngle(this.c[0], this.c[1], this.r, this.a[1]); }
}