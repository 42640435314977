import { GenId } from "../../../tsutils/id";
import { ArcBoundingRect, ArcMidPoint, ArcPointFromAngle, PointsToArc } from "../../../math/arc";
import { ArcAreaIntersects } from "../../../math/intersects";
import { PointArcNearestPoint } from "../../../math/nearest";
import { PointInRect } from "../../../math/rect";
import { CenterPointSnap } from "../../../snaps/centerPoint";
import { EndPointSnap } from "../../../snaps/endPoint";
import { MidPointSnap } from "../../../snaps/midPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { EntityData } from "../../data/entities/entityData";
import { PolyData } from "../../data/entities/polyData";
import { Layer } from "../layers/layer";
import { Space } from "../spaces/space";
import { IEntity, dat, ext } from "./entity";

export class Poly extends PolyData implements IEntity {
    
    constructor(id: string, es: IEntity[], space: Space, layer: Layer) {
        super(id, es.map(entity => dat(entity.Clone(""))), space, layer);
        this.extra = { isSelected: false }
    }

    GetId(): string { return this.id; }

    GetLayer(): Layer { return this.layer as Layer; }

    GetSpace(): Space { return this.space as Space; }

    IsSelected(): boolean { return this.extra.isSelected; }

    SetIsSelected(value: boolean) { this.extra.isSelected = value; }

    Clone(newId: string) { return new Poly(newId, this.es.map(entity => ext(entity)), this.GetSpace(), this.GetLayer()) }

    GetVisibleBoundingRect(): number[] {
        let minX = Number.MAX_SAFE_INTEGER, maxX = Number.MIN_SAFE_INTEGER, minY = Number.MAX_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER;
        this.es.forEach(entity => {
            const bounds = ext(entity).GetVisibleBoundingRect();
            minX = Math.min(minX, bounds[0]); minY = Math.min(minY, bounds[1]); maxX = Math.max(maxX, bounds[0] + bounds[2]); maxY = Math.max(maxY, bounds[1] + bounds[3]); 
        })
        return [minX, minY, maxX - minX, maxY - minY];
    }

    GetInteractiveBoundingRect(): number[] {
        let minX = Number.MAX_SAFE_INTEGER, maxX = Number.MIN_SAFE_INTEGER, minY = Number.MAX_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER;
        this.es.forEach(entity => {
            const bounds = ext(entity).GetInteractiveBoundingRect();
            minX = Math.min(minX, bounds[0]); minY = Math.min(minY, bounds[1]); maxX = Math.max(maxX, bounds[0] + bounds[2]); maxY = Math.max(maxY, bounds[1] + bounds[3]); 
        })
        return [minX, minY, maxX - minX, maxY - minY];
    }
    
    IntersectsArea(areaRect: number[]): boolean {
        for (var i = 0; i < this.es.length; i++)
            if (ext(this.es[i]).IntersectsArea(areaRect))
                return true;
        return false;
    }

    GetSrcSnaps(): number[][] { 
        const snaps: number[][] = []; 
        this.es.forEach(entity => {
            snaps.push(...ext(entity).GetSrcSnaps());
        })
        return snaps;
    }

    GetDestSnaps(wPointer: number[]): Snap[] {
        const snaps: Snap[] = []; 
        this.es.forEach(entity => {
            snaps.push(...ext(entity).GetDestSnaps(wPointer));
        })
        return snaps;
    }

    MoveBy(delta: number[]) {
        this.es.forEach(entity => {
            ext(entity).MoveBy(delta);
        })
    }

    MoveSrcSnap(snapP: number[], newP: number[]) {
        const endPointsChanged: number[][][] = [];
        this.es.forEach(entity => {
            ext(entity).MoveSrcSnap(snapP, newP).forEach(endPointChangedPair =>
                endPointsChanged.push(endPointChangedPair));
        })
        endPointsChanged.forEach(endPointChangedPair => {
            this.es.forEach(entity => {
                ext(entity).MoveSrcSnap(endPointChangedPair[0], endPointChangedPair[1]);
            })
        })
        return [];
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        this.es.forEach(entity => {
            ext(entity).Draw(ctx, store);
        })

        // ctx.strokeStyle = this.layer.color;
        // ctx.beginPath();
        // const rect = this.GetVisibleBoundingRect();
        // const p0 = store.WorldToCanvas([rect[0], rect[1]]);
        // const p1 = store.WorldToCanvas([rect[0] + rect[2], rect[1] + rect[3]]);
        // ctx.strokeRect(p0[0], p0[1], p1[0]-p0[0], p1[1]-p0[1]);
    }

    DrawSelected(ctx: CanvasRenderingContext2D, store: Store) {
        this.es.forEach(entity => {
            ext(entity).DrawSelected(ctx, store);
        })
    }
}