import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Divider, Stack, Typography, popoverClasses } from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import LoginIcon from '@mui/icons-material/Login';
import { useAuth0 } from "@auth0/auth0-react";

export interface ProfileLoginMenuProps {
    store: Store;
}

export default function ProfileLoginMenu(props: ProfileLoginMenuProps) {
    const { loginWithRedirect } = useAuth0();

    const handleClose = () => {
        props.store.ui.Set().profileLogintMenu.anchorEl = null;
    };
    return (
        <Menu anchorEl={props.store.ui.Get().profileLogintMenu.anchorEl} open={Boolean(props.store.ui.Get().profileLogintMenu.anchorEl)} sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} onClose={handleClose}>
            <MenuItem onClick={() => loginWithRedirect()}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <LoginIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Login</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
        </Menu>
    );
}