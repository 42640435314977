
export async function ApiPostJsonToJson(
    host: string,
    path: string,
    body: any,
    accessToken: string | undefined = undefined
) {
    const headers = {
        "Content-Type": "application/json"
    };
    const bearer = {
        //credentials: 'include',
        "Authorization": `Bearer ${accessToken}`
    };
    try {
        const response = await fetch(`${host}/${path}`, { 
            method: 'POST', 
            body: JSON.stringify(body), 
            headers: accessToken === undefined ? headers : {...headers, ...bearer}} );
        if (!response.ok) return undefined;
        return response.json();
    } catch (error) {
        console.log('Fetch error: ', error);
    }
    return undefined;
}

export async function ApiPostJsonToBin(
    host: string,
    path: string,
    body: any,
    accessToken: string | undefined = undefined
) {
    const headers = {
        "Content-Type": "application/json"
    };
    const bearer = {
        //credentials: 'include',
        "Authorization": `Bearer ${accessToken}`
    };
    try {
        const response = await fetch(`${host}/${path}`, { 
            method: 'POST', 
            body: JSON.stringify(body), 
            headers: accessToken === undefined ? headers : {...headers, ...bearer}} );
        if (!response.ok) return undefined;
        return response.arrayBuffer();
    } catch (error) {
        console.log('Fetch error: ', error);
    }
    return undefined;
}

export async function ApiPostBinToJson(
    host: string,
    path: string,
    body: Uint8Array,
    accessToken: string | undefined = undefined
) {
    const headers = {
        "Content-Type": "application/octet-stream"
    };
    const bearer = {
        //credentials: 'include',
        "Authorization": `Bearer ${accessToken}`
    };
    try {
        const response = await fetch(`${host}/${path}`, { 
            method: 'POST', 
            body, 
            headers: accessToken === undefined ? headers : {...headers, ...bearer}} );
        if (!response.ok) return undefined;
        return response.json();
    } catch (error) {
        console.log('Fetch error: ', error);
    }
    return undefined;
}
