
export class UndoableAction {
    redo: ()=> void;
    undo: ()=> void;

    constructor(
        redo: ()=> void,
        undo: ()=> void) {
            this.redo = redo;
            this.undo = undo;
        }

    Undo() {
        this.undo();
    }

    Redo() {
        this.redo();
    }
}

export class UndoableHistory {
    actions: UndoableAction[] = [];
    aIndex: number = 0;

    HasUndo(): boolean {
        return this.aIndex - 1 >= 0;
    }

    Undo() {
        if (this.HasUndo())
        {
            this.aIndex--;
            this.actions[this.aIndex].Undo();
        }
    }

    HasRedo(): boolean {
        return this.aIndex < this.actions.length;
    }

    Redo() {
        if (this.HasRedo())
        {
            this.aIndex++;
            this.actions[this.aIndex - 1].Redo();
        }
    }

    Do(undoableAction: UndoableAction) {
        this.actions = this.actions.slice(0, this.aIndex);
        this.actions.push(undoableAction);
        this.aIndex++;
        this.actions[this.aIndex - 1].Redo();
    }
}