import { RectRectIntersects, SegmentAreaIntersects } from "../../../math/intersects";
import { PointLineNearestPoint, PointSegmentNearestPoint } from "../../../math/nearest";
import { PointEquals } from "../../../math/point";
import { WorldToCanvas } from "../../../math/projection";
import { NormalizeRect } from "../../../math/rect";
import { EndPointSnap } from "../../../snaps/endPoint";
import { MidPointSnap } from "../../../snaps/midPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { LayerData } from "../layers/layerData";
import { SpaceData } from "../spaces/spaceData";
import { EntityData } from "./entityData";

export const TEXTUAL_CHAR_SEPARATION = 0.1;
export const TEXTUAL_LINE_SEPARATION = 0.3;
export const TEXTUAL_PADDING_LEFT = 0.3;
export const TEXTUAL_PADDING_RIGHT = 0.3;
export const TEXTUAL_PADDING_TOP = 0.3;
export const TEXTUAL_PADDING_BOTTOM = 0.3;

export class TextualData extends EntityData {
    area: number[];
    text: string;
    h: number;
    f: string;

    constructor(id: string, area: number[], text: string, h: number, f: string, space: SpaceData, layer: LayerData) {
        super(id, space, layer);
        this.area = NormalizeRect(area[0], area[1], area[2], area[3]); this.text = text; this.h = h; this.f = f;
    }
}