import { Dist } from "../math/point";
import { Arc } from "../doc/extended/entities/arc";
import { Circle } from "../doc/extended/entities/circle";
import { Line } from "../doc/extended/entities/line";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { Command } from "./command";
import { GenId } from "../tsutils/id";
import { ApiNewChange } from "../api/doc";

export class CreateArcCmd extends Command{
    constructor() {
        super("CreateArcCmd");
    }

    Process(store: Store) {
        store.ui.Set().promptBar.Set("ARC", "Select start point or", ["Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateArcWith1Point(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
            }
        ));
    }

    CreateArcWith1Point(wP0: number[], store: Store) {
        store.ui.Set().promptBar.Set("ARC", "Select second point or", ["Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateArcWith2Points(wP0, wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                    store.SetActiveState(new EditorState());
            },
            (ctx, wP) => { // gizmo
                ctx.setLineDash([10, 3]);
                ctx.strokeStyle = store.GetActiveDoc().GetActiveLayer().color;
                const canvasP0 = store.WorldToCanvas(wP0);
                const canvasP1 = store.WorldToCanvas(wP);
                ctx.moveTo(canvasP0[0], canvasP0[1]);
                ctx.lineTo(canvasP1[0], canvasP1[1]);
                ctx.stroke();
                ctx.setLineDash([]);
            }
        ));
    }

    CreateArcWith2Points(wP0: number[], wP1: number[], store: Store) {
        store.ui.Set().promptBar.Set("ARC", "Select end point or", ["Cancel"], "");

        const arc = new Arc(store.GenEntityId(), [0,0], 1, [0,0], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());

        store.SetActiveState(new PointSelectState(
            wP => { // success
                arc.Set3Points(wP0, wP1, wP);
                this.DoAddArcAction(arc, store);

                store.SetActiveState(new EditorState());
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
                arc.Set3Points(wP0, wP1, wP);
                arc.Draw(ctx, store);
            }
        ));
    }

    DoAddArcAction(arc: Arc, store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                store.GetActiveDoc().AddMask(arc);
                ApiNewChange(store.GetActiveDoc().AddEntity(arc), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                store.GetActiveDoc().RemoveMask(arc);
                ApiNewChange(store.GetActiveDoc().RemoveEntity(arc), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}