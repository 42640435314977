import { Store } from "../store/store";
import { Command } from "./command";

export class DeselectCmd extends Command{
    constructor() {
        super("DeselectCmd");
    }

    Process(store: Store) {
        store.ui.Get().selection.entities.forEach(entity => {
            entity.SetIsSelected(false);
        })
        store.ui.Set().selection.entities.clear();
    }
}