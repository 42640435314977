import { Store } from "../store/store";
import { Snap } from "./snap";

export class QuadrantPointSnap extends Snap {
    constructor(p: number[]) {
        super("QUADRANT_POINT", p);
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        const cP = store.WorldToCanvas(this.p);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'cyan';
        ctx.beginPath();
        ctx.moveTo(cP[0] - 7, cP[1]);
        ctx.lineTo(cP[0], cP[1] - 7);
        ctx.lineTo(cP[0] + 7, cP[1]);
        ctx.lineTo(cP[0], cP[1] + 7);
        ctx.lineTo(cP[0] - 7, cP[1]);
        ctx.stroke();
        ctx.lineWidth = 1;
    }
}