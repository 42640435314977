
export function WorldToCanvas(wX: number, wY:number, wCx: number, wCy: number, wWidth: number, cWidth: number, cHeight: number) {
    const scale = cWidth / wWidth;
    const cX = (wX - wCx + 0.5 * wWidth) * scale;
    const cY = ((wCy + 0.5 * cHeight / scale) - wY) * scale;
    return [ cX, cY ];
}

export function WorldToCanvasDelta(cDx: number, cDy:number, wCx: number, wCy: number, wWidth: number, cWidth: number, cHeight: number) {
    const p0 = WorldToCanvas(0, 0, wCx, wCy, wWidth, cWidth, cHeight);
    const p1 = WorldToCanvas(cDx, cDy, wCx, wCy, wWidth, cWidth, cHeight);
    return [ p1[0] - p0[0], p1[1] - p0[1] ];
}

export function CanvasToWorld(cX: number, cY:number, wCx: number, wCy: number, wWidth: number, cWidth: number, cHeight: number) {
    const inv_scale = wWidth / cWidth;
    const wX = cX * inv_scale + wCx - 0.5 * wWidth;
    const wY = wCy + 0.5 * cHeight * inv_scale - cY * inv_scale;
    return [ wX, wY ];
}

export function CanvasToWorldDelta(cDx: number, cDy:number, wCx: number, wCy: number, wWidth: number, cWidth: number, cHeight: number) {
    const p0 = CanvasToWorld(0, 0, wCx, wCy, wWidth, cWidth, cHeight);
    const p1 = CanvasToWorld(cDx, cDy, wCx, wCy, wWidth, cWidth, cHeight);
    return [ p1[0] - p0[0], p1[1] - p0[1] ];
}

export function CanvasToClient(cX: number, cY:number, canvasLeft: number, canvasTop: number) {
    return [ canvasLeft + cX, canvasTop + cY ];
}

export function ClientToCanvas(sX: number, sY:number, canvasLeft: number, canvasTop: number) {
    return [ sX - canvasLeft, sY - canvasTop ];
}

export function WorldToClient(wX: number, wY:number, wCx: number, wCy: number, wWidth: number, cWidth: number, cHeight: number, canvasLeft: number, canvasTop: number) {
    const p = WorldToCanvas(wX, wY, wCx, wCy, wWidth, cWidth, cHeight);
    return CanvasToClient(p[0], p[1], canvasLeft, canvasTop);
}

export function ClientToWorld(sX: number, sY:number, wCx: number, wCy: number, wWidth: number, cWidth: number, cHeight: number, canvasLeft: number, canvasTop: number) {
    const p = ClientToCanvas(sX, sY, canvasLeft, canvasTop);
    return CanvasToWorld(p[0], p[1], wCx, wCy, wWidth, cWidth, cHeight);
}