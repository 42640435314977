import { SegmentAreaIntersects } from "../../../math/intersects";
import { PointLineNearestPoint, PointSegmentNearestPoint } from "../../../math/nearest";
import { PointEquals } from "../../../math/point";
import { WorldToCanvas } from "../../../math/projection";
import { EndPointSnap } from "../../../snaps/endPoint";
import { MidPointSnap } from "../../../snaps/midPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { LayerData } from "../layers/layerData";
import { SpaceData } from "../spaces/spaceData";
import { EntityData } from "./entityData";

export class LineData extends EntityData {
    p0: number[];
    p1: number[];

    constructor(id: string, p0: number[], p1: number[], space: SpaceData, layer: LayerData) {
        super(id, space, layer);
        this.p0 = [...p0]; this.p1 = [...p1];
    }
}