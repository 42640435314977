export function DistSqr(p0: number[], p1: number[]) {
    return (p0[0] - p1[0]) * (p0[0] - p1[0]) + (p0[1] - p1[1]) * (p0[1] - p1[1]);
}

export function Dist(p0: number[], p1: number[]) {
    return Math.sqrt(DistSqr(p0, p1));
}

export function PointEquals(p0: number[], p1: number[]) {
    return Dist(p0, p1) < 0.0000001;
}

export function DistEquals(a: number, b: number) {
    return Math.abs(a - b) < 0.0000001;
}