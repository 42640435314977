import { CanvasToWorldDelta } from "../../math/projection";
import { Store } from "../../store/store";
import { Gesture } from "./gesture";

export class PanGesture extends Gesture {

    MouseDown(event: any, store: Store) {
        if (event.button === 1) // middle button
            store.ui.SetMousePanningPrevPos(store.ui.GetMousePos());
    }

    MouseMove(event: any, store: Store) {
        if (store.ui.GetMousePanningPrevPos().length > 0) // middle button down, panning
        {
            const deltaX = store.ui.GetMousePanningPrevPos()[0] - store.ui.GetMousePos()[0];
            const deltaY = store.ui.GetMousePanningPrevPos()[1] - store.ui.GetMousePos()[1];
            const worldDelta = store.CanvasToWorldDelta([deltaX, deltaY]);

            store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter([
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0] + worldDelta[0],
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1] + worldDelta[1]
            ]);
            store.ui.SetMousePanningPrevPos(store.ui.GetMousePos());
        }
    }

    MouseUp(event: any, store: Store) {
        if (event.button === 1) // middle button
            store.ui.SetMousePanningPrevPos([]);
    }

    MouseEnter(event: any, store: Store) {
        store.ui.SetMousePanningPrevPos([]);
    }

    MouseLeave(event: any, store: Store) {
        store.ui.SetMousePanningPrevPos([]);
    }
}