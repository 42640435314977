import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import { Store } from "../../store/store";
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { ReadyButton } from "../lib/buttons";
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ViewCarouselRoundedIcon from '@mui/icons-material/ViewCarouselRounded';
import Crop169Icon from '@mui/icons-material/Crop169';
import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

export interface MobileFileManagerDialogProps {
    store: Store;
}

export default function MobileFileManagerDialog(props: MobileFileManagerDialogProps) {

    const largeSizeBlock = [1,2,3,4,5,6,7,8,9,0].map(x=>(
        <Stack key={'x'} sx={{width:340, height:200}} alignItems={'center'} justifyContent={'center'}>
            <img src="/drawing.webp" style={{}}/>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{}}>
                <ReadyButton toolTip="File options" w={10} h={16} bc='black' c='lightgray' hbc='black' hc='white'>
                    <MoreVertIcon fontSize="large"/>
                </ReadyButton>
                <ReadyButton h={16} bc='black' c='lightgray' hbc='black' hc='white' jc='start'>
                    <Typography noWrap sx={{ mr:1.5,maxWidth:250,  textOverflow: 'ellipsis', overflow: 'clip', whiteSpace: 'nowrap' }}>Drawing Longer Filename asd fasd fasd fasd fasd fasd fa sd</Typography>
                </ReadyButton>
            </Stack>
        </Stack>
    ));
    const mediumSizeBlock = [1,2,3,4,5,6,7,8,9,0].map(x=>(
        <Stack key={'x'} sx={{width:340, height:125}} alignItems={'center'} justifyContent={'center'}>
            <img src="/drawing.webp" style={{width:100,height:75}}/>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{}}>
                <ReadyButton toolTip="File options" w={10} h={16} bc='black' c='lightgray' hbc='black' hc='white'>
                    <MoreVertIcon fontSize="large"/>
                </ReadyButton>
                <ReadyButton h={16} bc='black' c='lightgray' hbc='black' hc='white' jc='start'>
                    <Typography noWrap sx={{ mr:1.5,maxWidth:250,  textOverflow: 'ellipsis', overflow: 'clip', whiteSpace: 'nowrap' }}>Drawing Longer Filename asd fasd fasd fasd fasd fasd fa sd</Typography>
                </ReadyButton>
            </Stack>
        </Stack>
    ));
    const smallSizeBlock = [1,2,3,4,5,6,7,8,9,0].map(x=>(
        <Box key={'x'} sx={{width:325, height:50}}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'start'} sx={{}}>
                <ReadyButton w={325} h={16} bc='black' c='lightgray' hbc='black' hc='white' jc='start'>
                    <InsertDriveFileOutlinedIcon fontSize="large"/>
                    <Typography noWrap sx={{ ml:1,mr:0.5, textOverflow: 'ellipsis', overflow: 'clip', whiteSpace: 'nowrap' }}>Drawing Longer Filename Drawing asda asd asd as das as da </Typography>
                    <MoreVertIcon fontSize="large"/>
                </ReadyButton>
            </Stack>
        </Box>
    ));

    return (
        <>
            <Dialog
                open={false}
                onClose={() => {}}
                fullScreen
                
            >
                <DialogTitle sx={{bgcolor:'lightgray'}}>
                    <Stack spacing={1}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'row'} sx={{p:1}} alignItems={'center'}>
                                <Avatar alt={props.store.ui.account.name} src={props.store.ui.account.avatarUrl} />
                                <KeyboardArrowRightRoundedIcon/>
                                <Typography variant="h6">..</Typography>
                                <KeyboardArrowRightRoundedIcon/>
                                <Typography variant="h6">Folder</Typography>
                            </Stack>
                            <ReadyButton w={20} h={20} bc='lightgray' c='gray' hbc='lightgray' hc='gray' >
                                <DisabledByDefaultRoundedIcon fontSize="large" sx={{color:'gray'}}/>
                            </ReadyButton>
                        </Stack>
                        <Stack direction={'row'} spacing={0.5} alignItems={'center'} justifyContent={'center'}>
                            <ReadyButton w={30} h={24} bc='lightgray' c='gray' hbc='gray' hc='white'>
                                <ArrowBackOutlinedIcon fontSize="small"/>
                            </ReadyButton>
                            <ReadyButton w={30} h={24} bc='lightgray' c='gray' hbc='gray' hc='white'>
                                <Crop169Icon fontSize="small"/>
                            </ReadyButton>
                            <ReadyButton w={30} h={24} bc='lightgray' c='gray' hbc='gray' hc='white'>
                                <ViewCarouselRoundedIcon/>
                            </ReadyButton>
                            <ReadyButton w={30} h={24} bc='lightgray' c='gray' hbc='gray' hc='white' pressed={true}>
                                <ViewCarouselRoundedIcon fontSize="large"/>
                            </ReadyButton>
                            <ReadyButton w={85} h={24} bc='lightgray' c='gray' hbc='gray' hc='white'>
                                <Typography>Recent</Typography>
                                <ArrowDropDownRoundedIcon/>
                            </ReadyButton>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <DialogContent dividers={true} sx={{bgcolor:'black', border:'5px solid lightgray'}}>
                <DialogContentText
                    id="scroll-dialog-description"
                    tabIndex={-1}
                >
                    <Stack spacing={1} direction="row" useFlexGap flexWrap="wrap" sx={{}}>
                        {smallSizeBlock}
                    </Stack>
                </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}