import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Avatar, Box, Divider, ListItemIcon, Stack, Typography, popoverClasses } from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Check } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

export interface MobileAppMenuClosedProps {
    store: Store;
}

export default function MobileAppMenuClosed(props: MobileAppMenuClosedProps) {
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const openClick = () => {
        if (isAuthenticated) {

        }
        else loginWithRedirect();
    }
    
    const handleClose = () => {
        props.store.ui.Set().mobileAppMenuClosed.anchorEl = null;
    };
    return (
        <Menu anchorEl={props.store.ui.Get().mobileAppMenuClosed.anchorEl} 
              open={Boolean(props.store.ui.Get().mobileAppMenuClosed.anchorEl)} 
              sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} 
              onClose={handleClose}
              transformOrigin={{
                vertical: -5,
                horizontal: "center",
              }}>
            <MenuItem onClick={openClick}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <FileOpenOutlinedIcon fontSize="large" sx={{mr:1}}/>
                        <Typography>Open</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'} justifyContent={'end'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{mr:1}}>
                        <Avatar alt={props.store.ui.account.name} src={props.store.ui.account.avatarUrl} />
                    </Stack>
                    <ArrowRightIcon sx={{float: "right"}}/>
                </Stack>
            </MenuItem>
        </Menu>
    );
}