import { ZoomPreserveCanvasPoint } from "../../math/zoom";
import { Store } from "../../store/store";
import { Gesture } from "./gesture";

export class ZoomWheelGesture extends Gesture {

    MouseWheel(event: any, store: Store) {
        const p = event.deltaY > 0 ? 1.1 : 0.9;
        const canvasPoint = store.ClientToCanvas(store.ui.GetMousePos());

        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter(
            ZoomPreserveCanvasPoint(
                canvasPoint[0], canvasPoint[1], p, 
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0], 
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1], 
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth(), 
                store.ui.GetCanvasRect()[2], store.ui.GetCanvasRect()[3]
            )
        );
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionWidth(
            store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth() * p);
    }
}