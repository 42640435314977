import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LayersIcon from '@mui/icons-material/Layers';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Stack, TextField } from '@mui/material';
import { useState } from 'react';

export default function LeftPannel() {
  const [t, st] = useState("")
  return (
    <Stack direction={'row'} sx={{ height: '100%', bgcolor: 'black' }}>
      <Box sx={{ bgcolor: 'black', width: 170, height: '100%' }}>
        <Stack>
          <Stack alignItems='center' sx={{ bgcolor: 'gray', color: 'white', p:2, mt:1, ml:1, mb:1, borderRadius:'4px 0px 0px 4px' }}>
            <FormatListBulletedIcon/>
            <Typography>Properties</Typography>
          </Stack>
          <Stack alignItems='center' sx={{ bgcolor: 'black', color: 'lightgray', p:2, ml:1, mb:1 }}>
            <LayersIcon/>
            <Typography>Layers</Typography>
          </Stack>
          <Stack alignItems='center' sx={{ bgcolor: 'black', color: 'lightgray', p:2, ml:1, mb:1 }}>
            <WidgetsIcon/>
            <Typography>Blocks</Typography>
          </Stack>
        </Stack>
      </Box>
      <Box sx={{ bgcolor: 'gray', width: 'calc(100% - 10px)', height: 'calc(100% - 24px)', borderRadius:'0px 4px 4px 4px', p:1, mt:1, mr:1 }}>
        <TextField id="inputHello" label="Filled" variant="filled" value={t} onChange={e=>st(e.target.value)}/>
      </Box>
    </Stack>
  );
}