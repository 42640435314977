import { ApiNewChange } from "../api/doc";
import { IEntity, dat } from "../doc/extended/entities/entity";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { BufferBuilder } from "../tsutils/binary";
import { Command } from "./command";

export class EraseCmd extends Command{
    constructor() {
        super("EraseCmd");
    }

    Process(store: Store) {
        const selectedEntities = Array.from(store.ui.Get().selection.entities);
        this.DoEraseAction(selectedEntities, store);
    }

    DoEraseAction(selectedEntities: IEntity[], store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                const bb = new BufferBuilder();
                selectedEntities.forEach(entity => {
                    bb.Add(store.GetActiveDoc().RemoveEntity(dat(entity)));
                    store.GetActiveDoc().RemoveMask(entity);
                })        
                ApiNewChange(bb.ToBuffer(), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                const bb = new BufferBuilder();
                selectedEntities.forEach(entity => {
                    bb.Add(store.GetActiveDoc().AddEntity(dat(entity)));
                    store.GetActiveDoc().AddMask(entity);
                })
                ApiNewChange(bb.ToBuffer(), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}