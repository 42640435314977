import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Divider, Stack, Typography, popoverClasses } from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export interface EditMenuProps {
    store: Store;
}

export default function EditMenu(props: EditMenuProps) {
    const handleClose = () => {
        props.store.ui.Set().editMenu.anchorEl = null;
    };
    return (
        <Menu anchorEl={props.store.ui.Get().editMenu.anchorEl} open={Boolean(props.store.ui.Get().editMenu.anchorEl)} sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} onClose={handleClose}>
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <UndoIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Undo</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>⌘S</Typography>
                </Stack>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <RedoIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Redo</Typography>
                    </Stack>
                </Stack>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <ContentCutIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Cut</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>⌘X</Typography>
                </Stack>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <ContentCopyIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Copy</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>⌘X</Typography>
                </Stack>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <Stack direction={'row'} sx={{width: 140}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <ContentPasteIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Paste</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>⌘X</Typography>
                </Stack>
            </MenuItem>
        </Menu>
    );
}