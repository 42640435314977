import { SpaceData } from "../../data/spaces/spaceData";

export class Space extends SpaceData {
    worldProjectionCenter: number[] = [0,0];
    worldProjectionWidth: number = 100;

    GetWorldProjectionCenter(): number[] { return this.worldProjectionCenter; }
    SetWorldProjectionCenter(newCenter: number[]) { this.worldProjectionCenter = newCenter; }

    GetWorldProjectionWidth(): number { return this.worldProjectionWidth; }
    SetWorldProjectionWidth(newWidth: number) { this.worldProjectionWidth = newWidth; }
}