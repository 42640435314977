import Divider from '@mui/material/Divider';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function SnapButton() {
  return (
    <Stack direction={"row"} spacing={1} sx={{ bgcolor: 'lightgray', borderRadius:'4px', width: 106 }} alignItems={'center'} justifyContent={'flex-end'}>
        <Typography sx={{ color: 'black', pr:1 }}>Snap</Typography>
        <Divider orientation='vertical' sx={{ bgcolor: 'lightgray' }}/>
        <KeyboardArrowUpIcon sx={{ color: 'black', pr:1 }}/>
    </Stack>
  );
}