import { LayerData } from "../layers/layerData";
import { SpaceData } from "../spaces/spaceData";

export abstract class EntityData {
    id: string;
    space: SpaceData;
    layer: LayerData;
    extra: any = undefined;

    constructor(id: string, space: SpaceData, layer: LayerData) {
        this.id = id; this.space = space; this.layer = layer;
    }
}