import { Store } from "../store/store";
import { Command } from "./command";
import { PromptInputCmd } from "./promptInput";

export class AcceleratorKeyCmd extends Command{
    event: any;

    constructor(event: any) {
        super("AcceleratorKeyCmd");
        this.event = event;
    }

    static UpdatePromptInput(event: any, store: Store) {
        if (`abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890-.'" `.indexOf(event.key) >= 0) {
            store.ui.Set().promptBar.promptInput += event.key;
        }
        if (event.key === "Backspace") {
            store.ui.Set().promptBar.promptInput = store.ui.Set().promptBar.promptInput.substring(0, store.ui.Set().promptBar.promptInput.length - 1);
        }
        if (event.key === "Enter") {
            store.GetActiveState().Command(new PromptInputCmd(store.ui.Get().promptBar.promptInput), store);
        }
    }
}