export default class PromptBar {
    title: string = "";
    msg: string = "";
    buttons: string[] = [];
    defaultInputMsg: string = "";
    promptInput: string = "";

    constructor() {
    }

    Set(title: string, msg: string, buttons: string[], defaultInputMsg: string) {
        this.title = title;
        this.msg = msg;
        this.buttons = buttons;
        this.defaultInputMsg = defaultInputMsg;
        this.promptInput = "";
    }
}