import { Dist, DistEquals } from "../math/point";
import { IEntity } from "../doc/extended/entities/entity";
import { Store } from "../store/store";
import { Command } from "./command";
import { SnapMoveCmd } from "./snapMove";

export class ClickSelectCmd extends Command{
    wP: number[]; 
    shiftKey: boolean;

    constructor(wP: number[], shiftKey: boolean) {
        super("ClickSelectCmd"); this.wP = [...wP]; this.shiftKey = shiftKey;
    }

    Process(store: Store) {
        const wSelectRadius = store.CanvasToWorldDelta([5, 0])[0];
        const entities = store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaExact(
            store.GetActiveDoc().GetActiveSpace(),
            layer => layer.isVisible,
            () => true,
            [this.wP[0] - wSelectRadius, this.wP[1] - wSelectRadius, 2 * wSelectRadius, 2 * wSelectRadius]
        )

        // Get closest snaps
        var entitiesClicked: IEntity[] = [];
        let nearestP: number[] = [0,0], nearestDist = Number.MAX_SAFE_INTEGER;
        entities.forEach(entity => {
            if (entity.IsSelected())
            {
                console.log(entity.IsSelected());
                entity.GetSrcSnaps().forEach(p => {
                    const dist = Dist(p, this.wP);
                    if (DistEquals(nearestDist, dist))
                    {
                        entitiesClicked.push(entity);
                    }
                    else if (nearestDist > dist)
                    {
                        nearestP = p;
                        nearestDist = dist;
                        entitiesClicked = [entity];
                    }
                })
            }
        })

        console.log('entitiesClicked.length', entitiesClicked.length)

        // If valid snap then do a move, 
        if (entitiesClicked.length > 0 && 5 >= store.WorldToCanvasDelta([nearestDist, 0])[0])
        {
            store.GetActiveState().Command(new SnapMoveCmd(entitiesClicked, nearestP), store);
        }
        else // Otherwise try a (click) select
        {
            entities.forEach(entity => {
                if (!this.shiftKey)
                {
                    entity.SetIsSelected(true);
                    store.ui.Set().selection.entities.add(entity);
                }
                else
                {
                    entity.SetIsSelected(false);
                    store.ui.Set().selection.entities.delete(entity);
                }
            })
        }
    }
}