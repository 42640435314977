import { ApiNewSnapshot } from "../../api/doc";
import { Store } from "../../store/store";
import { Doc } from "./doc";
import { dat, ext } from "./entities/entity";

export function GenerateSnapshot(canvasId: string, store: Store, doc: Doc, cbDone: ()=>void)
{
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    if (canvas !== null)
    {
        const ctx = canvas.getContext('2d')!;

        ctx.fillStyle = 'black'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    
        const prevDoc = store.GetActiveDoc();
        const prevWidth = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth();
        const prevCenter = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter();
        const prevCanvasRect = store.ui.GetCanvasRect();
    
        store.SetActiveDoc(doc);
        store.ui.SetCanvasRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        let minX = Number.MAX_SAFE_INTEGER, maxX = Number.MIN_SAFE_INTEGER, minY = Number.MAX_SAFE_INTEGER, maxY = Number.MIN_SAFE_INTEGER;
        store.GetActiveDoc().EntitiesForEach(entity => {
            if (entity.GetLayer().isVisible && entity.GetSpace() === store.GetActiveDoc().GetActiveSpace())
            {
                const bounds = entity.GetVisibleBoundingRect();
                minX = Math.min(minX, bounds[0]); minY = Math.min(minY, bounds[1]); maxX = Math.max(maxX, bounds[0] + bounds[2]); maxY = Math.max(maxY, bounds[1] + bounds[3]); 
            }
        })
        const margin = 0.05 * Math.max(maxX - minX, maxY - minY);
        minX -= margin;
        minY -= margin;
        maxX += margin;
        maxY += margin;
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionWidth(Math.max(maxX - minX, (maxY - minY) * ctx.canvas.width / ctx.canvas.height));
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter([minX + 0.5 * (maxX - minX), minY + 0.5 * (maxY - minY)]);
    
        // Draw entities
        store.GetActiveDoc().EntitiesForEach(entity => {
            if (entity.GetLayer().isVisible && entity.GetSpace() === store.GetActiveDoc().GetActiveSpace())
                entity.Draw(ctx, store);
        })
    
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionWidth(prevWidth);
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter(prevCenter);
        store.ui.SetCanvasRect(prevCanvasRect[0], prevCanvasRect[1], prevCanvasRect[2], prevCanvasRect[3]);
        store.SetActiveDoc(prevDoc);
    
        canvas.toBlob(
            async (blob) => {
                if (blob !== null) {
                    console.log(blob)
                    doc.snapshotIsDirty = false;
                    ApiNewSnapshot(new Uint8Array(await blob.arrayBuffer()), doc.email, `${doc.fileName}.webp`, store);
                }
                cbDone()
            },
            "image/webp",
            0.25,
        );
    }
    else cbDone();
}