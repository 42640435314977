import { Store } from "../store/store";
import { State } from "./state";
import { useEffect } from "react";
import LoadingSimple from "../components/lib/loadingSimple";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie';
import { IsMobile } from "../tsutils/mobile";
import { MobileViewerState } from "./mobileViewer";
import { MobileClosedState } from "./mobileClosed";
import { EditorState } from "./editor";
import { ViewerState } from "./viewer";
import { ClosedState } from "./closed";
import { DownloadState } from "./download";
import { Doc } from "../doc/extended/doc";
import { decryptViewLink, encryptViewLink } from "../tsutils/crypt";
import { ApiDownloadFile } from "../api/doc";
import { ApiHello } from "../api/api";

interface StateProps {
    state: BootState;
    store: Store;
}

function parseHash(hash: string, dictionary: any): boolean {
    if (hash === undefined) return false;
    const hashTerms = decryptViewLink(hash);
    if (hashTerms === undefined) return false;
    dictionary.email = hashTerms.email;
    dictionary.fileName = hashTerms.fileName; console.log(hash, decodeURI(hash), atob(decodeURI(hash)), dictionary)
    return dictionary.fileName !== undefined;
}

const StateRender: React.FC<StateProps> = ({state, store}) => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();    

    const block = (
    <>
        <LoadingSimple/>
    </>);

    useEffect(() => { (async () => {
        console.log('boot pass on isLoading', user, isAuthenticated, isLoading, isAuthenticated ? await getAccessTokenSilently() : "")

        console.log(encryptViewLink("vdcoder@gmail.com", "drawing.cxi"));

        const parts = window.location.hash.toLowerCase().split("/");
        let obj: any = {};
        if (parts[0] === "#open" && parseHash(parts[1], obj))
        {
            Cookies.set('selectedFileOwnerEmail', obj.email); console.log(obj.email)
            Cookies.set('selectedFileName', obj.fileName);

            window.history.replaceState(null, "", "/")
            //window.location.hash = "";
        }
        else if (isLoading)
        {
        }
        else
        {
            // Selected file in cookies
            const selectedFileName = Cookies.get('selectedFileName');
            const selectedFileOwnerEmail = Cookies.get('selectedFileOwnerEmail');
            const hasSelectedFile = selectedFileName !== undefined && selectedFileOwnerEmail !== undefined;

            if (hasSelectedFile)
            {
                const newDoc = new Doc(selectedFileOwnerEmail, selectedFileName, isAuthenticated ? selectedFileOwnerEmail !== user?.email : undefined);
                store.docs.push(newDoc);
                store.SetActiveDoc(newDoc);
            }

            if (isAuthenticated)
            {
                store.ui.account.id = user?.email ?? "";
                store.ui.account.email = user?.email ?? "";
                store.ui.account.name = user?.name ?? "";
                store.ui.account.avatarUrl = user?.picture ?? "";
                store.ui.account.accessToken = await getAccessTokenSilently();

                //console.log(ApiHello(store));
                
                if (IsMobile()) {
                    if (hasSelectedFile) {
                        store.SetActiveState(new DownloadState(new MobileViewerState()))
                    }
                    else {
                        store.SetActiveState(new MobileClosedState())
                    }
                }
                else { // desk
                    if (hasSelectedFile) {
                        let ownsSelectedFile = selectedFileOwnerEmail === user?.email;

                        if (ownsSelectedFile) {
                            store.SetActiveState(new DownloadState(new EditorState()))
                        }
                        else {
                            store.SetActiveState(new DownloadState(new ViewerState()))
                        }
                    }
                    else {
                        store.SetActiveState(new ClosedState())
                    }
                }
            }
            else // not logged in
            {
                if (IsMobile()) {
                    if (hasSelectedFile) {
                        store.SetActiveState(new DownloadState(new MobileViewerState()))
                    }
                    else {
                        store.SetActiveState(new MobileClosedState())
                    }
                }
                else { // desk
                    if (hasSelectedFile) {
                        store.SetActiveState(new DownloadState(new ViewerState()))
                    }
                    else {
                        store.SetActiveState(new ClosedState())
                    }
                }
            }
        }
    })()}, [isLoading])

    return block;
}

export class BootState extends State {
    constructor() {
        super([], []);
    }

    Activate(store: Store) {}

    Render(store: Store) {
        return <StateRender state={this} store={store}/>
    }
}
