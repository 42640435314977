import { Command } from "../commands/command";
import { Store } from "../store/store";
import { Gesture } from "./gestures/gesture";

export class State {
    gestures: Gesture[];
    commands = new Set<string>();

    constructor(gestures: Gesture[], commands: string[]) {
        this.gestures = gestures;
        commands.forEach(cmdName => this.commands.add(cmdName));
    }

    Activate(store: Store) {}
    Deactivate(store: Store) {}

    Command(cmd: Command, store: Store) {
        if (this.commands.has(cmd.name))
            cmd.Process(store);
    }

    MouseDown(event: any, store: Store) {
        store.ui.SetMousePos([event.clientX, event.clientY]);
        this.gestures.forEach(gesture => gesture.MouseDown(event, store));
    }

    MouseUp(event: any, store: Store) {
        store.ui.SetMousePos([event.clientX, event.clientY]);
        this.gestures.forEach(gesture => gesture.MouseUp(event, store));
    }

    MouseMove(event: any, store: Store) {
        store.ui.SetMousePos([event.clientX, event.clientY]);
        this.gestures.forEach(gesture => gesture.MouseMove(event, store));
    }

    MouseWheel(event: any, store: Store) {
        store.ui.SetMousePos([event.clientX, event.clientY]);
        this.gestures.forEach(gesture => gesture.MouseWheel(event, store));
    }

    MouseEnter(event: any, store: Store) { 
        //event.preventDefault(); 
        store.ui.SetMousePos([event.clientX, event.clientY]);
        this.gestures.forEach(gesture => gesture.MouseEnter(event, store));
    }

    MouseLeave(event: any, store: Store) { 
        //event.preventDefault(); 
        store.ui.SetMousePos([event.clientX, event.clientY]);
        this.gestures.forEach(gesture => gesture.MouseLeave(event, store));
    }

    MouseDblClick(event: any, store: Store) { 
        //event.preventDefault(); 
        store.ui.SetMousePos([event.clientX, event.clientY]);
        this.gestures.forEach(gesture => gesture.MouseDblClick(event, store));
    }

    TouchesPan(event: any, store: Store) { 
        this.gestures.forEach(gesture => gesture.TouchesPan(event, store));
    }

    TouchesPinch(event: any, store: Store) { 
        this.gestures.forEach(gesture => gesture.TouchesPinch(event, store));
    }

    KeyDown(event: any, store: Store) { 
        if (!store.ui.IsInputFocus())
            this.gestures.forEach(gesture => gesture.KeyDown(event, store));
    }

    KeyUp(event: any, store: Store) {
        if (!store.ui.IsInputFocus())
            this.gestures.forEach(gesture => gesture.KeyUp(event, store));
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {}

    Render(store: Store): any { return ""; }
}