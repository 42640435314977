export function encryptViewLink(email: string, fileName: string): string {
    return encryptViewLinkV0(email, fileName);
}

export function decryptViewLink(link: string): {email: string, fileName: string} | undefined {
    return decryptViewLinkV0(link);
}

export function encryptViewLinkV0(email: string, fileName: string): string {
    const sBuffer = (new TextEncoder()).encode(`${email}:${fileName}`);
    return "0" + Array.prototype.map.call(sBuffer, n => ("0" + n.toString(16)).slice(-2)).join("").toLowerCase();
}

export function decryptViewLinkV0(link: string): {email: string, fileName: string} {
    var sBuffer = new Uint8Array(Math.ceil(link.substring(1).length / 2));
    for (var i = 0; i < sBuffer.length; i++) sBuffer[i] = parseInt(link.substring(1).substring(i * 2, i * 2 + 2), 16);
    const t = (new TextDecoder()).decode(sBuffer).split(":");
    return { email: t[0], fileName: t[1]};
}