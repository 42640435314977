import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';

export default function LoadingSimple() {
  return (
    <Stack sx={{position: 'absolute', left: 0, top: 0, width: '100vw', height: '100vh', bgcolor: 'black'}} alignItems={'center'} justifyContent={'center'}>
        <img src="/loading.gif" style={{width:100, height:100}}/>
        <Typography variant='h5' sx={{color:'white', mt:1}}>Please wait</Typography>
    </Stack>
  );
}