import Box from '@mui/material/Box';

import { Button, Stack, TextField, Typography } from '@mui/material';
import { Store } from '../../../store/store';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PromptButtonCmd } from '../../../commands/promptButton';
import { PromptInputCmd } from '../../../commands/promptInput';
import { EditorState } from '../../../states/editor';

export interface PromptBarProps {
    store: Store;
}

export default function PromptBar(props: PromptBarProps) {

    let title = "", msg = "", buttons: string[] = [], defaultInputMsg = "";
    if (props.store.GetActiveState() instanceof EditorState) // Idle state, ui adds selection count and deselect btn
    {
        title = "";
        const count = props.store.ui.Get().selection.entities.size;
        msg = count > 0 ? `${count} selected` : "";
        buttons = count > 0 ? ["Deselect"] : [];
        defaultInputMsg = "Enter command here"
    }
    else // Other states
    {
        title = props.store.ui.Get().promptBar.title;
        msg = props.store.ui.Get().promptBar.msg;
        buttons = props.store.ui.Get().promptBar.buttons;
        defaultInputMsg = props.store.ui.Get().promptBar.defaultInputMsg;
    }

    let titleBlock = title !== "" ?
        (<Typography noWrap sx={{color: 'white'}}><b>{props.store.ui.Get().promptBar.title}</b></Typography>) : "";

    let msgBlock = msg !== "" ?
        (<Typography noWrap sx={{color: 'white', pr:0}}>{msg}</Typography>) : "";

    let colonBlock = (title !== "" || msg !== "" || buttons.length > 0) && defaultInputMsg !== "" ?
        (<Typography noWrap sx={{color: 'white', pl:1}}>:</Typography>) : "";

    let inputBlock = defaultInputMsg !== "" ?
        (<TextField  sx={{ bgcolor: 'black', input:{ color: 'white' } }} 
            hiddenLabel
            id="inputCommand"
            placeholder={defaultInputMsg}
            variant="filled"
            size="small"
            value={props.store.ui.Get().promptBar.promptInput}
            onChange={e => props.store.ui.Set().promptBar.promptInput = e.target.value}
            onKeyDown={e => {
                if (e.key === "Enter")
                {
                    props.store.ui.FocusFrame();
                    props.store.GetActiveState().Command(new PromptInputCmd(props.store.ui.Get().promptBar.promptInput), props.store);
                }
            }}/>) : "";

    return (
        <Stack direction={"row"} sx={{ p: 1, backgroundColor: 'black', height: '40px' }} alignItems={'center'} spacing={1}>
            <KeyboardArrowRightIcon sx={{ color: 'white' }}/>
            {titleBlock}
            {msgBlock}
            {buttons.map(bName => 
                <Button key={bName} size='small' sx={{bgcolor: 'gray', color: 'white'}} 
                    onClick={()=> {
                        props.store.ui.FocusFrame();
                        props.store.GetActiveState().Command(new PromptButtonCmd(bName), props.store);
                    }}>{bName}</Button>)}
            {colonBlock}
            {inputBlock}
        </Stack>
    )
}