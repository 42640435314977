import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Store } from '../../store/store';
import { useAuth0 } from "@auth0/auth0-react";
import { ZoomExtentsCmd } from '../../commands/zoomExtents';

export interface MobileAppBarClosedProps {
  store: Store;
}

export default function MobileAppBarClosed(props: MobileAppBarClosedProps) {

  const { loginWithRedirect } = useAuth0();

  const mobileAppMenuClick = (event: any) => props.store.ui.Set().mobileAppMenuClosed.anchorEl = event.currentTarget;

  return (
    <Box height={72} sx={{bgcolor: 'lightgray'}}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} padding={2}>
        <Stack direction={'row'} alignItems={'center'}>
          <Box fontSize='large' sx={{ ml: 0, mr: 1 }}>
            <img src='./logo512.png' style={{width:32,height:32}}/>
          </Box>
          <Typography variant="h6" sx={{ mr: 0, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem' }}>
            Cadxi
          </Typography>          
        </Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton sx={{ mr: 0.25 }} onClick={mobileAppMenuClick}>
            <MenuIcon sx={{color: 'black'}} fontSize='large'/>
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}