import { BufferBuilder, DeserializeByte, DeserializeDouble, DeserializeId, DeserializeInt, DeserializeString, SerializeByte, SerializeDouble, SerializeId, SerializeInt, SerializeString } from "../../tsutils/binary";
import { ArcData } from "./entities/arcData";
import { CircleData } from "./entities/circleData";
import { EntityData } from "./entities/entityData";
import { LineData } from "./entities/lineData";
import { PolyData } from "./entities/polyData";
import { TextualData } from "./entities/textualData";
import { LayerData } from "./layers/layerData";
import { SpaceData } from "./spaces/spaceData";

enum ChangeTypeEnum {
    AddEntity,
    AddSpace,
    AddLayer,
    UpdateEntity,
    RemoveEntity,
}

enum EntityTypeEnum {
    Line,
    Circle,
    Arc,
    Poly,
    Textual,
}

export class DocData {
    spaces: Map<string, SpaceData> = new Map<string, SpaceData>();
    layers: Map<string, LayerData> = new Map<string, LayerData>();
    entities: Map<string, EntityData> = new Map<string, EntityData>();

    CreateSpace(id: string) { return new SpaceData(id); }
    CreateLayer(id: string, name: string, color: string, isVisible: boolean) { return new LayerData(id, name, color, isVisible); }
    CreateLine(id: string, p0: number[], p1: number[], space: SpaceData, layer: LayerData) { return new LineData(id, p0, p1, space, layer); }
    CreateCircle(id: string, c: number[], r: number, space: SpaceData, layer: LayerData) { return new CircleData(id, c, r, space, layer); }
    CreateArc(id: string, c: number[], r: number, a: number[], space: SpaceData, layer: LayerData) { return new ArcData(id, c, r, a, space, layer); }
    CreatePoly(id: string, es: EntityData[], space: SpaceData, layer: LayerData) { return new PolyData(id, es, space, layer); }
    CreateTextual(id: string, area: number[], text: string, h: number, f: string, space: SpaceData, layer: LayerData) { return new TextualData(id, area, text, h, f, space, layer); }

    Deserialize(buffer: Uint8Array) {
        this.spaces = new Map<string, SpaceData>();
        this.layers = new Map<string, LayerData>();
        this.entities = new Map<string, EntityData>();

        let offset: { value: number } = { value: 0 };
        while (offset.value < buffer.byteLength) 
            this.DeserializeChange(buffer, offset);
    }

    DeserializeEntity(buffer: Uint8Array, offset: { value: number }): EntityData | undefined {
        switch (DeserializeByte(buffer, offset))
        {
            case EntityTypeEnum.Line: {
                const id = DeserializeId(buffer, offset);
                const space = this.spaces.get(DeserializeId(buffer, offset));
                const layer = this.layers.get(DeserializeId(buffer, offset));
                const p0x = DeserializeDouble(buffer, offset);
                const p0y = DeserializeDouble(buffer, offset);
                const p1x = DeserializeDouble(buffer, offset);
                const p1y = DeserializeDouble(buffer, offset);
                return this.CreateLine(id, [p0x,p0y], [p1x,p1y], space!, layer!);
            } break;
            case EntityTypeEnum.Circle: {
                const id = DeserializeId(buffer, offset);
                const space = this.spaces.get(DeserializeId(buffer, offset));
                const layer = this.layers.get(DeserializeId(buffer, offset));
                const cx = DeserializeDouble(buffer, offset);
                const cy = DeserializeDouble(buffer, offset);
                const r = DeserializeDouble(buffer, offset);
                return this.CreateCircle(id, [cx,cy], r, space!, layer!);
            } break;
            case EntityTypeEnum.Arc: {
                const id = DeserializeId(buffer, offset);
                const space = this.spaces.get(DeserializeId(buffer, offset));
                const layer = this.layers.get(DeserializeId(buffer, offset));
                const cx = DeserializeDouble(buffer, offset);
                const cy = DeserializeDouble(buffer, offset);
                const r = DeserializeDouble(buffer, offset);
                const a0 = DeserializeDouble(buffer, offset);
                const a1 = DeserializeDouble(buffer, offset);
                return this.CreateArc(id, [cx,cy], r, [a0,a1], space!, layer!);
            } break;
            case EntityTypeEnum.Poly: {
                const id = DeserializeId(buffer, offset);
                const space = this.spaces.get(DeserializeId(buffer, offset));
                const layer = this.layers.get(DeserializeId(buffer, offset));
                const len = DeserializeInt(buffer, offset);
                const es: EntityData[] = [];
                for (let i = 0; i < len; i++)
                    es.push(this.DeserializeEntity(buffer, offset)!);
                return this.CreatePoly(id, es, space!, layer!);
            } break;
            case EntityTypeEnum.Textual: {
                const id = DeserializeId(buffer, offset);
                const space = this.spaces.get(DeserializeId(buffer, offset));
                const layer = this.layers.get(DeserializeId(buffer, offset));
                const areax = DeserializeDouble(buffer, offset);
                const areay = DeserializeDouble(buffer, offset);
                const areaw = DeserializeDouble(buffer, offset);
                const areah = DeserializeDouble(buffer, offset);
                const text = DeserializeString(buffer, offset);
                const h = DeserializeDouble(buffer, offset);
                const f = DeserializeString(buffer, offset);
                return this.CreateTextual(id, [areax,areay,areaw,areah], text, h, f, space!, layer!);
            } break;
        }
    }

    DeserializeLayer(buffer: Uint8Array, offset: { value: number }): LayerData {
        const id = DeserializeId(buffer, offset);
        const name = DeserializeString(buffer, offset);
        const color = DeserializeString(buffer, offset);
        const isVisible = DeserializeByte(buffer, offset);
        return this.CreateLayer(id, name, color, isVisible === 1);
    }

    DeserializeSpace(buffer: Uint8Array, offset: { value: number }): SpaceData {
        const id = DeserializeId(buffer, offset);
        return this.CreateSpace(id);
    }

    DeserializeChange(buffer: Uint8Array, offset: { value: number }) {
        switch (DeserializeByte(buffer, offset))
        {
            case ChangeTypeEnum.AddEntity: {
                const entity = this.DeserializeEntity(buffer, offset)!;
                this.entities.set(entity.id, entity);
            } break;
            case ChangeTypeEnum.AddSpace: {
                const space = this.DeserializeSpace(buffer, offset);
                this.spaces.set(space.id, space);
            } break;
            case ChangeTypeEnum.AddLayer: {
                const layer = this.DeserializeLayer(buffer, offset);
                this.layers.set(layer.id, layer);
            } break;
            case ChangeTypeEnum.UpdateEntity: {
                const entity = this.DeserializeEntity(buffer, offset)!;
                this.entities.set(entity.id, entity);
            } break;
            case ChangeTypeEnum.RemoveEntity: {
                const id = DeserializeId(buffer, offset);
                this.entities.delete(id);
            } break;
        }
    }

    Serialize(bb: BufferBuilder) {
        const tempDoc = new DocData();
        this.spaces.forEach(space => bb.Add(tempDoc.AddSpace(space)));
        this.layers.forEach(layer => bb.Add(tempDoc.AddLayer(layer)));
        this.entities.forEach(entity => bb.Add(tempDoc.AddEntity(entity)));
    }

    SerializeEntity(entity: EntityData, bb: BufferBuilder) {
        if (entity instanceof LineData) {
            const e = entity as LineData;
            bb.Add(SerializeByte(EntityTypeEnum.Line));
            bb.Add(SerializeId(e.id));
            bb.Add(SerializeId(e.space.id));
            bb.Add(SerializeId(e.layer.id));
            bb.Add(SerializeDouble(e.p0[0]));
            bb.Add(SerializeDouble(e.p0[1]));
            bb.Add(SerializeDouble(e.p1[0]));
            bb.Add(SerializeDouble(e.p1[1]));
        }
        if (entity instanceof CircleData) {
            const e = entity as CircleData;
            bb.Add(SerializeByte(EntityTypeEnum.Circle));
            bb.Add(SerializeId(e.id));
            bb.Add(SerializeId(e.space.id));
            bb.Add(SerializeId(e.layer.id));
            bb.Add(SerializeDouble(e.c[0]));
            bb.Add(SerializeDouble(e.c[1]));
            bb.Add(SerializeDouble(e.r));
        }
        if (entity instanceof ArcData) {
            const e = entity as ArcData;
            bb.Add(SerializeByte(EntityTypeEnum.Arc));
            bb.Add(SerializeId(e.id));
            bb.Add(SerializeId(e.space.id));
            bb.Add(SerializeId(e.layer.id));
            bb.Add(SerializeDouble(e.c[0]));
            bb.Add(SerializeDouble(e.c[1]));
            bb.Add(SerializeDouble(e.r));
            bb.Add(SerializeDouble(e.a[0]));
            bb.Add(SerializeDouble(e.a[1]));
        }
        if (entity instanceof PolyData) {
            const e = entity as PolyData;
            bb.Add(SerializeByte(EntityTypeEnum.Poly));
            bb.Add(SerializeId(e.id));
            bb.Add(SerializeId(e.space.id));
            bb.Add(SerializeId(e.layer.id));
            bb.Add(SerializeInt(e.es.length));
            e.es.forEach(ee => this.SerializeEntity(ee, bb));
        }
        if (entity instanceof TextualData) {
            const e = entity as TextualData;
            bb.Add(SerializeByte(EntityTypeEnum.Textual));
            bb.Add(SerializeId(e.id));
            bb.Add(SerializeId(e.space.id));
            bb.Add(SerializeId(e.layer.id));
            bb.Add(SerializeDouble(e.area[0]));
            bb.Add(SerializeDouble(e.area[1]));
            bb.Add(SerializeDouble(e.area[2]));
            bb.Add(SerializeDouble(e.area[3]));
            bb.Add(SerializeString(e.text));
            bb.Add(SerializeDouble(e.h));
            bb.Add(SerializeString(e.f));
        }
    }

    SerializeLayer(layer: LayerData, bb: BufferBuilder) {
        bb.Add(SerializeId(layer.id));
        bb.Add(SerializeString(layer.name));
        bb.Add(SerializeString(layer.color));
        bb.Add(SerializeByte(layer.isVisible ? 1 : 0));
    }

    SerializeSpace(space: SpaceData, bb: BufferBuilder) {
        bb.Add(SerializeId(space.id));
    }

    AddSpace(space: SpaceData) {
        const bb = new BufferBuilder();
        bb.Add(SerializeByte(ChangeTypeEnum.AddSpace));
        this.SerializeSpace(space, bb);
        const buffer = bb.ToBuffer();
        this.DeserializeChange(buffer, { value: 0 });
        this.spaces.get(space.id)!.extra = space.extra;
        return buffer;
    }

    AddLayer(layer: LayerData) {
        const bb = new BufferBuilder();
        bb.Add(SerializeByte(ChangeTypeEnum.AddLayer));
        this.SerializeLayer(layer, bb);
        const buffer = bb.ToBuffer();
        this.DeserializeChange(buffer, { value: 0 });
        this.layers.get(layer.id)!.extra = layer.extra;
        return buffer;
    }

    AddEntity(entity: EntityData) {
        const bb = new BufferBuilder();
        bb.Add(SerializeByte(ChangeTypeEnum.AddEntity));
        this.SerializeEntity(entity, bb);
        const buffer = bb.ToBuffer();
        this.DeserializeChange(buffer, { value: 0 });
        this.entities.get(entity.id)!.extra = entity.extra;
        return buffer;
    }

    RemoveEntity(entity: EntityData) {
        const bb = new BufferBuilder();
        bb.Add(SerializeByte(ChangeTypeEnum.RemoveEntity));
        bb.Add(SerializeId(entity.id));
        const buffer = bb.ToBuffer();
        this.DeserializeChange(buffer, { value: 0 });
        return buffer;
    }

    UpdateEntity(entity: EntityData) {
        const bb = new BufferBuilder();
        bb.Add(SerializeByte(ChangeTypeEnum.UpdateEntity));
        this.SerializeEntity(entity, bb);
        const buffer = bb.ToBuffer();
        this.DeserializeChange(buffer, { value: 0 });
        this.entities.get(entity.id)!.extra = entity.extra;
        return buffer;
    }
}