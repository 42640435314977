import React, { useRef, useEffect, useState } from 'react'

export interface CanvasProps {
    draw: (cxt: CanvasRenderingContext2D)=>void;
    mouseDown: (event: any)=>void;
    mouseUp: (event: any)=>void;
    mouseMove: (event: any)=>void;
    mouseWheel: (event: any)=>void;
    mouseEnter: (event: any)=>void;
    mouseLeave: (event: any)=>void;
    mouseDblClick: (event: any)=>void;
    touchesPan: (event: any)=>void;
    touchesPinch: (event: any)=>void;
    resizeAndOrMove: (x: number, y: number, w: number, h: number)=>void;
    width: string;
    height: string;
}

declare var Hammer: any;

export function Canvas(props: CanvasProps) {
    const [prevX, setPrevX] = useState(0);
    const [prevY, setPrevY] = useState(0);
    const [prevWidth, setPrevWidth] = useState(0);
    const [prevHeight, setPrevHeight] = useState(0);
    const [hammer, setHammer] = useState<any>(null);

    const canvasRef = useRef(null)
    
    const resizeAndOrMove = () => {
        const canvas: any = canvasRef.current;
        const { x, y, width, height } = canvas.getBoundingClientRect();
        if (x !== prevX || y !== prevY || width !== prevWidth || height !== prevHeight)
        {
            props.resizeAndOrMove(x, y, width, height);
            console.log("resize calls set", width, height)
            setPrevX(x);
            setPrevY(y);
            setPrevWidth(width);
            setPrevHeight(height);
            canvas.setAttribute("width", width);
            canvas.setAttribute("height", height);
        }
    }

    useEffect(() => {
        
        const canvas: any = canvasRef.current;

        const resizeIntervalId = setInterval(resizeAndOrMove, 500);
        resizeAndOrMove();

        console.log('hammer', hammer === null)
        if (hammer === null)
        {
            let hammer = new Hammer(canvasRef.current);
            hammer.get('tap').set({ enable: false });
            hammer.get('press').set({ enable: false });
            hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });
            hammer.get('pinch').set({ enable: true });
            hammer.on('pan', props.touchesPan);
            hammer.on('pinch', props.touchesPinch);
            setHammer(hammer);
        }
        
        const context = canvas.getContext('2d');
        let animationFrameId = 0;

        var frameCount = 0, prevFps = 0;
        const fpsIntervalId = setInterval(()=>{prevFps=frameCount;frameCount=0;}, 1000);
        
        const render = () => {
            props.draw(context)

            frameCount++;
            context.font = "20px Georgia";
            context.fillStyle = "cyan";
            context.fillText("FPS: " + prevFps, 10, 30);

            animationFrameId = window.requestAnimationFrame(render)
        }
        render()
        
        return () => {
            clearInterval(resizeIntervalId);
            clearInterval(fpsIntervalId);
            window.cancelAnimationFrame(animationFrameId);
        }
    })
    
    return <canvas ref={canvasRef} width="500" height="500" style={{width:props.width,height:props.height}} 
            onMouseDown={props.mouseDown} 
            onMouseUp={props.mouseUp} 
            onMouseMove={props.mouseMove} 
            onWheel={props.mouseWheel} 
            onMouseEnter={props.mouseEnter} 
            onMouseLeave={props.mouseLeave}
            onDoubleClick={props.mouseDblClick}
            className='no_cursor'/>
}