import { GenId } from "../../../tsutils/id";
import { RectRectIntersects, SegmentAreaIntersects } from "../../../math/intersects";
import { PointLineNearestPoint, PointSegmentNearestPoint } from "../../../math/nearest";
import { PointEquals } from "../../../math/point";
import { WorldToCanvas } from "../../../math/projection";
import { NormalizeRect } from "../../../math/rect";
import { EndPointSnap } from "../../../snaps/endPoint";
import { MidPointSnap } from "../../../snaps/midPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { TextualData } from "../../data/entities/textualData";
import { Layer } from "../layers/layer";
import { Space } from "../spaces/space";
import { IEntity } from "./entity";

export const TEXTUAL_CHAR_SEPARATION = 0.1;
export const TEXTUAL_LINE_SEPARATION = 0.3;
export const TEXTUAL_PADDING_LEFT = 0.3;
export const TEXTUAL_PADDING_RIGHT = 0.3;
export const TEXTUAL_PADDING_TOP = 0.3;
export const TEXTUAL_PADDING_BOTTOM = 0.3;

export class Textual extends TextualData implements IEntity {
    
    constructor(id: string, area: number[], text: string, h: number, f: string, space: Space, layer: Layer) {
        super(id, area, text, h, f, space, layer);
        this.extra = { isSelected: false }
    }

    GetId(): string { return this.id; }

    GetLayer(): Layer { return this.layer as Layer; }

    GetSpace(): Space { return this.space as Space; }

    IsSelected(): boolean { return this.extra.isSelected; }

    SetIsSelected(value: boolean) { this.extra.isSelected = value; }

    Clone(newId: string) { return new Textual(newId, [...this.area], this.text, this.h, this.f, this.GetSpace(), this.GetLayer()) }

    GetVisibleBoundingRect(): number[] {
        return this.area;
    }
    
    GetInteractiveBoundingRect() { return this.GetVisibleBoundingRect(); }
    
    IntersectsArea(areaRect: number[]): boolean {
        return RectRectIntersects(this.area[0], this.area[1], this.area[2], this.area[3], areaRect[0], areaRect[1], areaRect[2], areaRect[3]);
    }

    GetSrcSnaps(): number[][] { 
        return [ [this.area[0], this.area[1] + this.area[3]], [this.area[0] + 0.5 * this.area[2], this.area[1] + 0.5 * this.area[3]], [this.area[0] + this.area[2], this.area[1]] ]; 
    }

    GetDestSnaps(wPointer: number[]): Snap[] {
        return [];
    }

    MoveBy(delta: number[]) {
        this.area[0] += delta[0]; this.area[1] += delta[1];
    }

    MoveSrcSnap(snapP: number[], newP: number[]) {
        const srcSnaps = this.GetSrcSnaps();
        if (PointEquals(snapP, srcSnaps[0])) {
            this.area[2] += this.area[0] - newP[0];
            this.area[0] = newP[0];
            this.area[3] = newP[1] - this.area[1];
        }
        else if (PointEquals(snapP, srcSnaps[2])) {
            this.area[3] += this.area[1] - newP[1];
            this.area[1] = newP[1];
            this.area[2] = newP[0] - this.area[0];
        }
        else if (PointEquals(snapP, srcSnaps[1])) {
            const delta = [newP[0] - snapP[0], newP[1] - snapP[1]];
            this.area[0] += delta[0]; this.area[1] += delta[1];
        }
        return []; // no endpoints
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        ctx.fillStyle = this.layer.color;
        ctx.textBaseline = "bottom";
        const lines = this.text.split("\n");
        Textual.WalkTextWordWrap(
            this.area,
            this.text,
            this.h,
            this.f,
            TEXTUAL_CHAR_SEPARATION,
            TEXTUAL_LINE_SEPARATION,
            TEXTUAL_PADDING_LEFT,
            TEXTUAL_PADDING_TOP,
            TEXTUAL_PADDING_RIGHT,
            TEXTUAL_PADDING_BOTTOM,
            ctx,
            (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[]) => {
                // {
                //     ctx.strokeStyle = 'red';
                //     const [x, y] = store.WorldToCanvas([charArea[0], charArea[1]]);
                //     const [w, h] = store.WorldToCanvasDelta([charArea[2], charArea[3]]);
                //     ctx.strokeRect(x, y, w, h);
                // }
                // //if (lineIdx === 0)
                // {
                //     ctx.setLineDash([1, 1]);  
                //     ctx.strokeStyle = 'green';
                //     const [x, y] = store.WorldToCanvas([charExtendedArea[0], charExtendedArea[1]]);
                //     const [w, h] = store.WorldToCanvasDelta([charExtendedArea[2], charExtendedArea[3]]);
                //     ctx.strokeRect(x, y, w, h);
                //     ctx.setLineDash([]);  
                // }
                const [x, y] = store.WorldToCanvas([charArea[0], charArea[1]]);
                const h = store.WorldToCanvasDelta([this.h, 0])[0];
                ctx.font = `${h}px ${this.f}`;
                ctx.fillText(lines[lineIdx].substring(charIdx, charIdx + 1), x, y);
            },
            (lineIdx: number, lineArea: number[]) => {
                // ctx.setLineDash([1, 1]);  
                // ctx.strokeStyle = 'yellow';
                // const [x, y] = store.WorldToCanvas([lineArea[0], lineArea[1]]);
                // const [w, h] = store.WorldToCanvasDelta([lineArea[2], lineArea[3]]);
                // ctx.strokeRect(x, y, w, h);
                // ctx.setLineDash([]); 
            }
        )
    }

    DrawSelected(ctx: CanvasRenderingContext2D, store: Store) {
        this.Draw(ctx, store);

        ctx.strokeStyle = this.layer.color;
        ctx.setLineDash([1, 1]);  
        const bounds = this.GetVisibleBoundingRect()
        const [x, y] = store.WorldToCanvas([bounds[0], bounds[1]]);
        const [w, h] = store.WorldToCanvasDelta([bounds[2], bounds[3]]);
        ctx.strokeRect(x, y, w, h);
        ctx.setLineDash([]);  
    }

    // Special

    static WalkTextWordWrap(
        area: number[],
        text: string,
        h: number,
        f: string,
        charSeparation: number,
        lineSeparation: number,
        paddingLeft: number,
        paddingTop: number,
        paddingRight: number,
        paddingBottom: number,
        ctx: CanvasRenderingContext2D,
        cbChar: (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[])=> void,
        cbEmptyLine: (lineIdx: number, lineArea: number[])=> void
    ) {
        let x = area[0] + paddingLeft;
        let y = area[1] + area[3] - paddingTop - h;
        const lines = text.split("\n");
        for (var li = 0; li < lines.length; li++)
        {
            const line = lines[li];
            let chi = 0, rowStartChi = 0;
            for (; chi < line.length; chi++)
            {
                ctx.font = `${h}px ${f}`;
                const metrics = ctx.measureText(line[chi]);
                const w = metrics.width;
                if (x + w + charSeparation > area[0] + area[2] - paddingRight) 
                {
                    const row = line.substring(rowStartChi, chi);
                    let pBreak = row.lastIndexOf(" ") + 1;
                    if (pBreak === 0) pBreak = chi; else pBreak += rowStartChi;

                    x = area[0] + paddingLeft;
                    for (var chj = rowStartChi; chj < pBreak; chj++)
                    {
                        ctx.font = `${h}px ${f}`;
                        const metrics = ctx.measureText(line[chj]);
                        const w = metrics.width;
                        const extSpaceLeft = (chj === rowStartChi ? paddingLeft : 0);
                        const extSpaceTop = (li === 0 && rowStartChi === 0 ? paddingTop : 0);
                        const extSpaceRight = (chj < pBreak - 1 ? charSeparation : area[0] + area[2] - x - w);
                        const extSpaceBottom = (y - h >= area[1] + paddingBottom ? lineSeparation : y - area[1]);
                        cbChar( li, 
                            chj, 
                            [x, y, w, h], 
                            [x - extSpaceLeft, 
                             y - extSpaceBottom, 
                             extSpaceLeft + w + extSpaceRight, 
                             extSpaceTop + h + extSpaceBottom
                            ]);
                        x += w + charSeparation;
                    }

                    if (y - h < area[1] + paddingBottom) return;
                    y -= h + lineSeparation;
                    x = area[0] + paddingLeft;
                    rowStartChi = pBreak;
                    chi = rowStartChi - 1;
                }
                else 
                {
                    x += w + charSeparation;
                }
            }

            if (line.length === 0) // empty line
            {
                const extSpaceTop = (li === 0 ? paddingTop : 0);
                const extSpaceBottom = (li < lines.length - 1 && y - h >= area[1] + paddingBottom ? lineSeparation : y - area[1]);
                cbEmptyLine(li, [area[0], y - extSpaceBottom, area[2], extSpaceTop + h + extSpaceBottom]);
            }
            else
            {
                x = area[0] + paddingLeft;
                for (var chj = rowStartChi; chj < line.length; chj++)
                {
                    ctx.font = `${h}px ${f}`;
                    const metrics = ctx.measureText(line[chj]);
                    const w = metrics.width;
                    const extSpaceLeft = (chj === rowStartChi ? paddingLeft : 0);
                    const extSpaceTop = (li === 0 && rowStartChi === 0 ? paddingTop : 0);
                    const extSpaceRight = (chj < line.length - 1 ? charSeparation : area[0] + area[2] - x - w);
                    const extSpaceBottom = (li < lines.length - 1 && y - h >= area[1] + paddingBottom ? lineSeparation : y - area[1]);
                    cbChar( li, 
                        chj, 
                        [x, y, w, h], 
                        [x - extSpaceLeft, 
                            y - extSpaceBottom, 
                            extSpaceLeft + w + extSpaceRight, 
                            extSpaceTop + h + extSpaceBottom
                        ]);
                    x += w + charSeparation;
                }
            }

            if (y - h < area[1] + paddingBottom) break;
            y -= h + lineSeparation;
            x = area[0] + paddingLeft;
        }
    }

    static WalkText(
        area: number[],
        text: string,
        h: number,
        f: string,
        charSeparation: number,
        lineSeparation: number,
        paddingLeft: number,
        paddingTop: number,
        paddingRight: number,
        paddingBottom: number,
        ctx: CanvasRenderingContext2D,
        cbChar: (lineIdx: number, charIdx: number, charArea: number[], charExtendedArea: number[])=> void,
        cbEmptyLine: (lineIdx: number, lineArea: number[])=> void
    ) {
        let x = area[0] + paddingLeft;
        let y = area[1] + area[3] - paddingTop - h;
        const lines = text.split("\n");
        for (var li = 0; li < lines.length; li++)
        {
            const line = lines[li];
            for (var chi = 0; chi < line.length; chi++)
            {
                ctx.font = `${h}px ${f}`;
                const metrics = ctx.measureText(line[chi]);
                const w = metrics.width;
                if (x + w + charSeparation > area[0] + area[2] - paddingRight) break;
                const extSpaceLeft = (chi === 0 ? paddingLeft : 0);
                const extSpaceTop = (li === 0 ? paddingTop : 0);
                const extSpaceRight = (chi < line.length - 1 ? charSeparation : area[0] + area[2] - x - w);
                const extSpaceBottom = (li < lines.length - 1 && y - h >= area[1] + paddingBottom ? lineSeparation : y - area[1]);
                cbChar( li, 
                    chi, 
                    [x, y, w, h], 
                    [x - extSpaceLeft, 
                     y - extSpaceBottom, 
                     extSpaceLeft + w + extSpaceRight, 
                     extSpaceTop + h + extSpaceBottom
                    ]);
                x += w + charSeparation;
            }
            if (y - h < area[1] + paddingBottom) break;
            y -= h + lineSeparation;
            x = area[0] + paddingLeft;
        }
    }
}