import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Store } from '../../../store/store';
import { useAuth0 } from "@auth0/auth0-react";

export interface TopBarProps {
  store: Store;
}

export default function AppBar(props: TopBarProps) {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const fileClick = (event: any) => props.store.ui.Set().fileMenu.anchorEl = event.currentTarget;
  const editClick = (event: any) => props.store.ui.Set().editMenu.anchorEl = event.currentTarget;
  const selectionClick = (event: any) => props.store.ui.Set().selectionMenu.anchorEl = event.currentTarget;

  const settingsClick = () => {
    if (isAuthenticated) {

    }
    else loginWithRedirect();
  }

  const profileClick = (event: any) => {
    if (props.store.ui.account.id === "")
      props.store.ui.Set().profileLogintMenu.anchorEl = event.currentTarget;
    else
      props.store.ui.Set().profileLogouttMenu.anchorEl = event.currentTarget;
  }

  return (
    <Box height={72} sx={{bgcolor: 'lightgray'}}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} padding={2}>
        <Stack direction={'row'} alignItems={'center'}>
          <Box fontSize='large' sx={{ ml: 0, mr: 1 }}>
            <img src='/logo512.png' style={{width:32,height:32}}/>
          </Box>
          <Typography variant="h6" sx={{ mr: 2, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem' }}>
            Cadxi
          </Typography>
          <Button sx={{color: 'black', mr: 1}} onClick={fileClick}>File</Button>
          <Button sx={{color: 'black', mr: 1}} onClick={selectionClick}>Selection</Button>
          <Button sx={{color: 'black', mr: 1}} onClick={editClick}>Something</Button>
          <IconButton sx={{color: 'black', mr: 1}} onClick={settingsClick}><SettingsIcon/></IconButton>
        </Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <InsertDriveFileOutlinedIcon sx={{ mr: 1 }}/>
          <Typography sx={{ mr: 1}}>Drawing</Typography>
        </Stack>
        <Stack direction={'row'}>
          <IconButton sx={{ p: 0 }} onClick={profileClick}>
            <Avatar alt={props.store.ui.account.name} src={props.store.ui.account.avatarUrl} />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}