export class BufferBuilder {

    buffers: Uint8Array[] = [];

    Add(buffer: Uint8Array) {
        this.buffers.push(buffer);
    }

    ToBuffer(): Uint8Array {
        let totalSize = 0;
        this.buffers.forEach(buffer => totalSize += buffer.byteLength);
        var arrayBuffer = new ArrayBuffer(totalSize);
        const totalBuffer = new Uint8Array(arrayBuffer);
        let pos = 0;
        this.buffers.forEach(buffer => {
            totalBuffer.set(buffer, pos);
            pos += buffer.byteLength;
        });
        return totalBuffer;
    }
}

export function SerializeByte(byte: number): Uint8Array {
    var buffer = new ArrayBuffer(1);
    const arr = new Uint8Array(buffer);
    arr[0] = byte;
    return arr;
}

export function SerializeInt(int: number): Uint8Array {
    var buffer = new ArrayBuffer(4); 
    var arr = new Int32Array(buffer); 
    arr[0] = int;
    //console.log(int)
    const result = new Uint8Array(buffer);
    //console.log(result[0], result[0+1], result[0+2], result[0+3])
    return result;
}

export function SerializeDouble(double: number): Uint8Array {
    var buffer = new ArrayBuffer(8);      
    var arr = new Float64Array(buffer);
    arr[0] = double;
    return new Uint8Array(buffer);
}

export function SerializeString(s: string): Uint8Array {
    const sBuffer = (new TextEncoder()).encode(s);
    //console.log(s, sBuffer.byteLength, sBuffer)
    const bb = new BufferBuilder();
    bb.Add(SerializeInt(sBuffer.byteLength));
    bb.Add(sBuffer);
    const result = bb.ToBuffer();
    //console.log(result)
    return result;
}

export function SerializeId(id: string): Uint8Array {
    const sBuffer = (new TextEncoder()).encode(id);
    const bb = new BufferBuilder();
    bb.Add(SerializeByte(sBuffer.byteLength));
    bb.Add(sBuffer);
    const result = bb.ToBuffer();
    //console.log('SerializeId', id, result);
    return result;
}

export function DeserializeByte(buffer: Uint8Array, offset: {value: number}): number {
    offset.value++;
    return buffer[offset.value - 1];
}

export function DeserializeInt(buffer: Uint8Array, offset: {value: number}): number {
    //console.log(offset.value)
    //console.log(buffer[offset.value], buffer[offset.value+1], buffer[offset.value+2], buffer[offset.value+3])
    offset.value += 4;
    const result = new DataView(buffer.buffer).getInt32(offset.value - 4, true);
    //console.log('DeserializeInt', result)
    return result;
}

export function DeserializeDouble(buffer: Uint8Array, offset: {value: number}): number {
    offset.value += 8;
    return new DataView(buffer.buffer).getFloat64(offset.value - 8, true);
}

export function DeserializeString(buffer: Uint8Array, offset: {value: number}): string {
    //console.log('DeserializeString', buffer, offset.value);
    const size = DeserializeInt(buffer, offset);
    //console.log(size)
    const sBuffer = new Uint8Array(buffer.buffer, offset.value, size);
    offset.value += size;
    return (new TextDecoder()).decode(sBuffer);
}

export function DeserializeId(buffer: Uint8Array, offset: {value: number}): string {
    const size = DeserializeByte(buffer, offset);
    const sBuffer = new Uint8Array(buffer.buffer, offset.value, size);
    offset.value += size;
    const result = (new TextDecoder()).decode(sBuffer);
    //console.log('DeserializeId', result)
    return result;
}
