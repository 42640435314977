import { Line } from "../doc/extended/entities/line";
import { Poly } from "../doc/extended/entities/poly";
import { GenId } from "../tsutils/id";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { Command } from "./command";
import { ApiNewChange } from "../api/doc";

export class CreateRectangleCmd extends Command{
    constructor() {
        super("CreateRectangleCmd");
    }

    Process(store: Store) {
        store.ui.Set().promptBar.Set("RECTANGLE", "Select first corner or", ["Cancel"], "");
        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateRectangleWithPoint(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            }
        ));
    }

    CreateRectangleWithPoint(wP0: number[], store: Store) {
        store.ui.Set().promptBar.Set("RECTANGLE", "Select other corner or", ["Cancel"], "");

        const line0 = new Line(store.GenEntityId(), [wP0[0],wP0[1]], [1,wP0[1]], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
        const line1 = new Line(store.GenEntityId(), [1,wP0[1]], [1,1], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
        const line2 = new Line(store.GenEntityId(), [1,1], [wP0[0],1], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
        const line3 = new Line(store.GenEntityId(), [wP0[0],1], [wP0[0],wP0[1]], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());

        store.SetActiveState(new PointSelectState(
            wP => { // success
                line0.p1[0] = wP[0];
                line1.p0[0] = wP[0]; line1.p1[0] = wP[0]; line1.p1[1] = wP[1];
                line2.p0[0] = wP[0]; line2.p0[1] = wP[1]; line2.p1[1] = wP[1];
                line3.p0[1] = wP[1];
                const poly = new Poly(store.GenEntityId(), [line0, line1, line2, line3], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
                
                this.DoAddRectangleAction(poly, store);

                store.SetActiveState(new EditorState());
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
                line0.p1[0] = wP[0];
                line1.p0[0] = wP[0]; line1.p1[0] = wP[0]; line1.p1[1] = wP[1];
                line2.p0[0] = wP[0]; line2.p0[1] = wP[1]; line2.p1[1] = wP[1];
                line3.p0[1] = wP[1];
                const poly = new Poly(store.GenEntityId(), [line0, line1, line2, line3], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());
                poly.Draw(ctx, store);
            }
        ));
    }

    DoAddRectangleAction(poly: Poly, store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                store.GetActiveDoc().AddMask(poly);
                ApiNewChange(store.GetActiveDoc().AddEntity(poly), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                store.GetActiveDoc().RemoveMask(poly);
                ApiNewChange(store.GetActiveDoc().RemoveEntity(poly), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}