import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Store } from "../../store/store";
import { Stack, Typography, popoverClasses } from "@mui/material";
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import { SelectAllCmd } from "../../commands/selectAll";
import { DeselectCmd } from "../../commands/deselect";

export interface SelectionMenuProps {
    store: Store;
}

export default function SelectionMenu(props: SelectionMenuProps) {
    const close = () => {
        props.store.ui.Set().selectionMenu.anchorEl = null;
    };
    return (
        <Menu anchorEl={props.store.ui.Get().selectionMenu.anchorEl} open={Boolean(props.store.ui.Get().selectionMenu.anchorEl)} sx={{[`& .${popoverClasses.paper}`]:{bgcolor: "lightgray"}}} onClose={close}>
            <MenuItem onClick={() => { props.store.GetActiveState().Command(new SelectAllCmd(), props.store); close(); }}>
                <Stack direction={'row'} sx={{width: 160}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <SelectAllIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Select All</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>⌘A</Typography>
                </Stack>
            </MenuItem>
            <MenuItem onClick={() => { props.store.GetActiveState().Command(new DeselectCmd(), props.store); close(); }}>
                <Stack direction={'row'} sx={{width: 160}} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'row'}  alignItems={'center'} sx={{}}>
                        <DeselectIcon fontSize="small" sx={{mr:1}}/>
                        <Typography>Deselect</Typography>
                    </Stack>
                    <Typography variant="body2" color="text.secondary" sx={{float: "right"}}>Esc</Typography>
                </Stack>
            </MenuItem>
        </Menu>
    );
}