import { PointInRect } from "../math/rect";
import { Store } from "../store/store";
import { Command } from "./command";

export class RectSelectCmd extends Command{
    fromP: number[]; 
    toP: number[];
    shiftKey: boolean;
    
    constructor(fromP: number[], toP: number[], shiftKey: boolean) {
        super("RectSelectCmd"); this.fromP = [...fromP]; this.toP = [...toP]; this.shiftKey = shiftKey;
    }

    Process(store: Store) {
        store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaExact(
            store.GetActiveDoc().GetActiveSpace(),
            layer => layer.isVisible,
            () => true,
            [this.fromP[0], this.fromP[1], this.toP[0] - this.fromP[0], this.toP[1] - this.fromP[1]]
        ).forEach(entity => {
            if (this.fromP[0] > this.toP[0])
            {
                if (!this.shiftKey)
                {
                    entity.SetIsSelected(true);
                    store.ui.Set().selection.entities.add(entity);
                }
                else
                {
                    entity.SetIsSelected(false);
                    store.ui.Set().selection.entities.delete(entity);
                }
            }
            else
            {
                const entityBounds = entity.GetVisibleBoundingRect();
                if (    PointInRect(this.fromP[0], this.fromP[1], this.toP[0] - this.fromP[0], this.toP[1] - this.fromP[1], entityBounds[0], entityBounds[1]) 
                    &&  PointInRect(this.fromP[0], this.fromP[1], this.toP[0] - this.fromP[0], this.toP[1] - this.fromP[1], entityBounds[0] + entityBounds[2], entityBounds[1] + entityBounds[3]))
                {
                    if (!this.shiftKey)
                    {
                        entity.SetIsSelected(true);
                        store.ui.Set().selection.entities.add(entity);
                    }
                    else
                    {
                        entity.SetIsSelected(false);
                        store.ui.Set().selection.entities.delete(entity);
                    }
                }
            }
        })    
    }
}