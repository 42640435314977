import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { IsProduction } from './tsutils/production';

let auth0Domain = "dev-gprvsu3cmjkk5plw.us.auth0.com";
let auth0ClientId = "pAPaohvIZ3VGyAnWyxwbj4fuDfWmYB7P";
if (IsProduction())
{
  auth0Domain = "dev-gprvsu3cmjkk5plw.us.auth0.com";
  auth0ClientId = "GYhGS0wrKyazxnGT9CWN9mgZoldRddKP";
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: "https://dev.cadxi.com"
      }}>
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
