import { ApiPostJsonToBin } from "../tsutils/apiFetch";
import { API_HOST } from "./api";


export async function ApiUnsDownloadFile(
    email: string, 
    fileName: string
) {
    const data = await ApiPostJsonToBin(API_HOST, 'uns/doc/download', { email, fileName });
    if (data === undefined) return undefined;
    return new Uint8Array(data);
}