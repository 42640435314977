import { ZoomPreserveCanvasPoint } from "../math/zoom";
import { Store } from "../store/store";
import { Command } from "../commands/command";
import { ClickSelectGesture } from "./gestures/clickSelect";
import { KeyboardGesture } from "./gestures/keyboard";
import { PanGesture } from "./gestures/pan";
import { RectSelectGesture } from "./gestures/rectSelect";
import { ZoomWheelGesture } from "./gestures/zoomWheel";
import { State } from "./state";
import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import MobileAppBar from "../components/mobileFrame/mobileAppBar";
import { Canvas } from "../components/lib/canvas";
import { useEffect } from "react";
import Menus from "../components/menus/menus";
import Dialogs from "../components/dialogs/dialogs";

interface StateProps {
    state: MobileViewerState;
    store: Store;
}

const StateRender: React.FC<StateProps> = ({state, store}) => {
    const { user, isAuthenticated, isLoading } = useAuth0();    

    const block = (
    <>
        <Box id="MobileMainFrame" sx={{bgcolor:'black', outline:'none'}}>
            <MobileAppBar store={store}/>
            <Canvas draw={ctx => store.GetActiveState().Draw(ctx, store)} 
                            mouseDown={e => store.GetActiveState().MouseDown(e, store)}
                            mouseUp={e => store.GetActiveState().MouseUp(e, store)}
                            mouseMove={e => store.GetActiveState().MouseMove(e, store)}
                            mouseWheel={e => store.GetActiveState().MouseWheel(e, store)}
                            mouseEnter={e => store.GetActiveState().MouseEnter(e, store)}
                            mouseLeave={e => store.GetActiveState().MouseLeave(e, store)}
                            mouseDblClick={e => store.GetActiveState().MouseDblClick(e, store)}
                            touchesPan={e => store.GetActiveState().TouchesPan(e, store)}
                            touchesPinch={e => store.GetActiveState().TouchesPinch(e, store)}
                            resizeAndOrMove={(x: number, y: number, w: number, h: number) => store.ui.SetCanvasRect(x,y,w,h)}
                            width= "100vw" height= "calc(100vh - 75px)"/>
        </Box>
        <Menus store={store}/>
        <Dialogs store={store}/>
    </>);

    useEffect(() => { (async () => {
        
    })()}, [user, isAuthenticated, isLoading])

    return block;
}

export class MobileViewerState extends State {
    constructor() {
        super([
            new PanGesture(),
            new ZoomWheelGesture(),
            new RectSelectGesture(),
            new ClickSelectGesture(),
            new KeyboardGesture(),
        ],
        [
            "CloseCmd",

            
            "RectSelectCmd",
            "ClickSelectCmd",
            "SelectAllCmd",
            "DeselectCmd",
            "SnapMoveCmd",

            "CreateLineCmd",
            "CreateCircleCmd",
            "CreateArcCmd",
            "CreatePolyCmd",
            "CreateRectangleCmd",
            "CreateTextCmd",

            "ZoomExtentsCmd",
            "ZoomRectangleCmd",

            "MoveCmd",
            "EraseCmd"
        ]);
    }

    Activate(store: Store) {}

    Command(cmd: Command, store: Store) {
        super.Command(cmd, store);
        switch (cmd.name) {
        }        
    }

    TouchesPan(event: any, store: Store) { 
        super.TouchesPan(event, store);

        const wDelta = store.CanvasToWorldDelta([8 * event.velocityX, 8 * event.velocityY]);
        const wCenter = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter();
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter([wCenter[0] - wDelta[0], wCenter[1] - wDelta[1]]);
    }

    TouchesPinch(event: any, store: Store) { 
        super.TouchesPinch(event, store);

        const canvasPoint = store.ClientToCanvas([
            store.ui.GetCanvasRect()[0] + 0.5 * store.ui.GetCanvasRect()[2],
            store.ui.GetCanvasRect()[1] + 0.5 * store.ui.GetCanvasRect()[3]]);
        const scale = event.scale > 1 ? 0.975 : 1.025;
        //const canvasPoint = store.ClientToCanvas(event.center);

        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionCenter(
            ZoomPreserveCanvasPoint(
                canvasPoint[0], canvasPoint[1], scale, 
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0], 
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1], 
                store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth(), 
                store.ui.GetCanvasRect()[2], store.ui.GetCanvasRect()[3]
            )
        );
        store.GetActiveDoc().GetActiveSpace().SetWorldProjectionWidth(
        store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth() * scale);
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        super.Draw(ctx, store);

        ctx.fillStyle = 'black'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = 'white'
        ctx.beginPath()
        ctx.arc(50, 100, 20*Math.sin(Math.random()*1000*0.05)**2, 0, 2*Math.PI)
        ctx.fill();

        store.GetActiveDoc().GetSpacialPartiionAccelerator()._draw(ctx, store);

        // Get visible entities for layer
        const boundsW = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionWidth();
        const boundsH = Math.abs(store.CanvasToWorldDelta([0,store.ui.GetCanvasRect()[3]])[1]);
        const boundsX0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[0] - 0.5 * boundsW;
        const boundsY0 = store.GetActiveDoc().GetActiveSpace().GetWorldProjectionCenter()[1] - 0.5 * boundsH;
        const entities = store.GetActiveDoc().GetSpacialPartiionAccelerator().GetEntitiesForAreaApproximate(
            store.GetActiveDoc().GetActiveSpace(),
            layer => layer.isVisible,
            () => true,
            [boundsX0, boundsY0, boundsW, boundsH]
        )

        // Draw entities
        entities.forEach(entity => {
            entity.Draw(ctx, store);
        })
    }

    Render(store: Store) {
        return <StateRender state={this} store={store}/>
    }
}