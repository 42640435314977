import { Line } from "../doc/extended/entities/line";
import { Poly } from "../doc/extended/entities/poly";
import { Textual } from "../doc/extended/entities/textual";
import { GenId } from "../tsutils/id";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { TextEditorState } from "../states/textEditor";
import { Store } from "../store/store";
import { Command } from "./command";

export class CreateTextCmd extends Command{
    constructor() {
        super("CreateTextCmd");
    }

    Process(store: Store) {
        store.ui.Set().promptBar.Set("TEXT", "Select first corner or", ["Cancel"], "");
        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateTextWithPoint(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            }
        ));
    }

    CreateTextWithPoint(wP0: number[], store: Store) {
        store.ui.Set().promptBar.Set("TEXT", "Select other corner or", ["Cancel"], "");

        store.SetActiveState(new PointSelectState(
            wP => { // success
                store.ui.Set().promptBar.Set("TEXT", "Enter your text or", ["Done", "Cancel"], "");

                const text = new Textual(store.GenEntityId(), [wP0[0], wP0[1], wP[0] - wP0[0], wP[1] - wP0[1]], "", 1, "Courrier New", store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());

                store.SetActiveState(new TextEditorState(text, true));
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
                const canvasPointFrom = store.WorldToCanvas(wP0);
                const canvasPointTo = store.WorldToCanvas(wP);
                ctx.strokeStyle = "white";
                ctx.strokeRect(canvasPointFrom[0], canvasPointFrom[1], canvasPointTo[0] - canvasPointFrom[0], canvasPointTo[1] - canvasPointFrom[1]);
            }
        ));
    }
}