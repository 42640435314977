import { CircleAreaIntersects } from "../../../math/intersects";
import { PointCircleNearestPoint } from "../../../math/nearest";
import { PointEquals } from "../../../math/point";
import { PointInRect } from "../../../math/rect";
import { CenterPointSnap } from "../../../snaps/centerPoint";
import { NearestPointSnap } from "../../../snaps/nearestPoint";
import { QuadrantPointSnap } from "../../../snaps/quadrantPoint";
import { Snap } from "../../../snaps/snap";
import { Store } from "../../../store/store";
import { LayerData } from "../layers/layerData";
import { SpaceData } from "../spaces/spaceData";
import { EntityData } from "./entityData";

export class CircleData extends EntityData {
    c: number[];
    r: number;

    constructor(id: string, c: number[], r: number, space: SpaceData, layer: LayerData) {
        super(id, space, layer);
        this.c = [...c]; this.r = r;
    }
}