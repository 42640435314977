import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Divider } from '@mui/material';
import Crop32SharpIcon from '@mui/icons-material/Crop32Sharp';
import RadioButtonUncheckedSharpIcon from '@mui/icons-material/RadioButtonUncheckedSharp';
import TollSharpIcon from '@mui/icons-material/TollSharp';
import HorizontalRuleSharpIcon from '@mui/icons-material/HorizontalRuleSharp';
import StraightenSharpIcon from '@mui/icons-material/StraightenSharp';
import SettingsEthernetSharpIcon from '@mui/icons-material/SettingsEthernetSharp';
import TitleSharpIcon from '@mui/icons-material/TitleSharp';
import OpenWithSharpIcon from '@mui/icons-material/OpenWithSharp';
import OpenInNewOffSharpIcon from '@mui/icons-material/OpenInNewOffSharp';
import ScatterPlotSharpIcon from '@mui/icons-material/ScatterPlotSharp';
import ContentCutSharpIcon from '@mui/icons-material/ContentCutSharp';
import DeleteForeverSharpIcon from '@mui/icons-material/DeleteForeverSharp';
import SettingsBackupRestoreSharpIcon from '@mui/icons-material/SettingsBackupRestoreSharp';
import FitScreenSharpIcon from '@mui/icons-material/FitScreenSharp';
import FlareSharpIcon from '@mui/icons-material/FlareSharp';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import ExpandIcon from '@mui/icons-material/Expand';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { Store } from '../../../store/store';
import { CreateLineCmd } from '../../../commands/createLine';
import { CreateCircleCmd } from '../../../commands/createCircle';
import { CreateArcCmd } from '../../../commands/createArc';
import { CreatePolyCmd } from '../../../commands/createPoly';
import { CreateRectangleCmd } from '../../../commands/createRectangle';
import { ZoomExtentsCmd } from '../../../commands/zoomExtents';
import { ZoomRectangleCmd } from '../../../commands/zoomRectangle';
import { MoveCmd } from '../../../commands/move';
import { EraseCmd } from '../../../commands/erase';
import { CreateTextCmd } from '../../../commands/createText';

export interface ToolBarProps {
  store: Store;
}

export default function ToolBar(props: ToolBarProps) {
  return (
    <Box height={72} sx={{bgcolor: 'black'}}>
      <Stack direction={'row'} alignItems={'center'} padding={1} sx={{bgcolor: 'gray', borderRadius:'0px 0px 4px 4px'}}>
          <Box sx={{m:1}}></Box>
          <Stack alignItems={'center'}>
            <Typography variant='body2' sx={{color:'white', fontWeight:'bold', mb: 1}}>Edit</Typography>
            <Stack direction={'row'}>
              <Tooltip title="Undo">
                <UndoIcon onClick={() => props.store.Undo()} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Redo">
                <RedoIcon onClick={() => props.store.Redo()} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Cut">
                <ContentCutIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Copy">
                <ContentCopyIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Paste">
                <ContentPasteIcon sx={{color:'lightgray'}}/>
              </Tooltip>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{bgcolor: 'darkgray', m: 2.5}}/>

          <Stack alignItems={'center'}>
            <Typography variant='body2' sx={{color:'white', fontWeight:'bold', mb: 1}}>Zoom</Typography>
            <Stack direction={'row'}>
              <Tooltip title="Zoom Extents">
                <ZoomOutMapIcon onClick={() => props.store.GetActiveState().Command(new ZoomExtentsCmd(), props.store)} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Zoom Window">
                <AspectRatioIcon onClick={() => props.store.GetActiveState().Command(new ZoomRectangleCmd(), props.store)} sx={{color:'lightgray'}}/>
              </Tooltip>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{bgcolor: 'darkgray', m: 2.5}}/>

          <Stack alignItems={'center'}>
            <Typography variant='body2' sx={{color:'white', fontWeight:'bold', mb: 1}}>Draw</Typography>
            <Stack direction={'row'}>
              <Tooltip title="Rectangle">
                <Crop32SharpIcon onClick={() => props.store.GetActiveState().Command(new CreateRectangleCmd(), props.store)} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Circle">
                <RadioButtonUncheckedSharpIcon onClick={() => props.store.GetActiveState().Command(new CreateCircleCmd(), props.store)} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Arc">
                <TollSharpIcon onClick={() => props.store.GetActiveState().Command(new CreateArcCmd(), props.store)} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Line">
                <HorizontalRuleSharpIcon onClick={() => props.store.GetActiveState().Command(new CreateLineCmd(), props.store)} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Poly">
                <ShowChartIcon onClick={() => props.store.GetActiveState().Command(new CreatePolyCmd(), props.store)} sx={{color:'lightgray'}}/>
              </Tooltip>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{bgcolor: 'darkgray', m: 2.5}}/>

          <Stack alignItems={'center'}>
            <Typography variant='body2' sx={{color:'white', fontWeight:'bold', mb: 1}}>Annotate</Typography>
            <Stack direction={'row'}>
              <Tooltip title="Ruler">
                <StraightenSharpIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Dimension">
                <ExpandIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Text">
                <TitleSharpIcon onClick={() => props.store.GetActiveState().Command(new CreateTextCmd(), props.store)} sx={{color:'lightgray'}}/>
              </Tooltip>
            </Stack>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{bgcolor: 'darkgray', m: 2.5}}/>

          <Stack alignItems={'center'}>
            <Typography variant='body2' sx={{color:'white', fontWeight:'bold', mb: 1}}>Modify</Typography>
            <Stack direction={'row'}>
              <Tooltip title="Move">
                <OpenWithSharpIcon onClick={() => props.store.GetActiveState().Command(new MoveCmd(), props.store)} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Erase">
                <DeleteForeverSharpIcon onClick={() => props.store.GetActiveState().Command(new EraseCmd(), props.store)} sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Explode">
                <FlareSharpIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Mirror">
                <OpenInNewOffSharpIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Trim">
                <NetworkPingIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Rotate">
                <RotateLeftIcon sx={{color:'lightgray', mr: 2}}/>
              </Tooltip>
              <Tooltip title="Scale">
                <PhotoSizeSelectSmallIcon sx={{color:'lightgray'}}/>
              </Tooltip>
            </Stack>
          </Stack>
      </Stack>
    </Box>
  );
}