import { Store } from "../store/store";
import { Snap } from "./snap";

export class CenterPointSnap extends Snap {
    constructor(p: number[]) {
        super("CENTER_POINT", p);
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        const cP = store.WorldToCanvas(this.p);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'cyan';
        ctx.beginPath();
        ctx.arc(cP[0], cP[1], 7, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.lineWidth = 1;
    }
}