import { Store } from "../../store/store";
import FileManagerDialog from "./fileManager";
import MobileFileManagerDialog from "./mobileFileManager";

export interface DialogsProps {
    store: Store;
}

export default function Dialogs(props: DialogsProps) {
    return (
        <>
            <FileManagerDialog store={props.store}/>
            <MobileFileManagerDialog store={props.store}/>
        </>
    );
}