import { ApiNewChange } from "../api/doc";
import { Line } from "../doc/extended/entities/line";
import { GenId } from "../tsutils/id";
import { EditorState } from "../states/editor";
import { PointSelectState } from "../states/pointSelect";
import { Store } from "../store/store";
import { UndoableAction } from "../doc/extended/undoableHistory";
import { Command } from "./command";

export class CreateLineCmd extends Command{
    constructor() {
        super("CreateLineCmd");
    }

    Process(store: Store) {
        store.ui.Set().promptBar.Set("LINE", "Select first point or", ["Cancel"], "");
        store.SetActiveState(new PointSelectState(
            wP => { // success
                this.CreateLineWithPoint(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            }
        ));
    }

    CreateLineWithPoint(wP: number[], store: Store) {
        store.ui.Set().promptBar.Set("LINE", "Select next point or", ["Cancel"], "");

        const line = new Line(store.GenEntityId(), [...wP], [1,1], store.GetActiveDoc().GetActiveSpace(), store.GetActiveDoc().GetActiveLayer());

        store.SetActiveState(new PointSelectState(
            wP => { // success
                line.p1 = [...wP];
                this.DoAddLineAction(line, store);

                this.CreateLineWithPoint(wP, store);
            },
            () => { // cancel
                store.SetActiveState(new EditorState());
            },
            cmd => { // command
                if (cmd.name === "PromptButtonCmd")
                {
                    store.SetActiveState(new EditorState());
                }
            },
            (ctx, wP) => { // gizmo
                line.p1 = [...wP];
                line.Draw(ctx, store);
            }
        ));
    }

    DoAddLineAction(line: Line, store: Store) {
        store.Do(new UndoableAction( 
            function () { // do

                store.GetActiveDoc().AddMask(line);
                ApiNewChange(store.GetActiveDoc().AddEntity(line), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            },
            function () { // undo

                store.GetActiveDoc().RemoveMask(line);
                ApiNewChange(store.GetActiveDoc().RemoveEntity(line), store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName(), store);

            }
        ))
    }
}