import { Textual } from "../doc/extended/entities/textual";
import { Store } from "../store/store";
import { AcceleratorKeyCmd } from "../commands/acceleratorKey";
import { Command } from "../commands/command";
import { CreateArcCmd } from "../commands/createArc";
import { CreateCircleCmd } from "../commands/createCircle";
import { CreateLineCmd } from "../commands/createLine";
import { CreatePolyCmd } from "../commands/createPoly";
import { DeselectCmd } from "../commands/deselect";
import { PromptButtonCmd } from "../commands/promptButton";
import { PromptInputCmd } from "../commands/promptInput";
import { ClickSelectGesture } from "./gestures/clickSelect";
import { KeyboardGesture } from "./gestures/keyboard";
import { PanGesture } from "./gestures/pan";
import { RectSelectGesture } from "./gestures/rectSelect";
import { ZoomWheelGesture } from "./gestures/zoomWheel";
import { State } from "./state";
import { TextEditorState } from "./textEditor";
import LoadingSimple from "../components/lib/loadingSimple";
import { ApiDownloadFile } from "../api/doc";
import { ZoomExtentsCmd } from "../commands/zoomExtents";
import { ApiUnsDownloadFile } from "../api/unsDoc";

interface StateProps {
    state: DownloadState;
    store: Store;
}

const StateRender: React.FC<StateProps> = ({state, store}) => {
    //const { user, isAuthenticated, isLoading } = useAuth0();

    const block = (
    <>
        <LoadingSimple/>
    </>);

    return block;
}

export class DownloadState extends State {
    nextState: State;

    constructor(nextState: State) {
        super([
            new PanGesture(),
            new ZoomWheelGesture(),
            new RectSelectGesture(),
            new ClickSelectGesture(),
            new KeyboardGesture(),
        ],
        [
            "RectSelectCmd",
            "ClickSelectCmd",
            "SelectAllCmd",
            "DeselectCmd",
            "SnapMoveCmd",

            "CreateLineCmd",
            "CreateCircleCmd",
            "CreateArcCmd",
            "CreatePolyCmd",
            "CreateRectangleCmd",
            "CreateTextCmd",

            "ZoomExtentsCmd",
            "ZoomRectangleCmd",

            "MoveCmd",
            "EraseCmd"
        ]);
        this.nextState = nextState;
    }

    Activate(store: Store) {
        (async () => {
            //const fileData = await ApiDownloadFile(store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName());
            const fileData = await ApiUnsDownloadFile(store.GetActiveDoc().GetEmail(), store.GetActiveDoc().GetFileName());
            store.GetActiveDoc().Deserialize(fileData!);
            store.GetActiveDoc().snapshotIsDirty = false;
            //console.log(user, isAuthenticated, isLoading)
            console.log('got file', store.GetActiveDoc());
            store.SetActiveState(this.nextState);
            setTimeout(() => this.nextState.Command(new ZoomExtentsCmd(), store), 60);
        })();
    }

    Command(cmd: Command, store: Store) {
        super.Command(cmd, store);
        switch (cmd.name) {
            case "CancelCmd": {
                this.Command(new DeselectCmd(), store);
            } break;
            case "AcceleratorKeyCmd": {
                AcceleratorKeyCmd.UpdatePromptInput((cmd as AcceleratorKeyCmd).event, store);
            } break;
            case "PromptButtonCmd": {
                const tcmd = cmd as PromptButtonCmd;
                switch (tcmd.bName) {
                    case "Deselect": {
                        this.Command(new DeselectCmd(), store);
                    } break;
                }
            } break;
            case "PromptInputCmd": {
                const tcmd = cmd as PromptInputCmd;
                switch (tcmd.value.toUpperCase()) {
                    case "L": case "LINE": {
                        this.Command(new CreateLineCmd(), store);
                    } break;
                    case "C": case "CIRCLE": {
                        this.Command(new CreateCircleCmd(), store);
                    } break;
                    case "A": case "ARC": {
                        this.Command(new CreateArcCmd(), store);
                    } break;
                    case "P": case "PLINE": {
                        this.Command(new CreatePolyCmd(), store);
                    } break;
                }
            } break;
        }        
    }

    MouseDblClick(event: any, store: Store) { 
        super.MouseDblClick(event, store);

        if (event.button === 0)
        {
            // Snap mouse
            let wMouse = store.ClientToWorld(store.ui.GetMousePos());
            store.ui.Get().selection.entities.forEach(entity => {
                if (entity.IntersectsArea([wMouse[0], wMouse[1], 0, 0]))
                {
                    if (entity instanceof Textual)
                    {
                        store.ui.Set().promptBar.Set("TEXT", "Make changes to text or", ["Done", "Cancel"], "");
                        store.SetActiveState(new TextEditorState(entity as Textual, false));
                    }
                }
            })
        }
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {
        super.Draw(ctx, store);
    }

    Render(store: Store) {
        return <StateRender state={this} store={store}/>
    }
}
