import { Store } from "../store/store";

export class Snap {
    type: string;
    p: number[];

    constructor(type: string, p: number[]) {
        this.type = type;
        this.p = [...p];
    }

    Draw(ctx: CanvasRenderingContext2D, store: Store) {}
}